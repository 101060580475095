import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import API_URLS from "utils/apiVersionConfig";
import { useIntl } from 'react-intl';

const extractCadParameters = async (calculationId: number) => {
  const response = await axios.get(
    API_URLS.extractCadParameters(calculationId),
  );
  return response.data;
};

const useExtractCadParametersMutation = () => {
  const intl = useIntl();
  const mutation = useMutation(extractCadParameters, {
    onSuccess: () => {
      toast.success(
        intl.formatMessage({
          id: 'global.toasts.calculationCreated',
          defaultMessage: 'CAD parameters are being extracted. Calculation status will be updated shortly.',
        }),
        { duration: 5000 },
      );
    },
    onError: () => {
      toast.error("Error extracting CAD parameters", { duration: 5000 });
    },
  });

  return mutation;
};

export default useExtractCadParametersMutation;
