import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setCookie = <T>(name: string, value: T) => {
  cookies.set(name, value, { path: "/" });
};
export const getCookie = <T>(name: string): T => {
  return cookies.get(name);
};
export const removeCookie = (name: string) => {
  cookies.remove(name, { path: "/" });
};
