import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

interface CopyCalculationType {
  calculationId: number;
  inputParamsBody: any;
}

const postCopyCalculation = async ({
  calculationId,
  inputParamsBody,
}: CopyCalculationType) => {
  const response = await axios.post(
    API_URLS.postCopyCalculation(calculationId),
    {
      calculation_parameter_values: inputParamsBody,
    },
  );

  return response;
};

const useCopyCalculation = () => {
  const copyCalculation = useMutation(postCopyCalculation);

  return {
    copyCalculation,
  };
};

export default useCopyCalculation;
