import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useAppDispatch } from "store/hooks";
import { useValidateQRCodeMutation } from "views/admin/settings/api/QRCodeMutations";
import OTPInputComponent from "views/admin/settings/components/OTPInputComponent";
import { setUserAuth, UserAuth } from "../signInSlice";
import { useIntl } from "react-intl";
import axiosInterceptor from "services/axios.service";

type OTPValidateProps = {
  userObject: UserAuth;
};

function OTPValidate({ userObject }: OTPValidateProps) {
  const [otp, setOtp] = useState("");
  const mutation = useValidateQRCodeMutation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const currentLocale = intl.locale;
  const validateOTP = (otpToValidate: string) => {
    mutation
      .mutateAsync({
        user_id: userObject.user_id,
        token: String(otpToValidate),
        headers: {
          Authorization: `Bearer ${userObject.accessToken}`,
        },
      })
      .then(() => {
        dispatch(setUserAuth(userObject));
        axiosInterceptor(currentLocale);
        queryClient.invalidateQueries(["user"]);
      })
      .catch(() => {
        toast.error("Invalid OTP or OTP expired. ");
      });
  };
  return (
    <Flex
      zIndex="2"
      direction="column"
      align="center"
      w={{ base: "100%", md: "50%" }}
      gap={5}
      borderRadius="15px"
      my="auto"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
      p="10"
    >
      <Text fontSize="2xl" fontWeight="bold">
        Validate
      </Text>
      <Text textAlign="center">
        Enter the generetated code from your authenticator app to verify your
        identity.
      </Text>
      <OTPInputComponent
        value={otp}
        onChange={(val) => {
          setOtp(val);
        }}
      />

      {otp.length === 6 && (
        <Button
          onClick={() => {
            validateOTP(otp);
          }}
          colorScheme="blue"
          size="lg"
          rightIcon={mutation.isLoading && <Spinner />}
        >
          Verify
        </Button>
      )}
    </Flex>
  );
}

export default OTPValidate;
