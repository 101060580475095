import { useState } from "react";
import { Button, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useIntl, FormattedMessage } from "react-intl";
import {
  CalculationParameterValue,
  matchInputParameters,
} from "utils/matchInputParameters";
import useCopyCalculation from "views/admin/calculations/calculationDetails/api/useCopyCalculation";

interface CalcActionBtnProps {
  calculationId: number;
  formsWithValues: any;
  simpleFieldObject: any;
}

function CopyCalculationBtn({
  calculationId,
  formsWithValues,
  simpleFieldObject,
}: CalcActionBtnProps) {
  const history = useHistory();
  const [isCalculating, setIsCalculating] = useState(false);
  const intl = useIntl();
  const { copyCalculation } = useCopyCalculation();

  const inputParamsBody: CalculationParameterValue[] = matchInputParameters(
    formsWithValues,
    simpleFieldObject,
  );

  const requestBody = JSON.stringify(inputParamsBody);

  const CopyCalculation = async () => {
    setIsCalculating(true);
    try {
      const response = await copyCalculation.mutateAsync({
        calculationId,
        inputParamsBody: requestBody,
      });

      if (response.data) {
        history.push("/user/dashboard");
      }
    } catch (error) {
      console.error("Error copying calculation:", error);
      toast.error(
        intl.formatMessage({
          id: "global.toasts.errorCopyingCalculation",
          defaultMessage: "Error copying calculation",
        }),
      );
    } finally {
      setIsCalculating(false);
    }
  };

  return (
    <Button
      mr={5}
      w="fit-content"
      bg="blue.500"
      color="white"
      fontWeight="500"
      display="flex"
      alignItems="center"
      isDisabled={isCalculating}
      _hover={{ bg: "blue.600" }}
      justifyContent={isCalculating ? "space-between" : "center"}
      onClick={CopyCalculation}
    >
      {isCalculating && <Spinner size="sm" color="white" mr={2} />}
      <FormattedMessage
        id="calculation.detail.copyCalc"
        defaultMessage="Copy calculation"
      />
    </Button>
  );
}

export default CopyCalculationBtn;
