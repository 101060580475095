import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner, Icon } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { MdWarning } from "react-icons/md";
import runCalculationById from "views/admin/calculations/calculationDetails/api/runCalculationById";
import { toast } from "react-hot-toast";
import usePartitionPlane from "views/admin/calculations/calculationDetails/api/usePartitionPlane";
import { useIntl } from "react-intl";
import {
  usePartingPlanesMatch,
  useSetPartingPlanesMatch,
} from "contexts/ModelHooks";

interface CalcActionBtnProps {
  status: string;
  calculationId: number;
  production: any;
  predictedWeightBeingChanged: boolean;
  planeCalculationId: number;
}

function CalcActionBtn(props: CalcActionBtnProps) {
  const {
    status,
    calculationId,
    production,
    predictedWeightBeingChanged,
    planeCalculationId,
  } = props;
  const history = useHistory();
  const [isCalculating, setIsCalculating] = useState(false);
  const { editPartitionPlane } = usePartitionPlane();
  const intl = useIntl();
  let buttonText = "";
  let onClickAction: () => void = () => {};
  let icon;
  let bgColor;
  const partingPlanesMatch = usePartingPlanesMatch();
  const setPartingPlanesMatch = useSetPartingPlanesMatch();

  const savedPlaneDataString = localStorage.getItem("planeData");

  // Find the correct plane in the localStorage by matching the calculationId with the id of the calculation inside the calculation>plane
  const [correctPlane, setCorrectPlane] = useState(null);

  useEffect(() => {
    const savedDataArray = JSON.parse(savedPlaneDataString);
    if (savedDataArray) {
      setCorrectPlane(
        savedDataArray.find(
          (calculation: any) =>
            calculation.calculationId === planeCalculationId,
        ),
      );
    }
  }, [savedPlaneDataString]);

  const { mutate: runCalculationMutation } = useMutation({
    mutationFn: () => runCalculationById(calculationId, production),

    onMutate: () => {
      setIsCalculating(true);
    },
    onSuccess: () => {
      setIsCalculating(false);

      history.push(`/user/result/${calculationId}`, {
        calculationId: calculationId,
        calculationStatus: status,
      });
    },
    onError: (error: any) => {
      const errorFields = error.response.data.exceptions.map(
        (e: any) => `${e.field}: ${e.message}`,
      );
      toast.error(`
       ${errorFields}
      `);
      setIsCalculating(false);
    },
  });

  switch (status) {
    case "draft":
      if (partingPlanesMatch && savedPlaneDataString) {
        bgColor = "blue";
        buttonText = intl.formatMessage({
          id: "calculation.detail.runCalc",
          defaultMessage: "Run calculation",
        });
        onClickAction = async () => {
          setIsCalculating(true);

          try {
            runCalculationMutation();
          } catch (error) {
            console.log(error);
          } finally {
            setIsCalculating(false);
          }
        };
      } else {
        buttonText = intl.formatMessage({
          id: "calculation.detail.runCoreDetec",
          defaultMessage: "Calculate Cores",
        });
        onClickAction = async () => {
          setIsCalculating(true);
          try {
            const response = await editPartitionPlane.mutateAsync(correctPlane);
            setPartingPlanesMatch(
              correctPlane.axis === response.data.axis &&
                correctPlane.position === response.data.position &&
                response.data.number_of_cavities !== null,
            );
          } catch (error) {
            console.error("Error calculating cores:", error);
            toast.error(
              intl.formatMessage({
                id: "global.toasts.errorCalculatingCores",
                defaultMessage: "Error calculating cores",
              }),
            );
          } finally {
            setIsCalculating(false);
          }
        };
        icon = <Icon as={MdWarning} mr={2} />;
        bgColor = "brand";
      }
      break;
    case "finished":
      bgColor = "blue";
      buttonText = intl.formatMessage({
        id: "calculation.detail.showResults",
        defaultMessage: "Show results",
      });
      onClickAction = () => {
        history.push(`/user/result/${calculationId}`, {
          calculationId: calculationId,
          calculationStatus: status,
        });
      };
      break;
  }

  const disableButton =
    isCalculating ||
    production.length === 0 ||
    status === "error" ||
    predictedWeightBeingChanged;

  return (
    <Button
      w="fit-content"
      alignSelf="end"
      bg={`${bgColor}.500`}
      color="white"
      isDisabled={disableButton}
      fontWeight="500"
      _hover={{ bg: `${bgColor}.600` }}
      onClick={onClickAction}
      display="flex"
      alignItems="center"
      justifyContent={isCalculating ? "space-between" : "center"}
    >
      {isCalculating ? (
        <>
          <Spinner size="sm" color="white" mr={2} />
          {icon}
          {buttonText}
        </>
      ) : (
        <>
          {icon}
          {buttonText}
        </>
      )}
    </Button>
  );
}

CalcActionBtn.propTypes = {
  status: PropTypes.string.isRequired,
  calculationId: PropTypes.number.isRequired,
  production: PropTypes.any.isRequired,
  predictedWeightBeingChanged: PropTypes.bool.isRequired,
};

export default CalcActionBtn;
