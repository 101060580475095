import {
  FormControl,
  FormLabel,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

interface AxesTogglePropsType {
  showAxes: boolean;
  setShowAxes: (arg0: boolean) => void;
}

function AxesToggle({ showAxes, setShowAxes }: AxesTogglePropsType) {
  const textColor = useColorModeValue("gray.600", "white");

  return (
    <FormControl
      display="flex"
      alignItems="center"
      w="35%"
      mr={2}
      justifyContent="space-around"
    >
      <FormLabel htmlFor="axes-toggle" alignItems="center" display="flex" m={0}>
        <Text
          color={textColor}
          fontSize={{
            base: "md",
          }}
        >
          <FormattedMessage
            id="calculation.detail.axesToggle"
            defaultMessage="Show Axes"
          />
        </Text>
      </FormLabel>
      <Switch
        id="axes-toggle"
        isChecked={showAxes}
        onChange={(e) => setShowAxes(e.target.checked)}
      />
    </FormControl>
  );
}

export default AxesToggle;
