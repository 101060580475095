// Chakra Imports
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  MenuDivider,
  Box,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/molecule/sidebar/Sidebar";
// Assets
import { MdLanguage } from "react-icons/md";
import { useAppDispatch } from "store/hooks";
import { logout } from "views/auth/signIn/signInSlice";
import { useHistory } from "react-router-dom";
import useFetchUser from "services/getVerboseName";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import { LanguageContext } from "contexts/LanguageProvider";
import { langugageInput } from "types/calculationTypes";
import useRoutes from "UserRoutes";
import { IoMdMoon, IoMdSunny } from "react-icons/io";

interface NavbarLinksProps {
  onOpen: (...args: any[]) => any;
}

export default function NavbarLinks({ onOpen }: NavbarLinksProps) {
  const routes = useRoutes();
  const { colorMode, toggleColorMode } = useColorMode();
  const { language, setLanguage } = useContext(LanguageContext);
  // Chakra Color Mode
  const dispatch = useAppDispatch();
  const navbarIcon = useColorModeValue("gray.400", "white");
  const history = useHistory();
  const menuBg = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)",
  );
  const user = useFetchUser();
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      p="10px"
      borderRadius="30px"
      gap={2}
      boxShadow={shadow}
    >
      {/* <SearchBar me="10px" borderRadius="30px" /> */}

      <SidebarResponsive routes={routes} />
      {/* <Menu>
        <MenuButton p="0px">
          <Icon as={MdNotificationsNone} color={navbarIcon} />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "unset", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex w="100%" mb="20px" gap={10}>
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
        </MenuList>
      </Menu> */}
      <Menu>
        <MenuButton p="2" onClick={toggleColorMode}>
          <Icon
            color={colorMode === "light" ? "gray.700" : "white"}
            as={colorMode === "light" ? IoMdSunny : IoMdMoon}
            h="25px"
            w="25px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          />
        </MenuButton>
      </Menu>
      <Menu>
        <MenuButton p="0px">
          <Box display="flex" alignItems="center" gap={2}>
            <Icon as={MdLanguage} color={navbarIcon} />
            <Text>{language.toUpperCase()}</Text>
          </Box>
        </MenuButton>
        <MenuList boxShadow={shadow} p="4" bg={menuBg} border="none" mt="2">
          {langugageInput.map((lang) => (
            <MenuItem
              key={lang.language}
              onClick={() => setLanguage(lang.language)}
            >
              {lang.symbol} {lang.language.toUpperCase()}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={user?.username}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; {user?.username}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Newsletter Settings</Text>
            </MenuItem> */}
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px="14px"
              onClick={() => history.push("/user/settings")}
            >
              <Text fontSize="sm">
                <FormattedMessage
                  id="global.navbar.settings"
                  defaultMessage="Settings"
                />
              </Text>
            </MenuItem>
            <MenuDivider />

            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={() => {
                dispatch(logout());
                window.location.replace("/auth/sign-in/");
              }}
            >
              <Text fontSize="sm">
                <FormattedMessage
                  id="global.navbar.logout"
                  defaultMessage="Log out."
                />
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
