import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { Roles } from "views/auth/signIn/signInSlice";

interface ProtectedRouteProps extends RouteProps {
  accessRole: Roles;
}

function ProtectedRoute({ accessRole, ...restProps }: ProtectedRouteProps) {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  if (!isLoggedIn) {
    return <Redirect to="/auth/sign-in" />;
  }

  if (
    accessRole === Roles.SalesPerson ||
    accessRole === Roles.CalculatingUser ||
    accessRole === Roles.ControllingUser
  ) {
    return <Route {...restProps} />;
  }
  return <>no access</>;
}

export default ProtectedRoute;
