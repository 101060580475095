import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const fetchExcelFiles = async (calculationId: string) => {
  try {
    const response = await axios.get(API_URLS.fetchExcelFilesCOF(calculationId));
    return response.data;
  } catch (error) {
    throw new Error("There was a problem fetching the Excel files");
  }
};

export default fetchExcelFiles;
