import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import Card from "components/atoms/Card";
import { toast } from "react-hot-toast";
import useFetchUser from "services/getVerboseName";
import { useGenerateQRCodeMutation } from "./api/QRCodeMutations";
import OTPView from "./components/OTPView";
import ResetPassword from "./components/ResetPassword";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

function SettingsView() {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mutation = useGenerateQRCodeMutation();
  const User = useFetchUser();
  const intl = useIntl();

  const twoFactorEnabled = User?.otp_enabled;
  const onTwoFactorButtonClick = (e: any) => {
    const intl = useIntl();
    e.preventDefault();
    if (User?.email.length > 0) {
      mutation
        .mutateAsync({ email: User?.email, user_id: User?.id })
        .then(() => {
          onOpen();
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error(
        intl.formatMessage({
          id: "global.toasts.addEmail",
          defaultMessage: "Please add an email to your account first.",
        }),
      );
    }
  };
  return (
    <Card
      mt="5rem"
      display="flex"
      flexDirection="column"
      gap={5}
      fontSize={["sm", "lg", "xl"]}
    >
      <Flex>
        <Text fontWeight="bold">
          <FormattedMessage
            id="global.settings.twoFactor"
            defaultMessage="Two Factor Authentication"
          />
        </Text>
        <Spacer />

        <>
          <Button
            onClick={onTwoFactorButtonClick}
            _hover={{ bg: twoFactorEnabled ? "green.500" : "red.500" }}
            bg={twoFactorEnabled ? "green.600" : "red.600"}
            textColor="white"
            disabled={mutation.isLoading || twoFactorEnabled}
          >
            {intl.formatMessage({
              id: twoFactorEnabled
                ? "global.settings.twoFAEnabledB"
                : "global.settings.twoFAEnableB",
              defaultMessage: twoFactorEnabled ? "Enabled" : "Enable",
            })}
          </Button>
          <Modal
            size="2xl"
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader textAlign="center">
                <FormattedMessage
                  id="global.settings.setup2FA"
                  defaultMessage="Setup 2FA"
                />
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <OTPView
                  queryClient={queryClient}
                  User={User}
                  otpUrl={mutation?.data?.otpauth_url}
                  onClose={onClose}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      </Flex>
      <ResetPassword />
    </Card>
  );
}

export default SettingsView;
