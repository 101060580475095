export interface IMaterialType {
  id: number;
  name: string;
  material_marginal_cost_rate: number;
  material_full_cost_rate: number;
  material_density: number;
  comments: string;
}
export interface ProductionSite {
  id: number;
  client_group: number;
  company_name: string;
}

export interface ProductionLine {
  id: number;
  name: string;
  version: string;
  client: number;
  comments: string;
  available_materials: IMaterialType[];
  is_supported?: boolean;
}
export interface IInputParameters {
  id: number;
  calculation: number;
  ml_parameter: number;
  ml_parameter_value: string;
  extra_parameter: string;
  comments: string;
}

export interface IPartNames {
  id: number;
  name: string;
  client_group: string[];
  display_name: string;
  display_name_en: string;
  display_name_de: string;
  description: string;
  description_en: string;
  description_de: string;
}
export type CalculationInputs = {
  customer: number;
  part_name: number;
  material: number;
  part_number: string;
  process_state: "raw" | "finished";
  comments: string;
  test_standard: number;
  customer_revision_number: string;
  pieces_per_year_min: string;
  pieces_per_year_max: string;
  crm_number: string;
  batch_size: number;
  [key: string]: any;
};

export const langugageInput = [
  {
    language: "en",
    symbol: "🇺🇸",
  },
  {
    language: "de",
    symbol: "🇩🇪",
  },
];

export type Language = (typeof langugageInput)[0];
export type CadFileError = {
  file: string[];
  calculation: string[];
  cad_file: string[];
};

export interface IExcelFile {
  id: number;
  name: string;
  calculation: number;
  calculation_offer_save: number;
  production_line: number;
  excel_file: string;
  comments: string;
  production_line_name?: string;
}
export interface ITestStandard {
  id: number;
  name: string;
  description: string;
}
export type OptionType = {
  value: string | boolean | null;
  label: string;
  translationId?: string;
};

export interface IMLparameters {
  id: number;
  unit: string;
  type: string;
  display_name: string;
  name: string;
  created_timestamp: string;
  description: string;
  parameter_type: number;
  num_range_min: number;
  num_range_max: number;
  updated_timestamp: string;
}
export interface UserBasedField {
  id: number;
  name: string;
  recommended_placeholder: string;
  display_name: string;
  parameter_type: number;
  parameter_type_name: string;
  field_type: number;
  field_type_name: string;
  form_type: number;
  form_type_name: string;
  unit: string | null;
  description: string;
  created_timestamp: string;
  updated_timestamp: string;
  current_language: string;
  end_point: string;
  options: any[];
  validation_schema: any;
  simple_user_field_value?: any;
  value?: any;
  translationId?: string;
}
