import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import API_URLS from "utils/apiVersionConfig";

const createExcelFileAPI = async (calculationId: string) => {
  const response = await axios.post(API_URLS.createExcelFileAPI(), {
    name: (Math.random() + 1).toString(36).substring(7),
    calculation: calculationId,
    excel_file_url: "",
  });
  return response.data;
};

const useCreateExcelFile = () => {
  return useMutation(createExcelFileAPI, {
    onError: (err: any) => {
      toast.error(err.response);
    },
  });
};

export default useCreateExcelFile;
