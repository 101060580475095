import {
  Button,
  ButtonSpinner,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  ModalCloseButton,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/molecule/fields/InputField";
import { FormProvider, useForm } from "react-hook-form";
import { useAppSelector } from "store/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { useFetchCurrentClient } from "views/admin/excelBaseline/api/useFetchClients";
import {
  IExcelBaseline,
  ExcelBaselineSchema,
} from "views/admin/excelBaseline/BaselineSchema";
import useMutateExcelBaseline from "views/admin/excelBaseline/api/useMutateExcelBaseline";

interface AddBaselineModalType {
  user: any;
  isOpen: boolean;
  onClose: any;
  isLoading: any;
}

const AddBaselineModal = ({
  user,
  isOpen,
  onClose,
  isLoading,
}: AddBaselineModalType) => {
  const { User } = useAppSelector((state) => state.auth);
  const { currentClient } = useFetchCurrentClient(User.client);
  const textColor = useColorModeValue("gray.700", "white");
  const intl = useIntl();

  const initValues: IExcelBaseline = {
    file: [] as unknown as FileList,
    left_marker: "<",
    right_marker: ">",
    created_by_user: user.user_id,
  };

  const methods = useForm<IExcelBaseline>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(ExcelBaselineSchema),
    defaultValues: initValues,
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;
  const { isSubmitting, onSubmit } = useMutateExcelBaseline(user?.client);

  const onError = (err: any, e: any) => console.log(err, e);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton onClick={onClose} />
        </ModalHeader>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ModalBody>
              <InputField
                color={textColor}
                name="file"
                label={intl.formatMessage({
                  id: "user.excelBaseline.excelFile",
                  defaultMessage: "Excel File",
                })}
                type="file"
                border="none"
                p={1}
              />
              <Flex justifyContent="space-between" gap="4">
                <InputField
                  color={textColor}
                  name="left_marker"
                  label={intl.formatMessage({
                    id: "user.excelBaseline.leftMarker",
                    defaultMessage: "Left Marker",
                  })}
                  borderColor={errors.left_marker && "red.400"}
                  _focus={{
                    borderColor: !errors.left_marker ? "blue.400" : "red.400",
                  }}
                  containerStyles={{ w: "full" }}
                  type="string"
                />
                <InputField
                  color={textColor}
                  name="right_marker"
                  label={intl.formatMessage({
                    id: "user.excelBaseline.rightMarker",
                    defaultMessage: "Right Marker",
                  })}
                  borderColor={errors.right_marker && "red.400"}
                  _focus={{
                    borderColor: !errors.right_marker ? "blue.400" : "red.400",
                  }}
                  containerStyles={{ w: "full" }}
                  type="string"
                  w="full"
                />
              </Flex>
              <Text color="gray.500" fontSize="sm" fontWeight="semibold" mb="2">
                <FormattedMessage
                  id="user.excelBaseline.defaultMarkers"
                  defaultMessage="*Markers are filled by default. Change if necessary."
                />
              </Text>
              <FormLabel htmlFor="client">
                <FormattedMessage
                  id="user.excelBaseline.clientBaseline"
                  defaultMessage="You are setting for: "
                />
                {currentClient?.company_name}
              </FormLabel>
            </ModalBody>
            <ModalFooter>
              <input name="user" type="hidden" defaultValue={user.user_id} />
              <Button
                isLoading={isLoading}
                loadingText={intl.formatMessage({
                  id: "user.excelBaseline.loading",
                  defaultMessage: "Loading",
                })}
                spinner={<ButtonSpinner />}
                disabled={isSubmitting || !methods.formState.isValid}
                type="submit"
                colorScheme="blue"
                w="fit-content"
                alignSelf="flex-end"
              >
                {isSubmitting ? (
                  <FormattedMessage
                    id="user.excelBaseline.uploading"
                    defaultMessage="Uploading..."
                  />
                ) : (
                  <FormattedMessage
                    id="user.excelBaseline.upload"
                    defaultMessage="Upload"
                  />
                )}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default AddBaselineModal;
