import { useEffect, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { Group, Mesh, MeshBasicMaterial, SphereGeometry } from "three";
import {
  AllCoresType,
  PointsOfCoreType,
} from "views/admin/calculations/api/fetchPartitionPlane";

interface CoresPropsType {
  cavities: AllCoresType[];
}

const Cores = ({ cavities }: CoresPropsType) => {
  const { scene } = useThree();
  const cavitiesGroup = useRef<Group>(null);

  const getCavityColor = (index: number): number => {
    // different colors for each cavity
    const colors = [
      0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff, 0xff8000,
      0x8000ff, 0x0080ff, 0xff0080, 0x80ff00, 0x00ff80, 0xff80ff, 0x80ffff,
      0xff8040, 0x40ff80, 0x8040ff, 0x40ff40, 0xff4080,
    ];
    return colors[index % colors.length];
  };

  useEffect(() => {
    if (!cavitiesGroup.current) return;

    cavitiesGroup.current.clear();

    cavities.forEach((cavity: AllCoresType, index: number) => {
      const { points } = cavity;
      const cavityColor = getCavityColor(index);

      points.forEach((point: PointsOfCoreType) => {
        const [x, y, z] = point;

        const sphereGeometry = new SphereGeometry(1, 16, 16);
        const sphereMaterial = new MeshBasicMaterial({ color: cavityColor });
        const sphere = new Mesh(sphereGeometry, sphereMaterial);

        sphere.position.set(x, y, z);

        cavitiesGroup.current.add(sphere);
      });
    });

    scene.add(cavitiesGroup.current);

    return () => {
      if (cavitiesGroup.current) {
        scene.remove(cavitiesGroup.current);
      }
    };
  }, [cavities, scene]);

  return <group ref={cavitiesGroup} />;
};

export default Cores;
