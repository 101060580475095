import { useEffect, useMemo, useRef, useState } from "react";
import { Button, useColorModeValue } from "@chakra-ui/react";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useSetPartingPlanesMatch } from "contexts/ModelHooks";
import InputField from "../../fields/InputField";
import { useIntl } from "react-intl";
import { PlaneWithCoresType } from "views/admin/calculations/api/fetchPartitionPlane";
import { Box3 } from "three";

interface MovePlaneProps {
  calculationId: number;
  planeOffset: number;
  selectedOrientation: string;
  setPlaneOffset: (arg0: number) => void;
  planeWithCores: PlaneWithCoresType;
  planeId: number;
  planeCalculationId: number;
  resetPosition: boolean;
  bbox: Box3;
}

const useDebounce = (callback: any, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return useMemo(() => {
    return (...callbackArgs: any[]) => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...callbackArgs);
      }, delay);
    };
  }, [callback, delay]);
};

function MovePlane({
  calculationId,
  planeOffset,
  selectedOrientation,
  planeWithCores,
  planeId,
  planeCalculationId,
  setPlaneOffset,
  resetPosition,
  bbox,
}: MovePlaneProps) {
  const formStyles = {
    width: "40%",
    display: "flex",
  };
  const intl = useIntl();
  const [limit, setLimit] = useState<number | null>(null);
  const [planePosition, setPlanePosition] = useState<number | string>(
    planeOffset,
  );
  const setPartingPlanesMatch = useSetPartingPlanesMatch();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const formMethods = useForm<any>();
  const brandColor = useColorModeValue("gray.700", "white");

  useEffect(() => {
    const savedPlaneDataString = localStorage.getItem("planeData");
    const savedDataArray = JSON.parse(savedPlaneDataString);

    const correctPlane =
      savedDataArray &&
      savedDataArray.find(
        (calculation: any) => calculation.calculationId === planeCalculationId,
      );
    if (correctPlane) {
      setPlanePosition(correctPlane.position);
    }
  }, []);

  const updateLimit = () => {
    if (bbox) {
      const rangeX = bbox.max.x - bbox.min.x;
      const rangeY = bbox.max.y - bbox.min.y;
      const rangeZ = bbox.max.z - bbox.min.z;

      let limit;
      switch (selectedOrientation) {
        case "YZ":
          limit = Math.ceil(rangeX) / 2;
          break;
        case "XZ":
          limit = Math.ceil(rangeY) / 2;
          break;
        case "XY":
          limit = Math.ceil(rangeZ) / 2;
          break;
        default:
          break;
      }

      setLimit(limit);
    }
  };

  useEffect(() => {
    if (resetPosition) {
      setPlanePosition(0);
      setPlaneOffset(0);
    }
    updateLimit();
  }, [selectedOrientation, bbox]);

  // send the edited position to the local storage
  const handleEditPlane = useDebounce(
    (newOffset: number, selectedOrientation: string) => {
      try {
        const savedPlaneDataString = localStorage.getItem("planeData");
        const planeData = savedPlaneDataString
          ? JSON.parse(savedPlaneDataString)
          : [];

        const existingIndex = planeData.findIndex(
          (item: any) => item.calculationId === calculationId,
        );

        if (existingIndex !== -1) {
          planeData[existingIndex] = {
            planeId,
            calculationId,
            axis: selectedOrientation,
            position: newOffset,
            number_of_cavities: planeWithCores.number_of_cavities,
            total_volume_of_cavities: planeWithCores.total_volume_of_cavities,
          };

          setPartingPlanesMatch(
            selectedOrientation === planeWithCores.axis &&
              newOffset === planeWithCores.position &&
              planeWithCores.number_of_cavities !== null,
          );
        } else {
          setPartingPlanesMatch(
            selectedOrientation === planeWithCores.axis &&
              newOffset === planeWithCores.position &&
              planeWithCores.number_of_cavities !== null,
          );
          planeData.push({
            planeId,
            calculationId,
            axis: selectedOrientation,
            position: newOffset,
            number_of_cavities: planeWithCores.number_of_cavities,
            total_volume_of_cavities: planeWithCores.total_volume_of_cavities,
          });
        }

        localStorage.setItem("planeData", JSON.stringify(planeData));

        toast.success(
          intl.formatMessage({
            id: "global.toasts.partingPlaneUpdated",
            defaultMessage: "Partition plane information updated successfully.",
          }),
        );
      } catch (error) {
        console.error("Error updating partition plane information:", error);
        toast.error(
          intl.formatMessage({
            id: "global.toasts.errorUpdatingPartingPlane",
            defaultMessage: "Failed to update partition plane information.",
          }),
        );
      }
    },
    1000,
  );

  // update planeOffset only with correct values
  useEffect(() => {
    if (planePosition !== "" && limit) {
      const position =
        typeof planePosition === "string"
          ? parseFloat(planePosition)
          : planePosition;

      const minValue = Math.ceil(-limit + 1);
      const maxValue = Math.ceil(limit - 1);
      const newOffset = Math.min(Math.max(position, minValue), maxValue);

      handleEditPlane(newOffset, selectedOrientation);
      setPlaneOffset(newOffset);
    }
  }, [planePosition, selectedOrientation, limit, planeWithCores]);

  // edit plane position with input
  const handleInputChange = (value: string) => {
    if (value !== "") {
      const numericValue = parseFloat(value);
      const withinLimits =
        !isNaN(numericValue) &&
        numericValue >= Math.ceil(-limit + 1) &&
        numericValue <= Math.ceil(limit - 1);

      setPlanePosition(
        withinLimits
          ? value
          : Math.max(
              Math.min(numericValue, Math.ceil(limit - 1)),
              Math.ceil(-limit + 1),
            ).toString(),
      );
    } else {
      setPlanePosition(value);
    }
  };

  // edit plane position with buttons
  const movePlane = (direction: string) => {
    const step = direction === "increment" ? 1 : -1;
    setPlanePosition((prevOffset: any) => {
      const position = parseFloat(prevOffset) || 0;
      const newOffset =
        direction === "decrement"
          ? Math.max(position + step, Math.ceil(-limit + 1))
          : Math.min(position + step, Math.ceil(limit - 1));
      return newOffset;
    });
  };

  // create an interval to continuously update the offset while button is pressed
  const startMovingPlane = (direction: string) => {
    if (intervalId === null) {
      setIntervalId(
        setInterval(() => {
          movePlane(direction);
        }, 300),
      );
    }
  };

  // stop and clean interval
  const stopMovingPlane = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form style={{ ...formStyles }}>
        <Button
          mr={1}
          alignSelf="center"
          bg="blue.500"
          color="white"
          fontWeight="500"
          _hover={{ bg: "blue.600" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => movePlane("decrement")}
          onMouseDown={() => startMovingPlane("decrement")}
          onMouseUp={stopMovingPlane}
          onMouseLeave={stopMovingPlane}
        >
          {"<"}
        </Button>
        <InputField
          color={brandColor}
          name="numericValue"
          value={planePosition}
          onChange={(e) => handleInputChange(e.target.value)}
          type="number"
          textAlign="center"
          style={{ flex: "1" }}
        />
        <Button
          ml={1}
          alignSelf="center"
          bg="blue.500"
          color="white"
          fontWeight="500"
          _hover={{ bg: "blue.600" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => movePlane("increment")}
          onMouseDown={() => startMovingPlane("increment")}
          onMouseUp={stopMovingPlane}
          onMouseLeave={stopMovingPlane}
        >
          {">"}
        </Button>
      </form>
    </FormProvider>
  );
}

export default MovePlane;
