import { extendTheme, HTMLChakraProps, ThemingProps } from "@chakra-ui/react";
import buttonStyles from "./components/button";
import inputStyles from "./components/input";
import cardComponent from "./components/card";
import breakpoints from "./foundations/breakpoints";
import globalStyles from "./styles";

export default extendTheme(
  breakpoints,
  globalStyles,
  buttonStyles,
  inputStyles,
  cardComponent,
);

export interface CustomCardProps extends HTMLChakraProps<"div">, ThemingProps {}
