import { Box, Spinner, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

function ResultLoader() {
  return (
    <Box
      w="full"
      display="flex"
      gap="10"
      flexWrap="wrap"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={[10, 20, 40]}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.400"
        size="xl"
      />
      <Text ml={4}>
        <FormattedMessage 
          id="calculation.detail.resultLoader"
          defaultMessage="Your results might take 2 minutes to generate."
        />
      </Text>
    </Box>
  );
}

export default ResultLoader;
