import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import API_URLS from "utils/apiVersionConfig";
import { useIntl } from 'react-intl';

const deletePartname = (partnameId: number) => {
  return axios.delete(API_URLS.deletePartname(partnameId));
};
const editPartname = (data: any) => {
  return axios.put(API_URLS.editPartname(data.partnameId), data.partname);
};
const addPartname = (data: any) => {
  return axios.post(API_URLS.addPartname(), data.partname);
};
export const useDeletePartnameMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePartname, {
    onSuccess: () => {
      toast.success("Partname deleted successfully");
      queryClient.invalidateQueries(["partnames"]); // Replace 'partnames' with the appropriate query key
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });
};
export const useEditPartnameMutation = (onClose: any) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  return useMutation(editPartname, {
    onSuccess: () => {
      onClose();
      toast.success(        
        intl.formatMessage({
        id: 'global.toasts.editedPartname',
        defaultMessage: 'Partname edited successfully.',
      }))
      queryClient.invalidateQueries(["partnames"]); // Replace 'partnames' with the appropriate query key
    },
    onError: () => {
      toast.error(        
        intl.formatMessage({
        id: 'global.toasts.errorEditingPartname',
        defaultMessage: 'Something went wrong editing partname.',
      }))
    },
  });
};
export const useAddPartnameMutation = (onClose: any) => {
  const queryClient = useQueryClient();

  return useMutation(addPartname, {
    onSuccess: () => {
      onClose();
      toast.success("Partname added successfully");
      queryClient.invalidateQueries(["partnames"]); // Replace 'partnames' with the appropriate query key
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });
};
