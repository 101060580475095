import axios from "axios";

import API_URLS from "utils/apiVersionConfig";

const getSimpleFieldsValuesById = async (calculationId: number) => {
  const response = await axios.get(API_URLS.postSimpleUserFields(), {
    params: {
      calculation: calculationId,
    },
  });
  return response.data;
};

export default getSimpleFieldsValuesById;
