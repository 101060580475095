import axios from "axios";
import { CalculationList } from "models/calculation";
import API_URLS from "utils/apiVersionConfig";

export interface CalculationResponse {
  results: CalculationList | null;
  next: string | null;
  previous: string | null;
  count: number;
}

const fetchCalculations = async <T, U>(
  pageParam: T,
  status: U | null,
): Promise<CalculationResponse> => {
  const response = await axios.get(
    API_URLS.fetchCalculations(pageParam, status),
  );
  return response.data;
};
export default fetchCalculations;
