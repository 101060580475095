/*!
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Casculate - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Casculate (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { useState } from "react";
import { Box, Button, SimpleGrid, Spinner } from "@chakra-ui/react";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import { SortingState } from "@tanstack/react-table";
import { useInfiniteQuery } from "@tanstack/react-query";
import { OptionType } from "types/calculationTypes";
import { statusFilterOptions } from "variables/constants";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { toast } from "react-hot-toast";
import { useIntl } from "react-intl";
import fetchCalculations from "./api/fetchCalculations";
import CalculationFilters from "./components/CalculationFilters";
import { useAppSelector } from "store/hooks";
import { useFetchCurrentClient } from "views/admin/excelBaseline/api/useFetchClients";

export default function CalculationView() {
  const intl = useIntl();
  const userClientSite = useAppSelector((state) => state.auth.User.client);
  const { currentClient } = useFetchCurrentClient(userClientSite);
  const initialStatusFilter: OptionType = {
    ...statusFilterOptions[0],
    label: intl.formatMessage({ id: statusFilterOptions[0]?.translationId }),
  };
  const [sorting, setSorting] = useState<SortingState>([]);

  const [calculationStatus, setCalculationStatus] =
    useState<OptionType>(initialStatusFilter);

  const { data, isLoading, isError, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      staleTime: Infinity,
      refetchInterval: 5000,
      queryKey: ["calculations", calculationStatus.value],
      queryFn: ({ pageParam = 1 }) =>
        fetchCalculations(pageParam, calculationStatus.value),
      getNextPageParam: (lastPage) => {
        return lastPage?.next?.split("p=")[1];
      },
    });
  const calculations = data?.pages.flatMap((page) => page.results);

  if (isLoading) {
    return (
      <Box display="flex" justifyItems="center" alignItems="center" h="100vh">
        <Spinner m="auto" color="brand.500" size="xl" />
      </Box>
    );
  }
  if (isError) {
    return (
      <div>
        {toast.error("Something went wrong while fetching calculations.")}
      </div>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="20px" spacing={{ base: "20px", xl: "20px" }}>
        <CalculationFilters
          setSorting={setSorting}
          sorting={sorting}
          calculationStatus={calculationStatus}
          setCalculationStatus={setCalculationStatus}
        />
        <ComplexTable
          currentClient={currentClient}
          setSorting={setSorting}
          sorting={sorting}
          tableData={calculations}
        />
      </SimpleGrid>
      {hasNextPage && (
        <Button
          onClick={() => {
            fetchNextPage();
          }}
          mt="20px"
          mx="auto"
          display="block"
          colorScheme="brand"
          _hover={{ bg: "brand.600" }}
        >
          <TriangleDownIcon color="white" />
        </Button>
      )}
    </Box>
  );
}
