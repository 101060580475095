import { useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  FormControl,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";
import { CalculationInputs } from "types/calculationTypes";
import { useQueryClient } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import FormItem from "../../newCalculation/components/FormItem";
import usePutSimpleFields from "../api/useEditSimpleFields";

type EditCalculationsProps = {
  singleCalculation: CalculationType;
  formsWithValues: any[];
};

function EditCalculations({
  singleCalculation,
  formsWithValues,
}: EditCalculationsProps) {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const formMethods = useForm<CalculationInputs>();
  const {
    handleSubmit,
    formState: { isValid, dirtyFields },
  } = formMethods;
  const { mutateAsync } = usePutSimpleFields();

  const onSubmit: SubmitHandler<CalculationInputs> = async (values) => {
    if (Object.keys(dirtyFields).length === 0) {
      toast.error(
        intl.formatMessage({
          id: "global.toasts.noChanges",
          defaultMessage: "No changes in form.",
        }),
      );
    } else {
      const dirtyFieldPutData = Object.entries(dirtyFields).map(([key]) => {
        const itemFiltered = formsWithValues?.find((item) => item.name === key);
        const changedValue = values[key as string];
        const postData: any = {
          id: itemFiltered.fieldValueID,
          calculation: singleCalculation.id,
          simple_user_field: itemFiltered.id,
          name: key,
          simple_user_field_value: changedValue,
        };
        return postData;
      });
      dirtyFieldPutData.forEach(async (item) => {
        try {
          await mutateAsync(item);
          toast.success(
            intl.formatMessage({
              id: "global.toasts.changed",
              defaultMessage: "Successfully changed.",
            }),
          );
        } catch (error) {
          console.error(error);
        }
      });
    }
  };
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({ queryKey: ["formFieldValues"] });
    };
  }, [queryClient]);

  return (
    <Accordion allowToggle defaultChecked={false}>
      <FormProvider {...formMethods}>
        <AccordionItem>
          <h2>
            <AccordionButton
              borderColor="transparent"
              bg="blue.500"
              color="white"
              _hover={{ bg: "blue.600" }}
            >
              <Box flex="1" textAlign="left" fontSize="3xl" fontWeight="bold">
                <FormattedMessage
                  id="calculation.detail.inputParameters"
                  defaultMessage="Input Parameters"
                />
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction="column"
                align="center"
                w={{ sm: "full", md: "full", lg: "850px" }}
                mx="auto"
              >
                <FormControl>
                  <Flex direction="column" w="100%" my={10}>
                    <Stack direction="column" spacing="20px">
                      <Stack direction="column">
                        {formsWithValues?.map((field) => (
                          <FormItem
                            key={field.id}
                            formItem={field}
                            isEditingForm
                          />
                        ))}
                      </Stack>
                    </Stack>
                    <Button
                      type="submit"
                      variant="no-hover"
                      bg="brand.400"
                      alignSelf="flex-end"
                      mt="24px"
                      isDisabled={!isValid}
                      _hover={{ bg: "blue.600" }}
                      w={{ sm: "75px", lg: "100px" }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap="5px"
                      h="35px"
                    >
                      <Text fontSize="sm" color="#fff" fontWeight="bold">
                        <FormattedMessage
                          id="global.settings.change"
                          defaultMessage="Change"
                        />
                      </Text>
                    </Button>
                  </Flex>
                </FormControl>
              </Flex>
            </form>
          </AccordionPanel>
        </AccordionItem>
      </FormProvider>
    </Accordion>
  );
}

export default EditCalculations;
