interface Parameter {
  id: number;
  name: string;
  display_name: string;
  parameter_type: number;
  client_group: number;
  parameter_type_name: string;
  field_type: number;
  field_type_name: string;
  has_underscore: boolean;
  is_required: boolean;
  options: { id: string; name: string }[] | null;
  validation_schema: { required: boolean; message?: string };
  created_timestamp: string;
  updated_timestamp: string;
  current_language: string;
  fieldValueID: number;
  value: string;
}

interface Values {
  [key: string]: string;
}

export interface CalculationParameterValue {
  name: string;
  simple_user_field_value: string;
}

export function matchInputParameters(
  keys: Parameter[],
  selectedValues: Values,
): CalculationParameterValue[] {
  const calculation_parameter_values: CalculationParameterValue[] = [];

  for (const key of keys) {
    const name = key.display_name;
    const value = selectedValues[name] || "";
    calculation_parameter_values.push({
      name: key.name,
      simple_user_field_value: value,
    });
  }

  return calculation_parameter_values;
}
