import { object, string, InferType, boolean, number } from "yup";

export const userSchema = object({
  username: string().required("Please enter a valid email address."),
  password: string().required(),
});
export type AuthLogin = InferType<typeof userSchema>;
export type MaterialForm = InferType<typeof materialSchema>;

export const clientSchema = object({
  company_name: string().required("This field is required."),
  first_name: string().required("This field is required."),
  last_name: string().required("This field is required."),
  email: string().required().email("This field is required."),
  vat_id: string().required("This field is required."),
  is_active: boolean().required("This field is required."),
  address_prefixline: string().required("This field is required."),
  address_street: string().required("This field is required."),
  client_type: number()
    .required()
    .positive()
    .integer("This field is required."),
});
export const customerSchema = object({
  name: string()
    .matches(/^[^-_]+$/, 'Name cannot contain "-" or "_"')
    .required("This field is required"),
});
export const materialSchema = object({
  id: number().optional(),
  material_name: string().required("This field is required."),
  material_marginal_cost_rate: number().test(
    "maxDigits",
    "Must have no more than 2 digits before the decimal point",
    (value) => {
      if (!value) return true; // Allow empty value
      const digitsBeforeDecimal = Math.floor(value).toString().length;
      return digitsBeforeDecimal <= 2;
    },
  ),
  material_full_cost_rate: number().test(
    "maxDigits",
    "Must have no more than 2 digits before the decimal point",
    (value) => {
      if (!value) return true; // Allow empty value
      const digitsBeforeDecimal = Math.floor(value).toString().length;
      return digitsBeforeDecimal <= 2;
    },
  ),
});
export const partnameSchema = object().shape({
  display_name_en: string().required("English Display Name is required"),
  display_name_de: string().required("German Display Name is required"),
  description_en: string(),
  description_de: string()
});
