import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import API_URLS from 'utils/apiVersionConfig';
import { useIntl } from 'react-intl';

/**
 * Marks a calculation as deleted by updating its properties.
 * @param {number} calculationID - The ID of the calculation to be deleted.
 * @param {number} userID - The ID of the user performing the deletion.
 * @returns {Promise<Object>} A promise that resolves with the response data from the server.
 * @throws {Error} If the request fails or encounters an error.
 */
const deleteHideCalculation = async (calculationID:number, userID:number) => {
    const deletionPayload = {
        is_deleted: true,
        deleted_by_user: userID
    };

    try {
        const response = await axios.patch(API_URLS.putCalculation(calculationID), deletionPayload);
        return response.data;
    } catch (error) {
        throw new Error('Failed to delete calculation');
    }
};

const useCalculationDeleteHideMutation = () => {
    const queryClient = useQueryClient();
    const intl = useIntl();

    const deleteCustomerMutation = useMutation(
        ({ calculationID, userID }:{calculationID:number, userID:number}) => deleteHideCalculation(calculationID, userID), // Corrected the usage of deleteHideCalculation
        {
            onSuccess: () => {
                toast.success(
                    intl.formatMessage({
                        id: 'global.toasts.deleted',
                        defaultMessage: 'Calculation deleted successfully.',
                    }),
                );
                queryClient.invalidateQueries(["calculations"]); 
            },
            onError: () => {
                toast.error(
                    intl.formatMessage({
                        id: 'global.toasts.errorDeletingCalculation',
                        defaultMessage: 'Something went wrong while deleting calculation.',
                    }),
                );
            },
        }
    );

    return deleteCustomerMutation;
};

export default useCalculationDeleteHideMutation;
