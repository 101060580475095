import { Box3 } from "three";

export interface FloorProps {
  bbox: Box3;
}

const Floor = ({ bbox }: FloorProps) => {
  const enlargedWidth = (bbox.max.x - bbox.min.x) * 2;
  const enlargedLength = (bbox.max.y - bbox.min.y) * 2;

  const planeArgs: [number, number] = [enlargedWidth, enlargedLength];

  return (
    <group>
      <mesh receiveShadow position={[0, 0, bbox.min.z]}>
        <planeGeometry args={planeArgs} />
        <shadowMaterial opacity={0.2} />
      </mesh>
    </group>
  );
};

export default Floor;
