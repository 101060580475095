import React, { useState } from "react";
import {
  Box,
  Button,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/atoms/Card";
import { ICustomer } from "models/customer";
import AddCustomer from "views/admin/customers/components/AddCustomer";
import CustomerItem from "views/admin/customers/components/CustomerItem";
import CustomModal from "components/molecule/modal/CustomModal";
import CenteredSpinnerRound from "components/atoms/CenteredSpinnerRound";
import SearchBar from "components/molecule/searchBar/SearchBar";
import useFetchCustomers from "../calculations/calculationView/api/fetchCustomers";
import EditCustomer from "./components/EditCustomer";
import useDeleteMutation from "./api/useDeleteCustomer";
import useSearch from "./api/useSearch";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

export default function CustomerView() {
  const {
    isOpen: isAddCustomerOpen,
    onClose: onCloseAddCustomer,
    onOpen: onAddCustomerOpen,
  } = useDisclosure();
  const {
    isOpen: isEditCustomerOpen,
    onOpen: onEditCustomerOpen,
    onClose: onEditCustomerClose,
  } = useDisclosure();
  const customerBg = useColorModeValue("white", "gray.600");
  const intl = useIntl();

  const finalRef = React.useRef(null);
  const [selectedCustomer, setSelectedCustomer] = useState<
    ICustomer | undefined
  >();

  const {
    data: customers,
    refetch: refetchCustomers,
    isLoading: loadingCustomers,
  } = useFetchCustomers();
  const { filteredResult, handleSearch } = useSearch(customers);

  const deleteCustomerMutation = useDeleteMutation();
  const deleteCustomer = (id: number) => {
    deleteCustomerMutation.mutate({ id });
  };

  const editCustomer = (clientName: string) => {
    onEditCustomerOpen();
    const customerMatch = customers.find(
      (customer) => customer.name === clientName,
    );
    setSelectedCustomer(customerMatch);
  };

  if (loadingCustomers) return <CenteredSpinnerRound />;

  // Chakra Color Mode
  return (
    <Box ref={finalRef} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card bg={customerBg}>
        <Box display="flex">
          <SearchBar onChange={handleSearch} />
          <Button
            ml={4}
            w="fit-content"
            colorScheme="brand"
            placeSelf="end"
            mb="10"
            onClick={onAddCustomerOpen}
          >
            <FormattedMessage id="calculation.data.add" defaultMessage="Add" />
          </Button>
        </Box>
        {filteredResult?.map((customer, index) => (
          <CustomerItem
            key={index}
            id={customer.id}
            name={customer.name}
            deleteCustomer={deleteCustomer}
            editCustomer={editCustomer}
          />
        ))}
      </Card>

      <CustomModal
        finalFocusRef={finalRef}
        isOpen={isAddCustomerOpen}
        onClose={onCloseAddCustomer}
        size="6xl"
        closeOnOverlayClick={false}
        title={intl.formatMessage({
          id: "calculation.data.addCustomer",
          defaultMessage: "Add Customer",
        })}
      >
        <AddCustomer onClose={onCloseAddCustomer} />
      </CustomModal>
      <CustomModal
        isOpen={isEditCustomerOpen}
        onClose={onEditCustomerClose}
        size="6xl"
        closeOnOverlayClick={false}
        title={intl.formatMessage({
          id: "calculation.data.editCustomer",
          defaultMessage: "Edit Customer",
        })}
      >
        <EditCustomer
          isOpen={isEditCustomerOpen}
          onClose={onEditCustomerClose}
          selectedCustomer={selectedCustomer}
          refetchCustomers={refetchCustomers}
        />
      </CustomModal>
    </Box>
  );
}
