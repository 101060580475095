import { ChangeEvent } from "react";
import { Select } from "@chakra-ui/react";

interface SelectAxisProps {
  handleOrientationChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  selectedOrientation: string;
}

function SelectAxis({
  handleOrientationChange,
  selectedOrientation,
}: SelectAxisProps) {
  return (
    <Select
      borderRadius="16px"
      id="selectAxis"
      mr={2}
      w="25%"
      alignSelf="center"
      bg="blue.500"
      color="white"
      fontWeight="500"
      _hover={{ bg: "blue.600" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      value={selectedOrientation}
      onChange={handleOrientationChange}
    >
      <option value="YZ">YZ</option>
      <option value="XZ">XZ</option>
      <option value="XY">XY</option>
    </Select>
  );
}

export default SelectAxis;
