import { Button, Flex, useColorModeValue } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/molecule/fields/InputField";
import TextareaField from "components/molecule/fields/TextAreaField";
import { FormProvider, useForm } from "react-hook-form";
import { useAppSelector } from "store/hooks";
import { IPartNames } from "types/calculationTypes";
import { partnameSchema } from "utils/yupSchemas";
import { useAddPartnameMutation, useEditPartnameMutation } from "../api/api";
import { useIntl } from "react-intl";

interface AddPartnameProps {
  onClose: () => void;
  editForm?: boolean;
  selectedPartname?: IPartNames;
}

function AddPartname({
  onClose,
  editForm,
  selectedPartname,
}: AddPartnameProps) {
  const intl = useIntl();
  const textColor = useColorModeValue("gray.700", "white");
  const clientGroup = useAppSelector((state) => state.auth.User.client_group);
  const formMethods = useForm<IPartNames>({
    resolver: yupResolver(partnameSchema),
    mode: "onChange",
    defaultValues: editForm && selectedPartname,
  });
  const { mutate: addPartname } = useAddPartnameMutation(onClose);
  const { mutate: editPartname } = useEditPartnameMutation(onClose);
  const onSubmit = (data: IPartNames) => {
    const dataToSend = {
      ...data,
      client_group: clientGroup,
    };
    if (editForm)
      return editPartname({
        partname: dataToSend,
        partnameId: selectedPartname?.id,
      });
    addPartname({ partname: dataToSend });
  };
  const validationSchema = { required: true };
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Flex gap={4} direction="column">
          <InputField
            color={textColor}
            name="display_name_en"
            label={intl.formatMessage({
              id: "calculation.data.displayNameEN",
              defaultMessage: "Display Name (EN)",
            })}
            validationSchema={validationSchema}
          />
          <InputField
            color={textColor}
            name="display_name_de"
            label={intl.formatMessage({
              id: "calculation.data.displayNameDE",
              defaultMessage: "Display Name (DE)",
            })}
            validationSchema={validationSchema}
          />
          <TextareaField
            color={textColor}
            name="description_en"
            label={intl.formatMessage({
              id: "calculation.data.descriptionEN",
              defaultMessage: "Description (EN)",
            })}
          />
          <TextareaField
            color={textColor}
            name="description_de"
            label={intl.formatMessage({
              id: "calculation.data.descriptionDE",
              defaultMessage: "Description (DE)",
            })}
          />
          <Button
            isDisabled={!formMethods.formState.isValid}
            type="submit"
            mt={4}
            w="fit-content"
            colorScheme="blue"
          >
            {editForm
              ? intl.formatMessage({
                  id: "calculation.data.edit",
                  defaultMessage: "Edit",
                })
              : intl.formatMessage({
                  id: "calculation.data.add",
                  defaultMessage: "Add",
                })}
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
}

export default AddPartname;
