import { Box, Divider, Text } from "@chakra-ui/react";
import IconBar from "components/atoms/icons/IconBar";
import { FormattedMessage } from "react-intl";

interface CustomerItemProps {
  id?: number;
  name: string;
  deleteCustomer?: (param: number) => void;
  editCustomer?: (param: string) => void;
  viewCustomer?: () => void;
}

function CustomerItem({
  name,
  deleteCustomer,
  editCustomer,
  id,
  viewCustomer,
}: CustomerItemProps) {
  return (
    <Box p={5} key={id}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text>
          <span
            style={{
              fontWeight: "bold",
              display: "inline-block",
              marginRight: "2em",
            }}
          >
            <FormattedMessage
              id="calculation.data.customerName"
              defaultMessage="Customer Name:"
            />
          </span>
          {name}
        </Text>
        <IconBar
          onViewClick={viewCustomer}
          onDeleteClick={() => deleteCustomer(id!)}
          onEditClick={() => editCustomer(name!)}
        />
      </Box>
      <Divider mt={2} />
    </Box>
  );
}

export default CustomerItem;
