import { Flex } from "@chakra-ui/react";
import { Select as ChakraSelect } from "@chakra-ui/select";
import { SortingState } from "@tanstack/react-table";
import { LanguageContext } from "contexts/LanguageProvider";
import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { OptionType } from "types/calculationTypes";
import { dateFilterOptions, statusFilterOptions } from "variables/constants";
import { FormattedMessage } from "react-intl";

type CalculationFiltersProps = {
  calculationStatus: OptionType;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  setCalculationStatus: React.Dispatch<React.SetStateAction<OptionType>>;
};

function CalculationFilters({
  setSorting,
  sorting,
  setCalculationStatus,
  calculationStatus,
}: CalculationFiltersProps) {
  const intl = useIntl();

  const { language } = useContext(LanguageContext);

  const statusOptions = statusFilterOptions?.map((option) => ({
    ...option,
    label: intl.formatMessage({ id: option.translationId }),
    value: option.value === null ? "all" : option.value.toString(),
  }));

  const dateOptions = dateFilterOptions?.map((option) => ({
    ...option,
    label: intl.formatMessage({ id: option.translationId }),
    value: option.value ? "desc" : "asc", // Asigna "desc" si option.value es true, "asc" si es false
  }));

  useEffect(() => {
    // Update the selected option state when the language context changes
    setCalculationStatus((prev) => {
      return {
        ...prev,
        label: intl.formatMessage({ id: prev.translationId }),
      };
    });
  }, [setCalculationStatus, language, intl]);

  return (
    <Flex placeSelf="flex-end" gap={[2, 4, 6]}>
      <ChakraSelect
        onChange={(event) => {
          const selectedValue = event.target.value;
          const option = statusOptions.find(
            (option) => option.value === selectedValue,
          );
          if (selectedValue === "all") {
            setCalculationStatus({
              value: null,
              label: "All",
              translationId: "user.dashboard.statusAll",
            });
          } else {
            setCalculationStatus(option);
          }
        }}
        value={
          calculationStatus.value === null
            ? "all"
            : calculationStatus.value.toString()
        }
        sx={{
          "&:focus": {
            boxShadow: "none",
            borderColor: "gray.200",
          },
          "&:active": {
            borderColor: "gray.200",
          },
        }}
      >
        <option disabled value="placeholder">
          <FormattedMessage
            id="user.dashboard.statusFilter"
            defaultMessage="Status"
          />
        </option>
        {statusOptions.map((option, index) => (
          <option key={index} value={option.value.toString()}>
            {option.label}
          </option>
        ))}
      </ChakraSelect>
      <ChakraSelect
        onChange={(event) => {
          const option = dateOptions.find(
            (option) => option.value === event.target.value,
          );
          setSorting([
            {
              id: "created_timestamp",
              desc: option.value === "desc",
            },
          ]);
        }}
        value={sorting[0]?.desc ? "desc" : "asc"}
        sx={{
          "&:focus": {
            boxShadow: "none",
            borderColor: "gray.200",
          },
          "&:active": {
            borderColor: "gray.200",
          },
        }}
      >
        <option disabled value="placeholder">
          <FormattedMessage
            id="user.dashboard.dateFilter"
            defaultMessage="Date"
          />
        </option>
        {dateOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </ChakraSelect>
    </Flex>
  );
}

export default CalculationFilters;
