import { Text, TextProps } from "@chakra-ui/react";

interface TextAreaProps extends TextProps {
  maxLength: number;
  currentLength: number;
}

function TextAreaCount({ currentLength, maxLength, ...rest }: TextAreaProps) {
  return <Text {...rest}>{`${currentLength}/${maxLength}`}</Text>;
}

export default TextAreaCount;
