import { Flex, Icon } from "@chakra-ui/react";
import { IoMdEye } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";

type IconBarProps = {
  onViewClick?: () => void;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
};

function IconBar({ onViewClick, onDeleteClick, onEditClick }: IconBarProps) {
  return (
    <div>
      {" "}
      <Flex gap="3">
        {onViewClick ? (
          <Icon
            as={IoMdEye}
            w="6"
            h="6"
            rounded="full"
            p="1"
            fill="white"
            bg="gray"
            cursor="pointer"
            onClick={onViewClick}
          />
        ) : null}
        {onDeleteClick ? (
          <Icon
            as={MdDelete}
            w="6"
            h="6"
            p="1"
            rounded="full"
            fill="white"
            bg="red"
            cursor="pointer"
            onClick={onDeleteClick}
          />
        ) : null}
        {onEditClick ? (
          <Icon
            as={MdEdit}
            w="6"
            h="6"
            p="1"
            fill="white"
            bg="navy.300"
            cursor="pointer"
            rounded="full"
            onClick={onEditClick}
          />
        ) : null}
      </Flex>
    </div>
  );
}

export default IconBar;
