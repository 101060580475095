import { useContext } from "react";
import ModelContext from "./ModelProvider";

export function useCameraInitialPosition(): ModelContextType["state"]["cameraInitialPosition"] {
  const {
    state: { cameraInitialPosition },
  } = useContext(ModelContext);

  return cameraInitialPosition;
}

export function usePartingPlanesMatch(): ModelContextType["state"]["partingPlanesMatch"] {
  const {
    state: { partingPlanesMatch },
  } = useContext(ModelContext);

  return partingPlanesMatch;
}

export function useSetCameraInitialPosition(): ModelContextType["actions"]["setCameraInitialPosition"] {
  const {
    actions: { setCameraInitialPosition },
  } = useContext(ModelContext);

  return setCameraInitialPosition;
}

export function useSetPartingPlanesMatch(): ModelContextType["actions"]["setPartingPlanesMatch"] {
  const {
    actions: { setPartingPlanesMatch },
  } = useContext(ModelContext);

  return setPartingPlanesMatch;
}
