import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ProductionLine } from "types/calculationTypes";
import API_URLS from "utils/apiVersionConfig";

const fetchProductionLines = async () => {
  const response = await axios.get(API_URLS.fetchProductionLines());
  return response.data;
};

// write react query hook for the above function
const useFetchProductionLines = () => {
  const productionLineQuery = useQuery<ProductionLine[], Error>(
    ["productionlines"],
    fetchProductionLines,
  );
  return productionLineQuery;
};

export default useFetchProductionLines;
