import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const fetchCalculatorOverwrite = async (
  calculationId: string,
): Promise<any[]> => {
  const response = await axios.get(
    API_URLS.fetchCalculatorOverwrite(calculationId),
  );
  return response.data;
};

export default fetchCalculatorOverwrite;
