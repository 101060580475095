import { CheckIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Input,
  Td,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MdUndo } from "react-icons/md";
import { useIntl } from "react-intl";

type Result = {
  id?: number;
  ml_parameter_name?: string;
  ml_parameter_value: string;
  client: number;
  client_group: number;
  production_line: number;
  default_ml_parameter_value?: string;
  modified?: boolean;
  modified_by_user?: number;
  modified_by_user_name?: string;
  ml_parameter_display_name?: string;
};
type ResultItemProps = {
  result: Result;
  calculationId: string;
  mutate: any;
  isEditable: boolean;
  userId?: number;
  verboseName?: string;
  createExcelFile?: () => Promise<any>;
};

function ResultItem({
  result,
  calculationId,
  mutate,
  isEditable = true,
  userId,
  createExcelFile,
  verboseName = "",
}: ResultItemProps) {
  const valueNotDefault = result.modified;
  const brandColor = useColorModeValue("gray.700", "white");
  const intl = useIntl();
  const [inputValue, setInputValue] = useState(
    valueNotDefault
      ? result.ml_parameter_value
      : result.default_ml_parameter_value,
  );

  const queryClient = useQueryClient();
  const mutateCalculation = async () => {
    const data: Result = {
      ml_parameter_value: inputValue,
      client_group: result.client_group,
      production_line: result.production_line,
      client: result.client,
      modified_by_user: userId,
      modified: true,
      modified_by_user_name: verboseName,
    };

    await mutate({ calculationId, id: result.id, data })
      .then(() => {
        toast.success(
          intl.formatMessage({
            id: "global.toasts.calcUpdated",
            defaultMessage: "Calculation updated.",
          }),
        );
        queryClient.invalidateQueries(["excelFiles"]);
        queryClient.invalidateQueries(["calculationOverrides"]);
        createExcelFile();
      })
      .catch(() => {
        toast.error(
          intl.formatMessage({
            id: "global.toasts.errorUpdatingCalc",
            defaultMessage: "CError while updating.",
          }),
        );
      });
  };

  const setToDefault = async () => {
    const data: Result = {
      ml_parameter_value: result.default_ml_parameter_value,
      client_group: result.client_group,
      production_line: result.production_line,
      client: result.client,
      modified_by_user: userId,
      modified: false,
      modified_by_user_name: verboseName,
    };

    await mutate({ calculationId, id: result.id, data })
      .then(() => {
        toast.success("Value set to default.");
        setInputValue(result.default_ml_parameter_value);
        queryClient.invalidateQueries(["excelFiles"]);
        queryClient.invalidateQueries(["calculationOverrides"]);
        createExcelFile();
      })
      .catch(() => {
        toast.error("Error while updating.");
      });
  };
  useEffect(() => {
    setInputValue(
      valueNotDefault
        ? result.ml_parameter_value
        : result.default_ml_parameter_value,
    );
  }, [
    valueNotDefault,
    result.ml_parameter_value,
    result.default_ml_parameter_value,
  ]);

  return (
    <Tr key={result.id}>
      <Td>{result.ml_parameter_display_name}</Td>
      <Td
        display="flex"
        justifyContent="space-between"
        gap={10}
        alignItems="center"
      >
        <Input
          color={brandColor}
          _disabled={{
            textColor: "gray.600",
            outlineColor: "gray.300",
            cursor: "not-allowed",
          }}
          type="text"
          outlineColor="gray"
          name={result.ml_parameter_name}
          value={inputValue}
          onFocus={(e) => e.target.select()}
          isDisabled={!isEditable}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        {isEditable ? (
          <Flex justifyContent="center" alignItems="center" gap="2">
            {valueNotDefault && (
              <Tooltip label="Undo" aria-label="Undo tooltip" placement="top">
                <span>
                  <Icon
                    as={MdUndo}
                    cursor="pointer"
                    _hover={{ color: "red.500" }}
                    h="6"
                    w="6"
                    onClick={setToDefault}
                  />
                </span>
              </Tooltip>
            )}

            <Tooltip label="Confirm" aria-label="A tooltip" placement="top">
              <Icon
                as={CheckIcon}
                cursor="pointer"
                _hover={{ color: "green.500" }}
                h="6"
                w="6"
                onClick={mutateCalculation}
              />
            </Tooltip>
          </Flex>
        ) : (
          <Box>
            {valueNotDefault && (
              <Tooltip
                label={`Modified by ${result.modified_by_user_name}`}
                aria-label="A tooltip"
                placement="top"
              >
                <Icon
                  as={InfoIcon}
                  cursor="pointer"
                  color="blue.500"
                  _hover={{ color: "blue.300" }}
                  h="6"
                  w="6"
                />
              </Tooltip>
            )}
          </Box>
        )}
      </Td>
    </Tr>
  );
}

export default ResultItem;
