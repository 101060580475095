import * as yup from "yup";

export const calculationSchema = yup.object().shape({
  customer: yup.number().required(),
  comments: yup.string().optional(),
  material: yup.number().required("This field is required"),
  part_name: yup.number().required("This field is required"),
  process_state: yup.string().required("This field is required"),
  test_standard: yup.mixed().optional().nullable(),
  customer_revision_number: yup.string().optional(),
  part_number: yup.string().required("This field is required"),
  pieces_per_year_max: yup.string().optional(),
  pieces_per_year_min: yup.string().optional(),
  crm_number: yup.string().optional(),
  batch_size: yup.number().optional(),
});
export const fileSchema = yup.object().shape({
  cad_file: yup
    .mixed<FileList>()
    .required("File is required")
    .test("fileType", "Only .stp, .stl and .step files are allowed", (value) => {
      return (
        value[0]?.name?.includes(".stp") ||
        value[0]?.name?.includes(".step") ||
        value[0]?.name?.includes(".STEP") ||
        value[0]?.name?.includes(".STP") ||
        value[0]?.name?.includes(".stl") ||
        value[0]?.name?.includes(".STL")
      );
    }),
});

export type FileInput = yup.InferType<typeof fileSchema>;
export type CalculationSchemaType = yup.InferType<typeof calculationSchema>;
