import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { usePartingPlanesMatch } from "contexts/ModelHooks";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdWarning } from "react-icons/md";
import { PlaneWithCoresType } from "views/admin/calculations/api/fetchPartitionPlane";

interface CavitiesDataType {
  planeWithCores: PlaneWithCoresType;
}

function CavitiesData(props: CavitiesDataType) {
  const { planeWithCores } = props;
  const [cavities, setCavities] = useState<{
    numberOfCavities: number | null;
    totalVolumeinLiters: number | null;
  } | null>(null);
  const intl = useIntl();
  const partingPlanesMatch = usePartingPlanesMatch();

  useEffect(() => {
    if (
      planeWithCores &&
      typeof planeWithCores.number_of_cavities === "number"
    ) {
      setCavities({
        numberOfCavities: planeWithCores.number_of_cavities,
        totalVolumeinLiters: +(
          planeWithCores.total_volume_of_cavities * 0.000001
        ).toFixed(3),
      });
    }
  }, [planeWithCores]);

  const renderCavitiesInfo = (
    labelId: string,
    labelText: string,
    value: number,
    unit: string,
  ) => (
    <Flex>
      <Text>
        <FormattedMessage id={labelId} defaultMessage={labelText} />
        {value} {unit}
      </Text>
      {!partingPlanesMatch && (
        <Tooltip
          label={intl.formatMessage({
            id: "calculation.detail.coresWarning",
            defaultMessage:
              "This information is outdated or not defined, please run the core detection.",
          })}
        >
          <Flex alignItems="center" justifyContent="center" ml="2">
            <Icon as={MdWarning} color="yellow.300" boxSize="1em" />
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );

  return (
    <>
      {planeWithCores &&
        renderCavitiesInfo(
          "calculation.cavities",
          "Number of cavities: ",
          cavities?.numberOfCavities,
          "",
        )}
      {planeWithCores &&
        renderCavitiesInfo(
          "calculation.volume",
          "Total volume of cavities: ",
          cavities?.totalVolumeinLiters,
          "L",
        )}
    </>
  );
}

export default CavitiesData;
