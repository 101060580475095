import { Flex, Text, useColorModeValue, Tooltip, Icon } from "@chakra-ui/react";
import { MdWarning } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import Card from "components/atoms/Card";
import { calculationDetailLabels } from "variables/constants";
import { useIntl, FormattedMessage } from "react-intl";
import fetchInputParameters from "views/admin/calculations/calculationDetails/api/fetchInputParameters";
import fetchMlParameters from "views/admin/calculations/calculationDetails/api/fetchMlParameters";
import fetchFormFields from "views/admin/calculations/newCalculation/api/useFetchFormFields";
import { useEffect } from "react";
import getSimpleFieldsValuesById from "views/admin/calculations/api/useGetSimpleFieldsValue";
import CavitiesData from "components/molecule/CavitiesData";
import useFetchPartitionPlane from "../../views/admin/calculations/api/fetchPartitionPlane";
import { usePartingPlanesMatch } from "contexts/ModelHooks";
import { useAppSelector } from "store/hooks";
import { useFetchCurrentClient } from "views/admin/excelBaseline/api/useFetchClients";
import { UserBasedField } from "types/calculationTypes";

interface LabelObject {
  [key: string]: string;
}

interface CalculationField {
  value: string | boolean;
  label: string;
  translationId?: string;
}

interface CalculationDataTableProps {
  calculation: CalculationType;
}

export default function CalculationDataTable(props: CalculationDataTableProps) {
  const { calculation } = props;
  const userClientSite = useAppSelector((state) => state.auth.User.client);
  const { currentClient } = useFetchCurrentClient(userClientSite);
  const intl = useIntl();
  const textColor = useColorModeValue("navy.700", "white");
  const { data: formFields } = useQuery<UserBasedField[]>({
    queryKey: ["formFields"],
    queryFn: fetchFormFields,
  });
  const { data: fieldValues } = useQuery({
    queryKey: ["simpleFieldsValues", calculation.id],
    queryFn: () => getSimpleFieldsValuesById(calculation?.id),
  });
  const { data: inputParameters } = useQuery({
    queryKey: ["InputParameters", calculation?.id],
    queryFn: () => fetchInputParameters(calculation?.id),
  });

  const partingPlanesMatch = usePartingPlanesMatch();

  const { data: planeWithCores, refetch: refetchPlaneFieldValues } = useQuery({
    queryKey: ["partitionPlane", calculation.plane.id],
    queryFn: () => useFetchPartitionPlane(calculation.plane.id),
  });

  useEffect(() => {
    refetchPlaneFieldValues();
  }, [partingPlanesMatch, calculation]);

  const modelWarning =
    calculation.status === 6 &&
    calculation.model_version !== currentClient?.model_version;

  const formsWithValues = formFields.map((form: any) => {
    const value = fieldValues?.find(
      (fieldValue: any) => fieldValue.name === form.name,
    );
    const fieldWithValue = {
      ...form,
      fieldValueID: value?.id,
      value: value?.simple_user_field_value,
      translationId: value?.translationId, // TODO: This value should come from the middleware to display the correct translation
    };
    return fieldWithValue;
  });

  const { data: mlParameters } = useQuery({
    queryKey: ["MlParameters"],
    queryFn: fetchMlParameters,
    enabled: !!inputParameters,
  });

  const calculationFields = calculationDetailLabels?.map((option) => ({
    ...option,
    label: intl.formatMessage({ id: option.translationId }),
  }));

  const labelsById = calculationFields.reduce(
    (acc: LabelObject, option: CalculationField) => {
      const key = String(option.value);
      acc[key] = option.label;
      return acc;
    },
    {},
  );

  const mergedParameters = () => {
    return (
      inputParameters?.map((inputParameter) => {
        const mlParameter = mlParameters?.find(
          (mlParameterItem) =>
            mlParameterItem.id === inputParameter.ml_parameter,
        );

        return {
          ...inputParameter,
          ...mlParameter,
          inputParamId: inputParameter.id,
        };
      }) ?? []
    );
  };

  const stepModule = mergedParameters().find(
    (el) => el.name === "step_module",
  )?.ml_parameter_value;

  return (
    <Card fontWeight="normal" fontSize="md" color={textColor}>
      <Text
        fontSize={{ sm: "2xl", md: "3xl" }}
        fontWeight="bold"
        pb=".5rem"
        display="flex"
        justifyContent="space-between"
      >
        {labelsById.calculation}
        {calculation.built_calculation_id}
        {modelWarning && (
          <Tooltip
            label={intl.formatMessage({
              id: "user.dashboard.modelWarning",
              defaultMessage:
                "This calculation was run with an outdated AI model. You can rerun this calculation with the latest models.",
            })}
          >
            <Flex alignItems="center" justifyContent="center" ml="4">
              <Icon as={MdWarning} color="yellow.300" boxSize="1em" />
            </Flex>
          </Tooltip>
        )}
      </Text>
      {calculation.created_by_user && (
        <Text>
          {labelsById.createdBy}
          {calculation.created_by_user_name}
        </Text>
      )}
      {calculation.calculation_parent && (
        <Text>
          <FormattedMessage
            id="calculation.detail.parent"
            defaultMessage="Created from ID: "
          />
          {calculation.calculation_parent}
        </Text>
      )}
      {formsWithValues?.map((form, index) => (
        <Text key={index}>
          <>
            {form.value && `${form.display_name}: ${form.value}`}
            {form.name === "process_state" &&
              !form.value &&
              `${form.display_name}: Raw`}
          </>
        </Text>
      ))}
      {calculation.raw_weight && (
        <Text>
          {labelsById.weight}
          {calculation.raw_weight}
          kg
        </Text>
      )}

      <Text color={textColor}>
        {labelsById.module}
        {stepModule}
      </Text>

      <CavitiesData planeWithCores={planeWithCores} />

      {calculation.status_name && (
        <Text color={textColor} fontWeight="bold" mt="4">
          <FormattedMessage id="calculation.status" defaultMessage="Status: " />
          <FormattedMessage
            id={`calculation.detail.${calculation.status_name}`}
            defaultMessage={calculation.status_name}
          />
        </Text>
      )}
    </Card>
  );
}
