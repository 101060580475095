import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IMaterialType } from "types/calculationTypes";
import API_URLS from "utils/apiVersionConfig";

const fetchMaterial = async () => {
  const response = await axios.get(API_URLS.fetchMaterial());
  return response.data;
};

const useFetchMaterial = () => {
  return useQuery<IMaterialType[], Error>(["materials"], fetchMaterial);
};

export default useFetchMaterial;
