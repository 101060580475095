import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { CalculationList } from "models/calculation";
import API_URLS from "utils/apiVersionConfig";

interface CalculationState {
  calculations: CalculationList | null;
  loading: boolean;
  singleCalculation: CalculationType | null;
  errors: any;
  hasNext: boolean;
}

const initialState: CalculationState = {
  calculations: [],
  singleCalculation: null,
  loading: false,
  errors: null,
  hasNext: false,
};
interface CalculationResponse {
  results: CalculationList | null;
  next: string | null;
  previous: string | null;
  count: number;
}
interface CalculationInputParams {
  page?: number;
  status?: string;
}

// actions are processes that get data from backend
export const getCalculations = createAsyncThunk<
  CalculationResponse,
  CalculationInputParams
>("calculations/getCalculations", async (inputParams, thunkAPI) => {
  const { page, status } = inputParams;
  try {
    const response = await axios.get(API_URLS.getCalculations(page));
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
});

export const getCalculationById = createAsyncThunk<CalculationType, string>(
  "calculations/getCalculationById",
  async (id, thunkAPI) => {
    try {
      const response = await axios.post(API_URLS.getCalculation(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createCalculation = createAsyncThunk<CalculationType, Object>(
  "calculations/createCalculation",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(API_URLS.postCalculation(), data, {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
        },
      });
      thunkAPI.dispatch(getCalculations({ page: 1 }));

      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  },
);

export const updateCalculation = createAsyncThunk<
  CalculationType,
  Object | any
>("calculations/updateCalculation", async (data, thunkAPI) => {
  try {
    const response = await axios.put(API_URLS.putCalculation(data?.id), data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteCalculation = createAsyncThunk<string, string>(
  "calculations/deleteCalculation",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(API_URLS.deleteCalculation(id));
      thunkAPI.dispatch(getCalculations({ page: 1 }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const calculationSlice = createSlice({
  name: "calculations",
  initialState,
  reducers: {
    setCalculations: (state, action: PayloadAction<CalculationList>) => {
      state.calculations = action.payload;
    },
    setSingleCalculation: (state, action: PayloadAction<CalculationType>) => {
      state.singleCalculation = action.payload;
    },
    clearErrors: (state) => {
      state.errors = "";
    },
    setHasNext: (state, action: PayloadAction<boolean>) => {
      state.hasNext = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalculations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCalculations.fulfilled, (state, action) => {
      state.calculations.push(...action.payload.results);
      state.loading = false;
      state.hasNext = !!action.payload.next;
    });
    builder.addCase(getCalculations.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    });
    builder.addCase(getCalculationById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCalculationById.fulfilled, (state, action) => {
      state.singleCalculation = action.payload;
      state.loading = false;
    });
    builder.addCase(updateCalculation.fulfilled, (state, action) => {
      state.singleCalculation = action.payload;
    });
    builder.addCase(createCalculation.rejected, (state, action) => {
      state.errors = action.payload;
    });
  },
});

export default calculationSlice.reducer;
export const {
  setCalculations,
  clearErrors,
  setSingleCalculation,
  setHasNext,
} = calculationSlice.actions;
