import { IExcelFile, ProductionLine } from "types/calculationTypes";

const processExcelFiles = (
  excelFiles: IExcelFile[] | undefined,
  productionLines: ProductionLine[] | undefined,
  calculationresults: any[] | undefined,
  defaultPredictionResults: any[] | undefined,
  predictionMLParameters: any[] | undefined,
) => {
  const predictionResultsMap = new Map();
  defaultPredictionResults.forEach((predictionResult) => {
    predictionResultsMap.set(
      `${predictionResult.ml_parameter_name}/${predictionResult.production_line}`,
      predictionResult.ml_parameter_value,
    );
  });
  const productionLinesUnique = Array.from(
    new Set(excelFiles.map((file) => file.production_line)),
  ).length;
  // since with each change to input, the excelFiles array is updated, we need to splice the array to get the last n elements
  const splicedExcelFiles =
    productionLinesUnique === excelFiles.length
      ? excelFiles
      : excelFiles?.splice(-productionLinesUnique);

  return splicedExcelFiles?.map((file) => {
    const productionLine = productionLines?.find(
      (line) => line.id === file.production_line,
    );
    const productionLineResults = calculationresults?.filter(
      (result) => result.production_line === file.production_line,
    );

    const updatedProductionLineResults = productionLineResults.map((result) => {
      return {
        ...result,
        default_ml_parameter_value: predictionResultsMap.get(
          `${result.ml_parameter_name}/${result.production_line}`,
        ),
      };
    });
    const productionLineResultsWithDisplayName =
      updatedProductionLineResults.map((result) => {
        const predictionMLParameter = predictionMLParameters?.find(
          (parameter) => parameter.name === result.ml_parameter_name,
        );
        return {
          ...result,
          ml_parameter_display_name: predictionMLParameter?.display_name,
        };
      });

    return {
      ...file,
      production_line_name: productionLine?.name,
      production_line_results: productionLineResultsWithDisplayName,
    };
  });
};

export default processExcelFiles;
