import { Box, Spinner } from "@chakra-ui/react";

function CenteredSpinnerRound() {
  return (
    <Box display="flex" justifyItems="center" alignItems="center" h="100vh">
      <Spinner m="auto" color="brand.500" size="xl" />
    </Box>
  );
}

export default CenteredSpinnerRound;
