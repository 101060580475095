import { toast } from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { resetPasswordInstance } from "services/axios.service";
import { ChangePasswordMutationArgs } from "types/settingsTypes";

const useChangePasswordMutation = () => {
  const changePasswordMutation = async ({
    userId,
    data,
  }: ChangePasswordMutationArgs) => {
    const response = await resetPasswordInstance.put(
      `change_password/${userId}/`,
      data,
    );
    return response.data;
  };

  return useMutation(changePasswordMutation, {
    onSuccess: () => {
      toast.success("Password changed successfully");
    },
    onError(err: any) {
      const errorMessages: [string, unknown][] = Object.entries(
        err.response.data.details,
      ).map(([key, value]): [string, unknown] => {
        return [key, value];
      });

      const formattedErrorMessages = errorMessages.map(([key, value]) => {
        return `${
          key.charAt(0).toUpperCase() +
          key.slice(1).split(":")[0].replace(/_/g, " ")
        }: ${
          value && typeof value === "object"
            ? (value as Record<string, string>)[key] || value
            : value
        }`;
      });
      toast.error(formattedErrorMessages.join("\n"));
    },
  });
};

export default useChangePasswordMutation;
