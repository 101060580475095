import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import { SubmitHandler } from "react-hook-form";
import { Spinner, Text } from "@chakra-ui/react";
import { FileInput } from "../../CalculationSchema";
import usePostNewCalculation from "./usePostNewCalculation";
import API_URLS from "utils/apiVersionConfig";
import { useIntl } from "react-intl";

const postCadFile = async (data: any) => {
  const response = await axios.post(API_URLS.postCADFile(), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const usePostCadFileMutation = (
  userId: number,
  userClient: number, // Adjust the type of this parameter as per your need
  setCadFileError: (error: any) => void, // Adjust the type of this parameter as per your need
  setCreatingCalc: (arg0: boolean) => void,
) => {
  const calcMutation = usePostNewCalculation(setCadFileError, setCreatingCalc);
  const intl = useIntl();

  const mutation = useMutation(postCadFile, {
    onMutate: () => {
      setCreatingCalc(true);
    },
    onSuccess: (data) => {
      setCreatingCalc(false);
      calcMutation.mutate({
        created_by_user: Number(userId),
        ml_parameter_pipeline: 1,
        cad_file: data.id,
      });
    },
    onError: (error: any) => {
      setCreatingCalc(false);
      if (error.response.status === 400) {
        setCadFileError(error.response.data);
      }
    },
  });

  const onSubmit: SubmitHandler<FileInput> = async (values) => {
    const data = {
      created_by_user: Number(userId),
      file: values.cad_file[0],
      client: userClient,
    };
    mutation.mutate(data);
  };

  return { mutation, onSubmit };
};

export default usePostCadFileMutation;
