import { Box3 } from "three";

interface LightsProps {
  distance: number;
  bbox: Box3;
}

const Lights = ({ distance, bbox }: LightsProps) => {
  const { min, max } = bbox;
  const center = min.clone().add(max).multiplyScalar(0.5);

  const topLight: [number, number, number] = [
    center.x,
    center.y,
    center.z + distance,
  ];
  const bottomLight: [number, number, number] = [
    center.x,
    center.y,
    center.z - distance,
  ];

  return (
    <>
      <ambientLight />
      <spotLight castShadow intensity={1} position={topLight} />
      <spotLight intensity={0.8} position={bottomLight} />
    </>
  );
};

export default Lights;
