import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Card from "components/atoms/Card";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CadFileViewer from "components/organisms/CadFileViewer";
import CalculationDataTable from "components/organisms/CalculationDataTable";
import EditCalculations from "./components/EditCalculations";
import fetchInputParameters from "./api/fetchInputParameters";
import fetchMlParameters from "./api/fetchMlParameters";
import InputParametersDetails from "./components/InputParameters";
import ProductionLines from "./components/ProductionLines";
import useChangeInputParamMutationMutation from "./api/changeInputParamMutation";
import useGetClientGroup from "./api/fetchClientGroup";
import useGetFilteredProductionLines from "./api/useGetFilteredProductions";
import useFetchMaterial from "../calculationView/api/fetchMaterials";
import fetchFormFields from "../newCalculation/api/useFetchFormFields";
import getSimpleFieldsValuesById from "views/admin/calculations/api/useGetSimpleFieldsValue";
import CalcActionBtn from "components/molecule/CalcActionBtn";
import { usePartingPlanesMatch } from "contexts/ModelHooks";
import { useIntl } from "react-intl";
import { UserBasedField } from "types/calculationTypes";
import CopyCalculationBtn from "components/molecule/CopyCalculationBtn";
import fetchCalculationById from "../calculationView/api/fetchCalculationById";
import CenteredSpinnerRound from "components/atoms/CenteredSpinnerRound";

interface ICalculationDetailsParams {
  calculationId: any;
}

export interface LocationState {
  calculation: CalculationType;
}

function ProductionLinesFetch(props: any) {
  const { filteredMaterialId, setProduction, production } = props;
  const { clientFactoriesWithProductions } = useGetFilteredProductionLines(
    filteredMaterialId,
    setProduction,
  );

  return (
    <div>
      {clientFactoriesWithProductions &&
        clientFactoriesWithProductions?.map((site: any, index: any) => (
          <Stack key={index}>
            <Text fontWeight="bold" fontSize={{ sm: "lg", md: "xl" }} ml="5">
              {site?.productionLines?.length > 0 && site.company_name}
            </Text>

            <Box>
              <ProductionLines
                production={production}
                setProduction={setProduction}
                productionLines={site?.productionLines}
              />
            </Box>
          </Stack>
        ))}
    </div>
  );
}

function CalculationDetailsView() {
  const textColor = useColorModeValue("navy.700", "white");
  const { data: clientGroup } = useGetClientGroup();
  const [production, setProduction] = useState([]);
  const location = useLocation();
  const { calculationId } = useParams<ICalculationDetailsParams>();
  const partingPlanesMatch = usePartingPlanesMatch();
  const history = useHistory();

  // Define calculation from local state if it's defined or obtain it from the API
  const { data: calculation, isError: calculationByIdError } = useQuery(
    ["calculatioById", calculationId],
    async () => {
      if (calculation && calculation.location?.state) {
        const { calculation } = location.state as LocationState;
        return calculation;
      }
      const response = await fetchCalculationById(calculationId);
      return response;
    },
  );

  useEffect(() => {
    if (calculationByIdError) {
      history.replace("/user/404", {
        text: "Calculation detail not found",
        textId: "user.calcDetail.idNotFound",
        button: "Return to dashboard",
        buttonId: "user.dashboard.redirect",
        path: "/user/dashboard",
      });
    }
  }, [calculationByIdError]);

  const { data: formFields, refetch: refetchFormFields } = useQuery<
    UserBasedField[]
  >({
    queryKey: ["formFields"],
    queryFn: () => fetchFormFields(),
  });

  const { data: fieldValues, refetch: refetchFieldValues } = useQuery({
    queryKey: ["simpleFieldsValues", calculation?.id],
    queryFn: () => getSimpleFieldsValuesById(calculation?.id),
  });
  const { data: materials } = useFetchMaterial();
  const intl = useIntl();
  const currentLocale = intl.locale;

  useEffect(() => {
    refetchFormFields();
    refetchFieldValues();
  }, [currentLocale]);

  // Find the material name from fieldValues instead from singleCalculation to make the ProductionLines fetch faster
  const materialName = fieldValues?.find(
    (field: any) => field.name === "MaterialExact",
  );

  const formsWithValues = formFields?.map((form: any) => {
    const value = fieldValues?.find(
      (fieldValue: any) => fieldValue.name === form.name,
    );
    const fieldWithValue = {
      ...form,
      fieldValueID: value?.id,
      value: value?.simple_user_field_value,
      translationId: value?.translationId, // TODO: This value should come from the middleware to display the correct translation
    };
    return fieldWithValue;
  });

  const simpleFieldObject = formsWithValues?.reduce((acc: any, obj: any) => {
    acc[obj.display_name] = obj.value;
    return acc;
  }, {});

  const singleCalculation = {
    ...calculation,
    ...simpleFieldObject,
  };

  const filteredMaterial = materials?.find(
    (material) => material.name === materialName?.simple_user_field_value,
  );

  const {
    data: inputParameters,
    isError: inputParametersError,
    refetch: refetchInputParams,
  } = useQuery(["InputParameters", calculationId], () =>
    fetchInputParameters(Number(calculationId)),
  );
  const {
    data: mlParameters,
    isError: mlParametersIsError,
    refetch: refectMLParams,
  } = useQuery({
    queryKey: ["MlParameters"],
    queryFn: fetchMlParameters,
    enabled: !!inputParameters,
  });
  const { isLoading: predictedWeightBeingChanged } =
    useChangeInputParamMutationMutation(refetchInputParams, refectMLParams);

  const isError = inputParametersError || mlParametersIsError;

  const mergedParameters = () => {
    return (
      inputParameters?.map((inputParameter) => {
        const mlParameter = mlParameters?.find(
          (mlParameterItem) =>
            mlParameterItem.id === inputParameter.ml_parameter,
        );

        return {
          ...inputParameter,
          ...mlParameter,
          inputParamId: inputParameter.id,
        };
      }) ?? []
    );
  };

  return (
    <Card w="full" p={0}>
      <SimpleGrid mt="10rem">
        {calculation ? (
          <>
            <GridItem colSpan={{ sm: 2, md: 1 }}>
              <CadFileViewer calculation={calculation} />
            </GridItem>
            <GridItem colSpan={{ sm: 2, md: 1 }}>
              <Flex flexDirection="column" h="100%" lineHeight="1.6">
                <CalculationDataTable calculation={calculation} />
                <Card>
                  <Text
                    fontSize={{ sm: "2xl", md: "3xl" }}
                    color={textColor}
                    fontWeight="bold"
                    pb=".5rem"
                  >
                    {clientGroup?.name}
                  </Text>
                  {filteredMaterial && (
                    <ProductionLinesFetch
                      setProduction={setProduction}
                      production={production}
                      filteredMaterialId={filteredMaterial.id}
                    />
                  )}
                  <Box display="flex" justifyContent="end">
                    {formsWithValues && (
                      <CopyCalculationBtn
                        calculationId={singleCalculation.id}
                        formsWithValues={formsWithValues}
                        simpleFieldObject={simpleFieldObject}
                      />
                    )}
                    {partingPlanesMatch !== null && (
                      <CalcActionBtn
                        status={singleCalculation.status_name}
                        calculationId={singleCalculation.id}
                        production={production}
                        predictedWeightBeingChanged={
                          predictedWeightBeingChanged
                        }
                        planeCalculationId={singleCalculation.plane.calculation}
                      />
                    )}
                  </Box>
                </Card>
              </Flex>
            </GridItem>
            {singleCalculation?.status_name === "draft" && (
              <GridItem colSpan={2} overflowX="auto">
                <EditCalculations
                  formsWithValues={formsWithValues}
                  singleCalculation={singleCalculation}
                />
              </GridItem>
            )}
            <GridItem colSpan={2} overflowX="auto" my={20}>
              <InputParametersDetails
                mergedParameters={mergedParameters}
                isError={isError}
              />
            </GridItem>
          </>
        ) : (
          <CenteredSpinnerRound />
        )}
      </SimpleGrid>
    </Card>
  );
}

export default CalculationDetailsView;
