import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const fetchPredictionResults = async (calculationId: string) => {
  try {
    const response = await axios.get(
      API_URLS.fetchPredictionResults(calculationId),
    );

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export default fetchPredictionResults;
