// Chakra Imports
import {
  Flex,
  Icon,
  Text,
  MenuButton,
  Box,
  Menu,
  MenuList,
  MenuItem,
  useColorMode,
} from "@chakra-ui/react";
import { useContext } from "react";
// Custom Icons
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import { LanguageContext } from "contexts/LanguageProvider";
import { MdLanguage } from "react-icons/md";
import { langugageInput } from "types/calculationTypes";

export default function FixedPlugin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <Box
      bg={colorMode === "light" ? "brand.500" : "gray.700"}
      borderRadius="20px"
      p="2"
      position="fixed"
      bottom="30px"
      right="35px"
      zIndex="3"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Flex justifyContent="center" alignItems="center">
        <Menu>
          <MenuButton p="2" {...rest} onClick={toggleColorMode}>
            <Icon
              color="white"
              as={colorMode === "light" ? IoMdMoon : IoMdSunny}
              h="25px"
              w="25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            />
          </MenuButton>
        </Menu>
        <Menu>
          <MenuButton p="2">
            <Flex alignItems="center" gap={2}>
              <MdLanguage color="white" />
              <Text color="white">{language.toUpperCase()}</Text>
            </Flex>
          </MenuButton>
          <MenuList p="4" borderRadius="20px" border="none" mt="2">
            {langugageInput.map((lang) => (
              <MenuItem
                key={lang.language}
                onClick={() => setLanguage(lang.language)}
              >
                {lang.symbol} {lang.language.toUpperCase()}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
}
