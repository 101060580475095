import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { ICustomer } from "models/customer";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useAppSelector } from "store/hooks";
import API_URLS from "utils/apiVersionConfig";

interface EditCustomerProps {
  id: number;
  refetchCustomers: () => void;
  onClose: () => void;
}

const editCustomer = async ({ data, id }: any) => {
  const response = await axios.put(API_URLS.editCustomer(id), data);
  return response.data;
};

const useEditCustomer = ({
  onClose,
  refetchCustomers,
  id,
}: EditCustomerProps) => {
  const editCustomerMutation = useMutation(editCustomer, {
    onSuccess: () => {
      toast.success("Customer deleted successfully");
      refetchCustomers();
      onClose();
    },
    onError() {
      toast.error("Something went wrong while adding customer.");
    },
  });
  const clientGroup = useAppSelector((state) => state.auth.User.client_group);
  const submitHandler: SubmitHandler<ICustomer> = async (values) => {
    const data = {
      client_group: clientGroup,
      name: values.name,
    };
    editCustomerMutation.mutateAsync({ data, id });
  };
  return submitHandler;
};

export default useEditCustomer;
