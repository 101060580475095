import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  SimpleGrid,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/atoms/Card";
import CustomModal from "components/molecule/modal/CustomModal";
import CenteredSpinnerRound from "components/atoms/CenteredSpinnerRound";
import { IPartNames } from "types/calculationTypes";
import SearchBar from "components/molecule/searchBar/SearchBar";
import { useDeletePartnameMutation } from "./api/api";
import AddPartname from "./components/AddPartname";
import PartnameItem from "./components/PartnameItem";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import fetchPartNames from "../calculations/calculationView/api/fetchPartnames";

export default function PartnameView() {
  const {
    isOpen: isAddPartnameOpen,
    onClose: onClosePartnameAdd,
    onOpen: onOpenPartnameAdd,
  } = useDisclosure();
  const {
    isOpen: isEditPartnameOpen,
    onOpen: onEditPartnameOpen,
    onClose: onEditPartnameClose,
  } = useDisclosure();
  const intl = useIntl();
  const currentLocale = intl.locale;

  const finalRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPartname, setSelectedPartname] = useState<
    IPartNames | undefined
  >();

  const {
    data: partnames,
    isLoading: loadingPartnames,
    refetch: refetchPartnames,
  } = useQuery<IPartNames[], Error>(["partnames"], () => fetchPartNames());

  useEffect(() => {
    refetchPartnames();
  }, [currentLocale]);

  const partnamesBg = useColorModeValue("white", "gray.600");

  const { mutate: deletionMutate } = useDeletePartnameMutation();
  const deletePartname = (id: number) => {
    deletionMutate(id);
  };

  const editPartname = (partId: number) => {
    onEditPartnameOpen();
    const partnameMatch = partnames.find((partname) => partname.id === partId);
    setSelectedPartname(partnameMatch);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };
  const filteredPartnames = partnames
    ? partnames
        .filter((item) =>
          item.display_name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
        .sort((a, b) => a.display_name.localeCompare(b.display_name))
    : [];

  if (loadingPartnames) return <CenteredSpinnerRound />;

  return (
    <Box ref={finalRef} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card bg={partnamesBg}>
        <Box display="flex">
          <SearchBar onChange={handleSearchChange} />
          <Button
            ml={4}
            w="fit-content"
            colorScheme="brand"
            placeSelf="end"
            mb="10"
            onClick={onOpenPartnameAdd}
          >
            <FormattedMessage id="calculation.data.add" defaultMessage="Add" />
          </Button>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="10">
          {filteredPartnames?.map((partname) => (
            <PartnameItem
              key={partname.id}
              id={partname.id}
              name={partname.display_name}
              description={partname.description}
              deletePartname={deletePartname}
              editPartname={editPartname}
            />
          ))}
        </SimpleGrid>
      </Card>

      <CustomModal
        finalFocusRef={finalRef}
        isOpen={isAddPartnameOpen || isEditPartnameOpen}
        onClose={isAddPartnameOpen ? onClosePartnameAdd : onEditPartnameClose}
        size="6xl"
        closeOnOverlayClick={false}
        title={intl.formatMessage({
          id: isAddPartnameOpen
            ? "calculation.data.addPartname"
            : "calculation.data.editPartname",
          defaultMessage: isAddPartnameOpen ? "Add Partname" : "Edit Partname",
        })}
      >
        <AddPartname
          editForm={isEditPartnameOpen}
          onClose={isAddPartnameOpen ? onClosePartnameAdd : onEditPartnameClose}
          selectedPartname={selectedPartname}
        />
      </CustomModal>
    </Box>
  );
}
