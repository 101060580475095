import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import CenteredSpinnerRound from "components/atoms/CenteredSpinnerRound";
import Card from "components/atoms/Card";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { Prompt, useLocation, useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import useFetchClients from "../excelBaseline/api/useFetchClients";

import API_URLS from "utils/apiVersionConfig";
import useFetchExcelData, { ExcelData } from "./api/useFetchExcelData";
import TableInputCellSystemVariable from "./components/TableInputCellSystemVariable";
import FinalCostSection from "./components/FinalCostSection";

type IExcelBaselineParams = {
  excelId: string;
};

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (
      rowIndex: number,
      columnId: string,
      value: unknown,
      setValue: any,
    ) => void;
  }
}

interface ExcelDataNavigationState {
  excelId: number;
}

function ConfigExcelView() {
  const [data, setData] = useState<ExcelData[]>([]);
  const location = useLocation<ExcelDataNavigationState>();
  const [existingFinalCost, setExistingFinalCost] =
    useState<ExcelData | null>();
  const history = useHistory();
  const { excelId } = useParams<IExcelBaselineParams>();

  const clientId = useAppSelector((state) => state.auth.User.client);
  const { isLoading: excelDataIsLoading, isError: excelDataError } =
    useFetchExcelData(Number(excelId), setData, setExistingFinalCost);
  const intl = useIntl();

  useEffect(() => {
    if (excelDataError) {
      history.push("/user/404", {
        text: "Excel Baseline not Found",
        textId: "user.excelBaseline.idNotFound",
        button: "Return to baseline's list",
        buttonId: "user.excelBaseline.redirect",
        path: "/user/excel-baseline",
      });
    }
  }, [excelDataError]);

  const headerStyles = {
    color: useColorModeValue("gray.500", "white"),
    fontSize: "md",
    fontWeight: "700",
  };

  const cellStyles = {
    borderColor: useColorModeValue("gray.200", "gray.500"),
    color: useColorModeValue("gray.700", "white"),
    fontSize: "sm",
  };
  const { clients } = useFetchClients();

  const columnHelper = createColumnHelper<ExcelData>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        header: () => <Text {...headerStyles}>SN</Text>,
        cell: (info: any) => <Text {...cellStyles}>{info.row.index + 1}</Text>,
        enableSorting: true,
      }),
      columnHelper.accessor("placeholder_name", {
        id: "placeholder_name",
        header: () => (
          <Text {...headerStyles}>
            <FormattedMessage
              id="user.excelBaseline.placeholdersFound"
              defaultMessage="Placeholders found"
            />
          </Text>
        ),
        cell: (info: any) => <Text {...cellStyles}>{info.getValue()}</Text>,
        enableSorting: false,
      }),
      columnHelper.accessor("system_variable", {
        id: "system_variable",
        header: () => (
          <Text {...headerStyles}>
            <FormattedMessage
              id="user.excelBaseline.systemVariable"
              defaultMessage="System Variable"
            />
          </Text>
        ),
        cell: TableInputCellSystemVariable,
        enableSorting: false,
      }),
      columnHelper.accessor("sheet_name", {
        id: "sheet_name",
        header: () => (
          <Text {...headerStyles}>
            <FormattedMessage
              id="user.excelBaseline.sheetName"
              defaultMessage="Sheet Name"
            />
          </Text>
        ),
        cell: (info: any) => <Text {...cellStyles}>{info.getValue()}</Text>,
        enableSorting: false,
      }),
      columnHelper.accessor("cell_position", {
        id: "cell_position",
        header: () => (
          <Text {...headerStyles}>
            <FormattedMessage
              id="user.excelBaseline.cellPosition"
              defaultMessage="Cell Position"
            />
          </Text>
        ),
        cell: (info: any) => <Text {...cellStyles}>{info.getValue()}</Text>,
        enableSorting: false,
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: async (rowIndex, columnId, value, setValue) => {
        const isValueExists = data.some(
          (row, index) => index !== rowIndex && row[columnId] === value,
        );
        if (isValueExists && columnId === "system_variable" && value !== "") {
          toast.error("Duplicate variable already exists");
        } else {
          setValue(value); // update table UI

          // send updated field to the middleware to be stored in DB
          await axios.patch(
            API_URLS.patchExcelFilePlaceholders(data[rowIndex].id),
            {
              [columnId]: value,
            },
          );

          // this is only for setting the "complete" indicator. should be simpler...
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex]!,
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
        }
      },
    },
  });

  const isTableCompleted = useMemo(() => {
    return data.every((row) => row.system_variable);
  }, [data]);

  const isFinalCostCompleted =
    existingFinalCost?.sheet_name.length > 0 &&
    existingFinalCost?.cell_position.length > 0;

  const isStatusCompleted = isTableCompleted && isFinalCostCompleted;

  const userClient = clients?.find((client) => client.id === clientId);

  if (excelDataIsLoading) return <CenteredSpinnerRound />;

  return (
    <Card pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!excelDataError && (
        <Prompt
          when={!isStatusCompleted}
          message={intl.formatMessage({
            id: "global.warning.missingEntries",
            defaultMessage:
              "You have unfilled enteries. Are you sure you want to leave?",
          })}
        />
      )}
      <Flex justifyContent="space-between" alignItems="center" mb="20px">
        <Box>
          <Text fontSize="2xl" fontWeight="700">
            <FormattedMessage
              id="user.excelBaseline.configureExcel"
              defaultMessage="Configure Excel"
            />
          </Text>
          <Text fontSize="sm" fontWeight="500">
            <FormattedMessage
              id="user.excelBaseline.mandatoryFieldsNotice"
              defaultMessage="* All fields are mandatory for functionality to work."
            />
          </Text>
        </Box>
        <Box>
          <Text fontSize="sm">
            <FormattedMessage
              id="user.excelBaseline.status"
              defaultMessage="Status"
            />
          </Text>
          <Text fontSize="sm" fontWeight="700">
            <FormattedMessage
              id="user.excelBaseline.client"
              defaultMessage="Client"
            />
            : {userClient?.company_name}
          </Text>
          <Text
            fontSize="sm"
            fontWeight="700"
            color={isStatusCompleted ? "green.500" : "red.500"}
          >
            {isStatusCompleted ? (
              <FormattedMessage
                id="user.excelBaseline.completed"
                defaultMessage="Completed"
              />
            ) : (
              <FormattedMessage
                id="user.excelBaseline.incomplete"
                defaultMessage="Incomplete"
              />
            )}
          </Text>
        </Box>
      </Flex>

      <Table variant="simple">
        <Thead>
          {table?.getHeaderGroups()?.map((headerGroup, index) => (
            <Tr key={index}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <Th
                    key={index}
                    colSpan={header.colSpan}
                    pe="10px"
                    {...cellStyles}
                    cursor="pointer"
                  >
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody rowGap={3}>
          {table?.getRowModel()?.rows?.length > 0 ? (
            <>
              {table.getRowModel().rows.map((row, index) => {
                return (
                  <Tr
                    key={index}
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <Td
                          {...cellStyles}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan={7} textAlign="center" {...cellStyles}>
                <FormattedMessage
                  id="user.excelBaseline.noBaselineDataFound"
                  defaultMessage="No Baseline Data found"
                />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {data.length > 0 && (
        <FinalCostSection
          // setExistingFinalCost={setExistingFinalCost}
          excelId={Number(excelId)}
          existingFinalCost={existingFinalCost}
        />
      )}
    </Card>
  );
}

export default ConfigExcelView;
