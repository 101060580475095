import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface CalculationResultsState {
  calculationresults: CalculationResultsType[];
  loading: boolean;
  errors: any;
}

const initialState: CalculationResultsState = {
  calculationresults: [],
  loading: false,
  errors: null,
};

export const getResultsByCalculationId = createAsyncThunk<
  CalculationResultsType[],
  Object
>("calculations/getCalculationResultsById", async (id, thunkAPI) => {
  try {
    const response = await axios.get(`/predictionresult/?calculation=${id}`);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

// reducers -> reduce to a specific state -> changes state
export const calculationResultsSlice = createSlice({
  name: "calculationresults",
  initialState,
  reducers: {
    setCalculationResults: (
      state,
      action: PayloadAction<CalculationResultsType[]>,
    ) => {
      return { ...state, calculationresults: action.payload };
    },
    resetResults: (state) => {
      state.calculationresults = null;
      state.errors = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResultsByCalculationId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getResultsByCalculationId.fulfilled, (state, action) => {
      state.loading = false;
      state.calculationresults = action.payload;
    });
  },
});

export default calculationResultsSlice.reducer;
export const { setCalculationResults, resetResults } =
  calculationResultsSlice.actions;
