import { Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useVerifyQRCodeMutation } from "../api/QRCodeMutations";
import OTPInputComponent from "./OTPInputComponent";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';

type Props = {
  otpUrl: string;
  User: any;
  onClose: () => void;
  queryClient: ReturnType<typeof useQueryClient>;
};

function OTPView({ otpUrl, onClose, User, queryClient }: Props) {
  const [otp, setOtp] = useState("");
  const mutation = useVerifyQRCodeMutation();
  const intl = useIntl();

  const onVerify = (data: any) => {
    mutation
      .mutateAsync({
        user_id: User?.id,
        token: String(data.token),
      })
      .then(() => {
        toast.success(        
          intl.formatMessage({
          id: 'global.toasts.twoFAEnabled',
          defaultMessage: '2FA enabled.',
        }))
        queryClient.invalidateQueries(["user"]);
        onClose();
      })
      .catch(() => {
        toast.error(        
          intl.formatMessage({
          id: 'global.toasts.invalidOTP',
          defaultMessage: 'Invalid OTP or OTP expired.',
        }))
      });
  };

  return (
    <Flex align="center" justify="center" mb="10" direction="column" gap="10">
      <QRCodeSVG value={otpUrl} size={256} />

      <Text textAlign="center">
        <FormattedMessage 
          id="global.settings.scanQRCode"
          defaultMessage="Scan the above code with your favorite authenticator app to setup 2FA.
          Enter the generetated code below to verify."
        />
      </Text>
      <OTPInputComponent
        value={otp}
        onChange={(val) => {
          setOtp(val);
        }}
      />

      {otp.length === 6 && (
        <Button
          onClick={() => {
            onVerify({ token: otp });
          }}
          colorScheme="blue"
          size="lg"
          rightIcon={mutation.isLoading && <Spinner />}
        >
          <FormattedMessage 
            id="global.settings.verify"
            defaultMessage="Verify"
          />
        </Button>
      )}
    </Flex>
  );
}

export default OTPView;
