import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

interface PartitionPlaneData {
  planeId: number;
  calculationId: number;
  axis: string;
  position: number;
}

const putPartitionPlane = async ({
  planeId,
  ...restData
}: PartitionPlaneData) => {
  const response = await axios.put(
    API_URLS.putCalculationPartingPlane(planeId),
    {
      axis: restData.axis,
      position: restData.position,
    },
  );

  return response;
};

const usePartitionPlane = () => {
  const editPartitionPlane = useMutation(putPartitionPlane);

  return {
    editPartitionPlane,
  };
};

export default usePartitionPlane;
