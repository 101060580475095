import { Icon } from "@chakra-ui/react";
import { MdEngineering, MdCalculate } from "react-icons/md";

// Admin Imports
// Auth Imports
import CalculationDetailsView from "views/admin/calculations/calculationDetails/CalculationDetailView";
import CadFileUploadView from "views/admin/calculations/newCalculation/CadFileUploadView";
import CalculationResultsView from "views/admin/calculations/calculationResults/CalculationResultsView";
import SettingsView from "views/admin/settings/SettingsView";
import NewCalculationView from "views/admin/calculations/newCalculation/NewCalculationView";
import { FaFileExcel, FaHandshake } from "react-icons/fa";
import PartnameView from "views/admin/partnames/PartnamesView";
import CustomerView from "views/admin/customers/CustomerView";
import ExcelBaselineView from "views/admin/excelBaseline/ExcelBaselineView";
import ConfigExcelView from "views/admin/excelProcessing/ConfigExcelView";
import { getCookie } from "utils/CookieFunctions";
import { Roles, UserAuth } from "views/auth/signIn/signInSlice";
import CalculationView from "./views/admin/calculations/calculationView/CalculationView";
import NotFoundView from "views/admin/notFound/NotFoundView";

const adminRoutes = [
  {
    name: "Partnames",
    layout: "/user",
    path: "/partnames",
    icon: <Icon as={FaHandshake} width="20px" height="20px" color="inherit" />,
    component: PartnameView,
    secondary: true,
  },
  {
    name: "Customers",
    layout: "/user",
    path: "/customers",
    icon: <Icon as={FaHandshake} width="20px" height="20px" color="inherit" />,
    component: CustomerView,
    secondary: true,
  },
  {
    name: "Excel Baseline",
    layout: "/user",
    path: "/excel-baseline",
    icon: <Icon as={FaFileExcel} width="20px" height="20px" color="inherit" />,
    component: ExcelBaselineView,
    secondary: true,
  },
  {
    name: "Config Excel Baseline",
    layout: "/user",
    path: "/config-excel-baseline/:excelId",
    icon: <Icon as={FaFileExcel} width="20px" height="20px" color="inherit" />,
    component: ConfigExcelView,
    hideInSidebar: true,
  },
];

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/user",
    path: "/dashboard",
    icon: <Icon as={MdCalculate} width="20px" height="20px" color="inherit" />,
    component: CalculationView,
  },
  {
    name: "Calculation",
    layout: "/user",
    path: "/cadfile",
    icon: (
      <Icon as={MdEngineering} width="20px" height="20px" color="inherit" />
    ),
    component: CadFileUploadView,
    hideInSidebar: false,
  },
  {
    name: "Calculation Inputs",
    layout: "/user",
    path: "/newCalculation",
    component: NewCalculationView,
    hideInSidebar: true,
  },
  {
    name: "Detail",
    layout: "/user",
    path: "/details/:calculationId",
    component: CalculationDetailsView,
    hideInSidebar: true,
  },
  {
    name: "Result",
    layout: "/user",
    path: "/result/:calculationId",
    component: CalculationResultsView,
    hideInSidebar: true,
  },
  {
    name: "Settings",
    layout: "/user",
    path: "/settings",
    component: SettingsView,
    hideInSidebar: true,
  },
  {
    name: "Not Found",
    layout: "/user",
    path: "/404",
    component: NotFoundView,
    hideInSidebar: true,
  },
];

const useRoutes = () => {
  if (getCookie<UserAuth>("User")?.role === Roles.CalculatingUser) {
    return [...routes, ...adminRoutes];
  }
  return routes;
};

export default useRoutes;
