import { createContext, FC, PropsWithChildren, useState } from "react";

const ModelContext = createContext({} as ModelContextType);

const ModelProvider: FC<PropsWithChildren> = (props) => {
  const [cameraInitialPosition, setCameraInitialPosition] =
    useState<CameraPositionType>({
      latitude: Math.PI / 8,
      longitude: -Math.PI / 8,
      distance: 200,
    });
  const [partingPlanesMatch, setPartingPlanesMatch] = useState<boolean | null>(
    null,
  );

  const actions = {
    setCameraInitialPosition,
    setPartingPlanesMatch,
  };

  const state = {
    cameraInitialPosition,
    partingPlanesMatch,
  };

  return (
    <ModelContext.Provider value={{ state, actions }}>
      {props.children}
    </ModelContext.Provider>
  );
};

export { ModelContext as default, ModelProvider as Provider };
