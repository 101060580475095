import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import Card from "components/atoms/Card";
import { useAppSelector } from "store/hooks";
import CenteredSpinnerRound from "components/atoms/CenteredSpinnerRound";
import { FormattedMessage } from "react-intl";
import useGetExcelBaseline from "../calculations/calculationDetails/api/useGetExcelBaseline";
import BaselineList from "./components/BaselineList";
import AddBaselineModal from "components/molecule/AddBaselineModal";
import useFetchClients from "./api/useFetchClients";

function ExcelBaselineView() {
  const {
    data: baselineExcel,
    isLoading: isBaselineLoading,
    refetch: refetchExcelBaselines,
  } = useGetExcelBaseline();
  const { clients, isLoading } = useFetchClients();
  const user = useAppSelector((state) => state.auth.User);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const baselineWithClient = baselineExcel?.map((baseline) => ({
    ...baseline,
    client: clients?.find((client) => client.id === baseline.client)
      ?.company_name,
  }));

  const currentClientName = clients?.find(
    (client) => client.id === user?.client,
  )?.company_name;
  if (isBaselineLoading) {
    return <CenteredSpinnerRound />;
  }
  return (
    <Card h="100vh">
      <AddBaselineModal
        user={user}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
      />
      <Flex mt="20" flexDir="column">
        <Button
          mt="20x"
          w="fit-content"
          alignSelf="flex-end"
          colorScheme="blue"
          onClick={onOpen}
        >
          <FormattedMessage
            id="user.excelBaseline.add"
            defaultMessage="Add New"
          />
        </Button>
        {baselineWithClient?.length === 0 && (
          <Text mt="10" color="gray.500" textAlign="center" fontSize="md">
            <FormattedMessage
              id="user.excelBaseline.notFound"
              defaultMessage="No baselines found"
            />
          </Text>
        )}
        {baselineWithClient?.map((baseline) => (
          <BaselineList
            key={baseline.id}
            baseline={baseline}
            refetchExcelBaselines={refetchExcelBaselines}
            currentClientName={currentClientName}
          />
        ))}
      </Flex>
    </Card>
  );
}

export default ExcelBaselineView;
