import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "components/wrappers/ProtectedRoute";
import { Roles, setUserAuth, UserAuth } from "views/auth/signIn/signInSlice";
import { useAppDispatch } from "store/hooks";
import { useEffect, useState } from "react";
import { getCookie } from "utils/CookieFunctions";
import CenteredSpinnerRound from "components/atoms/CenteredSpinnerRound";
import AuthLayout from "./layouts/auth";
import UserLayout from "./layouts/user";
import { useIntl } from "react-intl";
import axiosInterceptor from "services/axios.service";
import { Provider } from "contexts/ModelProvider";

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const currentLocale = intl.locale;

  useEffect(() => {
    const fetchUser = async () => {
      const userData = getCookie<UserAuth>("User");
      if (userData) {
        dispatch(setUserAuth(userData));
        axiosInterceptor(currentLocale);
      }
      setLoading(false);
    };

    fetchUser();
  }, [dispatch, currentLocale]);

  if (loading) {
    return <CenteredSpinnerRound />;
  }

  return (
    <Provider>
      <Switch>
        <Route path="/auth/*" component={AuthLayout} />
        <ProtectedRoute
          accessRole={Roles.SalesPerson}
          path="/"
          component={UserLayout}
        />
      </Switch>
    </Provider>
  );
}

export default App;
