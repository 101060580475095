import { useRef, useEffect, useState } from "react";
import {
  MeshBasicMaterial,
  Mesh,
  BoxGeometry,
  Vector3,
  DoubleSide,
  Box3,
} from "three";
import { useFrame } from "@react-three/fiber";

interface PlaneProps {
  planeOffset: number;
  selectedOrientation: string;
  bbox: Box3;
}

const planeGeometry = new BoxGeometry(1, 1, 1);

function Plane({ selectedOrientation, planeOffset, bbox }: PlaneProps) {
  const plane = useRef<Mesh>();
  const [rotation, setRotation] = useState<any>({ y: 0, x: 0, z: 0 });

  const planeMaterial = new MeshBasicMaterial({
    color: 0x3498db,
    side: DoubleSide,
    opacity: 0.3,
    transparent: true,
  });

  // Update the rotation based on the selected orientation
  useEffect(() => {
    switch (selectedOrientation) {
      case "YZ":
        setRotation({ y: Math.PI / 2, x: Math.PI / 2, z: 0 });
        break;
      case "XZ":
        setRotation({ y: 0, x: Math.PI / 2, z: 0 });
        break;
      case "XY":
        setRotation({ y: 0, x: 0, z: Math.PI / 2 });
        break;
      default:
        setRotation({ y: 0, x: 0, z: 0 });
        break;
    }
  }, [selectedOrientation]);

  // Update the plane's position, rotation, and scale
  useFrame(() => {
    if (plane.current && bbox) {
      const { min, max } = bbox;
      const center = new Vector3().copy(min).add(max).multiplyScalar(0.5);

      let width, height, rotationZ;
      let posX = center.x;
      let posY = center.y;
      let posZ = center.z;

      switch (selectedOrientation) {
        case "YZ":
          width = (max.z - min.z) * 1.1;
          height = (max.y - min.y) * 1.1;
          rotationZ = Math.PI / 2; // Rotate 90 degrees on the Z axis
          posX += planeOffset;
          break;
        case "XZ":
          width = (max.x - min.x) * 1.1;
          height = (max.z - min.z) * 1.1;
          rotationZ = 0;
          posY += planeOffset;
          break;
        case "XY":
          width = (max.x - min.x) * 1.1;
          height = (max.y - min.y) * 1.1;
          rotationZ = Math.PI / 2; // Rotate 90 degrees on the Z axis
          posZ += planeOffset;
          break;
        default:
          width = (max.x - min.x) * 1.1;
          height = (max.y - min.y) * 1.1;
          rotationZ = 0;
          break;
      }

      const { x, y, z } = rotation;

      plane.current.position.set(posX, posY, posZ);
      plane.current.rotation.set(x, y, z + rotationZ);
      plane.current.scale.set(width, height, 1);
    }
  });

  return <mesh ref={plane} geometry={planeGeometry} material={planeMaterial} />;
}

export default Plane;
