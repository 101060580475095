import { useState } from "react";

const useSearch = (searchArray: any[]) => {
  const [searchString, setSearchString] = useState<string>("");

  const handleSearch = (term: string) => {
    setSearchString(term);
  };
  const filteredResult = searchArray
    ? searchArray
        ?.filter((item: any) =>
          item.name.toLowerCase().includes(searchString.toLowerCase()),
        )
        ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
    : [];

  return { filteredResult, handleSearch };
};

export default useSearch;
