import {
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import { useFormContext, FieldError } from "react-hook-form";
import TextAreaCount from "./TextAreaCount";

interface ITextareaField extends TextareaProps {
  name: string;
  label: string;
  containerStyles?: BoxProps;
  required?: boolean;
}

function TextareaField({
  name,
  label,
  containerStyles,
  required = false,
  ...rest
}: ITextareaField) {
  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  watch(name);
  return (
    <Box {...containerStyles}>
      <FormControl isInvalid={!!errors[name]}>
        <FormLabel fontWeight="bold" htmlFor={name}>
          {label}
        </FormLabel>
        <Flex flexDirection="column">
          <Textarea
            borderColor={errors[name] ? "red.500" : ""}
            focusBorderColor={errors[name] ? "red.500" : "brand.400"}
            maxLength={200}
            id={name}
            {...register(name, {required})}
            {...rest}
          />
          <TextAreaCount
            mt="2"
            placeSelf="end"
            currentLength={getValues(name)?.length ? getValues(name).length : 0}
            maxLength={200}
          />
        </Flex>
        {errors[name] && (
          <FormErrorMessage color="red.500">
            {(errors[name] as FieldError)?.message || "Invalid input"}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
}

export default TextareaField;
