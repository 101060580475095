import { useColorModeValue } from "@chakra-ui/system";
import DropDown from "components/molecule/fields/DropDown";
import InputField from "components/molecule/fields/InputField";
import RadioButtonField from "components/molecule/fields/RadioButtonField";
import { UserBasedField } from "types/calculationTypes";

type FormItemProps = {
  formItem: UserBasedField;
  isEditingForm?: boolean;
};
export interface SelectOption {
  id: number;
  display_name?: string;
  name: string;
  current_language?: string;
}
export type ControlType = "text" | "select" | "number" | "checkbox";

function FormItem({
  formItem: {
    field_type_name,
    display_name,
    name,
    options,
    validation_schema,
    value,
    translationId,
  },
  isEditingForm,
}: FormItemProps) {
  const brandColor = useColorModeValue("gray.700", "white");
  const foundOption = options?.find((option) => option?.id === translationId);

  const defaultDropDownValue = {
    label: foundOption ? foundOption.name : value,
    value: foundOption ? foundOption.name : value,
    translationId: foundOption?.id,
  };

  const nullOption = {
    label: "",
    value: "",
    translationId: "",
  };

  const dropdownValues = isEditingForm ? defaultDropDownValue : nullOption;

  const dropdownComponent = (
    <DropDown
      name={name}
      label={display_name}
      options={options}
      defaultValue={dropdownValues}
      validationSchema={validation_schema}
    />
  );

  switch (field_type_name) {
    case "input":
      return (
        <InputField
          color={brandColor}
          label={display_name}
          name={name}
          type="text"
          defaultValue={isEditingForm ? value : ""}
          validationSchema={validation_schema}
        />
      );
    case "drop_down":
      return dropdownComponent;
    case "creatable_drop_down":
      return dropdownComponent;
    default:
      return (
        <RadioButtonField
          name={name}
          label={display_name}
          options={["raw", "finished"]} // TO DO: these should not be hardcoded, but fetched from the middleware, same as other simple user fields
          defaultValue={isEditingForm ? value : "raw"} // default to "raw" if no initial value is passed
          validationSchema={validation_schema}
        />
      );
  }
}

export default FormItem;
