import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const postSimpleFields = async (data: any) => {
  const response = await axios.post(API_URLS.postSimpleUserFields(), data);
  return response;
};

const usePostSimpleFields = () => {
  const mutation = useMutation(postSimpleFields);
  return mutation;
};
export default usePostSimpleFields;
