import { useMutation } from "@tanstack/react-query";
import { resetPasswordInstance } from "services/axios.service";

const generateQRCode = async (data: any) => {
  const response = await resetPasswordInstance.post("otp/generate", data);
  return response.data;
};
const verifyQRCode = async (data: any) => {
  const response = await resetPasswordInstance.post("otp/verify", data);
  return response.data;
};

const validateQRCode = async (data: any) => {
  const response = await resetPasswordInstance.post("otp/validate", data, {
    headers: data.headers,
  });
  return response.data;
};

// create mutation with tanstack query for all above functions.
const useGenerateQRCodeMutation = () => {
  const mutation = useMutation(generateQRCode);
  return mutation;
};
const useVerifyQRCodeMutation = () => {
  const mutation = useMutation(verifyQRCode);
  return mutation;
};
const useValidateQRCodeMutation = () => {
  const mutation = useMutation(validateQRCode);
  return mutation;
};

export {
  useGenerateQRCodeMutation,
  useVerifyQRCodeMutation,
  useValidateQRCodeMutation,
};
