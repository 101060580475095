import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import { ProductionSite } from "types/calculationTypes";
import API_URLS from "utils/apiVersionConfig";

const getProductionSites = async () => {
  const response = await axios.get<ProductionSite[]>(
    API_URLS.getProductionSites(),
  );
  return response.data;
};

const useProductionSites = () => {
  return useQuery<ProductionSite[], Error>(
    ["productionSites"],
    getProductionSites,
    {
      onError: () => {
        toast.error("Error while fetching production possibilities");
      },
    },
  );
};

export default useProductionSites;
