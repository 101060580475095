import { InferType, mixed, number, object, string } from "yup";

const supportedFileTypes = ["xlsx", "xlsm"];
export const ExcelBaselineSchema = object({
  file: mixed<FileList>()
    .required("File is required")
    .test("fileType", "Only xlsx and xlsm files are allowed", (value) => {
      const fileExtension = value[0]?.name?.split(".").pop();
      return supportedFileTypes.includes(fileExtension);
    }),
  created_by_user: number().optional(),
  left_marker: string()
    .required()
    .matches(/^[!@#$%^&*(),.?":{}|<>]+$/, {
      message: "Invalid input. Only special characters are allowed.",
    }),
  right_marker: string()
    .required()
    .matches(/^[!@#$%^&*(),.?":{}|<>]+$/, {
      message: "Invalid input. Only special characters are allowed.",
    }),
});

export type IExcelBaseline = InferType<typeof ExcelBaselineSchema>;

// client, excel_parameter_pipeline, created_by_user
