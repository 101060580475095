/* eslint-disable */
// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import useFetchUser from "services/getVerboseName";
import NavbarLinksUser from "./NavbarLinks";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

interface NavbarProps {
  secondary: boolean;
  message: string | boolean;
  pathnames: Array<string>;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}

export default function Navbar({
  brandText,
  pathnames,
  onOpen,
  secondary,
}: NavbarProps) {
  const User = useFetchUser();
  const [scrolledUp, setScrolledUp] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const windowHeight = window.innerHeight;
      const fivePercentHeight = windowHeight * 0.05;

      setScrolledUp(currentScrollPos < fivePercentHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("gray.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgb(45, 55, 72, 0.5)",
  );
  let gap = "0px";

  return (
    <>
      {!User?.otp_enabled && (
        <Box
          position={"absolute"}
          top="0"
          bg={"red.400"}
          w="100vw"
          textAlign={"center"}
        >
          <Text color={"white"} fontWeight="bold">
            <FormattedMessage
              id="global.navbar.twoFA"
              defaultMessage="We recommend enabling 2FA for your account."
            />
          </Text>
        </Box>
      )}
      <Box
        position="fixed"
        top={scrolledUp ? "30px" : "10px"}
        left="auto"
        right="30"
        boxShadow={navbarShadow}
        backdropFilter={navbarBackdrop}
        bg={navbarBg}
        borderRadius="15px"
        transition="box-shadow 0.25s, background-color 0.25s, filter 0.25s"
        display={secondary ? "block" : "flex"}
        justifyContent="flex-end"
        alignItems="center"
        minH="75px"
        px="12px"
        pt="8px"
        pb="8px"
        w={{
          base: "calc(100vw - 10%)",
          md: "calc(100vw - 7.8%)",
          lg: "calc(100vw - 6%)",
          xl: "calc(100vw - 365px)",
          "2xl": "calc(100vw - 365px)",
        }}
      >
        <Flex
          w="100%"
          flexDirection={{
            sm: "column",
            md: "row",
          }}
          alignItems={{ xl: "center" }}
          mb={gap}
        >
          <Box mb={{ sm: "8px", md: "0px" }}>
            <Breadcrumb>
              <BreadcrumbItem fontSize="sm" color={secondaryText}>
                <BreadcrumbLink
                  onClick={() => history.push("/user/dashboard")}
                  color={secondaryText}
                >
                  <FormattedMessage
                    id="global.navbar.pages"
                    defaultMessage={"Home"}
                  />
                </BreadcrumbLink>
              </BreadcrumbItem>
              {pathnames.map((pathname, index) => (
                <BreadcrumbItem color={secondaryText} fontSize="sm" key={index}>
                  <BreadcrumbLink
                    color={secondaryText}
                    onClick={() => {
                      if (index !== pathnames.length - 1) {
                        history.replace(
                          `/${pathnames.slice(0, index + 1).join("/")}`,
                        );
                      }
                    }}
                  >
                    <FormattedMessage
                      id={`global.breadcrumb.${pathname}`}
                      defaultMessage={pathname}
                    />
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
            {/* Here we create navbar brand, based on route name */}
            <Text
              color={mainText}
              fontWeight="bold"
              fontSize={{ sm: "xl", md: "xl", lg: "3xl" }}
            >
              <FormattedMessage
                id={`sidebar.user.${brandText.replace(/\s+/g, "_")}`}
                defaultMessage={brandText}
              />
            </Text>
          </Box>
          <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
            <NavbarLinksUser onOpen={onOpen} />
          </Box>
        </Flex>
      </Box>
    </>
  );
}
