// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "components/molecule/footer/FooterAdmin";
// Layout components
import UserNavbar from "components/molecule/navbar/Navbar";
import Sidebar from "components/molecule/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import useRoutes from "UserRoutes";

function convertToHyphenCase(inputString: string) {
  const hyphenatedString = inputString.replace(/\s+/g, "-").toLowerCase();
  return hyphenatedString;
}
// Custom Chakra theme
export default function UserLayout(props: { [x: string]: any }) {
  const { ...rest } = props;
  const routes = useRoutes();

  const location = useLocation();
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  const getRoute = () => {
    return window.location.pathname !== "/user/full-screen-maps";
  };

  const pathnames = location.pathname.split("/").filter((x) => x);
  const getActiveRoute = (): string => {
    const activeRoute = "Default Brand Text";

    if (pathnames.length > 0) {
      const currentPath = `/${pathnames[pathnames.length - 1]}`;
      const matchedRoute = routes.find((route) => route.path === currentPath);

      if (matchedRoute) {
        return matchedRoute.name;
      } else {
        return `${pathnames[pathnames.length - 1]}`;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (): boolean => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i += 1) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (): string | boolean => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i += 1) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (): any => {
    return routes.map((route: RoutesType, key: any) => {
      return (
        <Route
          path={route.layout + route.path}
          component={route.component}
          key={key}
        />
      );
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <UserNavbar
                onOpen={onOpen}
                logoText="Casculate Dashboard PRO"
                pathnames={pathnames}
                brandText={getActiveRoute()}
                secondary={getActiveNavbar()}
                message={getActiveNavbarText()}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                {getRoutes()}
                <Redirect from="/" to="/user/dashboard" />
              </Switch>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
