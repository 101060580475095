import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ICustomer } from "models/customer";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useAppSelector } from "store/hooks";
import API_URLS from "utils/apiVersionConfig";

interface AddCustomerProps {
  onClose: () => void;
}

const addCustomer = async ({ data }: any) => {
  const response = await axios.post(API_URLS.addCustomer(), data);
  return response.data;
};

const useAddCustomer = ({ onClose }: AddCustomerProps) => {
  const queryClient = useQueryClient();
  const addCustomerMutation = useMutation(addCustomer, {
    onSuccess: async () => {
      toast.success("Customer added successfully");
      await queryClient.invalidateQueries(["customers"]);
      onClose();
    },
    onError() {
      toast.error("Something went wrong while adding customer.");
    },
  });
  const clientGroup = useAppSelector((state) => state.auth.User.client_group);
  const submitHandler: SubmitHandler<ICustomer> = async (values) => {
    const data = {
      client_group: clientGroup,
      name: values.name,
    };
    addCustomerMutation.mutateAsync({ data });
  };
  return submitHandler;
};

export default useAddCustomer;
