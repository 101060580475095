import Language from "lang/types";
import React, { createContext, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";
import deMessages from "lang/de";
import enMessages from "lang/en";
import axiosInterceptor from "services/axios.service";

const messages: Record<string, Language> = {
  de: deMessages,
  en: enMessages,
};
// Define the type for your context
type LanguageContextType = {
  language: string;
  setLanguage: (lang: string) => void;
};
const getLanguage = () => {
  const lang = navigator.language.split(/[-_]/)[0];
  if (lang in messages) {
    return lang;
  }
  return "en";
};
// Create the initial context
const initialLanguageContext: LanguageContextType = {
  language: getLanguage(),
  setLanguage: () => {},
};

// Create the context
export const LanguageContext = createContext<LanguageContextType>(
  initialLanguageContext,
);

// Create a provider component
export function LanguageProvider({ children }: React.PropsWithChildren) {
  const [language, setLanguage] = useState<string>(
    initialLanguageContext.language,
  );

  // Function to update the language value
  const updateLanguage = (lang: string) => {
    axiosInterceptor(lang);
    setLanguage(lang);
  };

  const contextValue = useMemo(() => {
    return {
      language,
      setLanguage: updateLanguage,
    };
  }, [language]);

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}
