import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customerSchema } from "utils/yupSchemas";
import { ICustomer } from "models/customer";
import useEditCustomer from "../api/useEditCustomer";
import { FormattedMessage } from "react-intl";

type EditCustomerProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomer: ICustomer;
  refetchCustomers: () => void;
};
function EditCustomer({
  onClose,
  selectedCustomer,
  refetchCustomers,
}: EditCustomerProps) {
  const textColor = useColorModeValue("gray.700", "white");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICustomer>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(customerSchema),
    defaultValues: selectedCustomer,
  });
  const submitHandler = useEditCustomer({
    onClose,
    refetchCustomers,
    id: selectedCustomer.id,
  });

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <VStack rowGap={2} py={5}>
        <FormControl>
          <FormLabel
            color={textColor}
            fontWeight="bold"
            fontSize="xs"
            htmlFor="name"
          >
            <FormattedMessage
              id="calculation.data.customerName"
              defaultMessage="Customer Name:"
            />
          </FormLabel>
          <Input
            color={textColor}
            borderRadius="15px"
            placeholder="..."
            fontSize="xs"
            id="name"
            type="text"
            {...register("name")}
          />
          {errors && errors.name && (
            <FormHelperText color="red">
              {errors.name.message && errors.name.message}
            </FormHelperText>
          )}
        </FormControl>

        <Button
          type="submit"
          colorScheme="brand"
          alignSelf="end"
          isDisabled={!isValid}
        >
          <FormattedMessage id="calculation.data.save" defaultMessage="Save" />
        </Button>
      </VStack>
    </form>
  );
}

export default EditCustomer;
