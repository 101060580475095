import { configureStore } from "@reduxjs/toolkit";
import authReducer from "views/auth/signIn/signInSlice";
import calculationReducer from "views/admin/calculations/calculationsSlice";
import calculationResultsReducer from "views/admin/calculations/calculationResultsSlice";
import materialsReducer from "views/admin/calculations/materialSlice";

const reducer = {
  auth: authReducer,
  calculation: calculationReducer,
  calculationResults: calculationResultsReducer,
  materials: materialsReducer,
};
const store = configureStore({
  reducer,
  devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
