import { EventManager, useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import { Box3, Vector3 } from "three";
import { OrbitControls } from "three-stdlib";

interface ModelOrbitControlsProps {
  bbox: Box3;
}

function ModelOrbitControls({ bbox }: ModelOrbitControlsProps) {
  const camera = useThree((state) => state.camera);
  const controls = useMemo(() => new OrbitControls(camera), [camera]);

  const gl = useThree((state) => state.gl);
  const events = useThree((state) => state.events) as EventManager<HTMLElement>;
  const domElement = events.connected ?? gl.domElement;

  useEffect(() => {
    controls.connect(domElement);
    return () => controls.dispose();
  }, [controls, domElement]);

  const { min, max } = bbox;
  const center = min.clone().add(max).multiplyScalar(0.5);

  useEffect(() => {
    controls.target.copy(center);
  }, [controls, center]);

  return <primitive object={controls} />;
}

export default ModelOrbitControls;
