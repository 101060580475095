import { Flex, Input, InputProps } from "@chakra-ui/react";
import React from "react";

type PartialInputProps = Pick<InputProps, "className" | "style">;

type Props = {
  /**
   * full value of the otp input, up to {size} characters
   */
  value: string;
  onChange(value: string): void;
  /**
   * Number of characters/input for this component
   */
  size?: number;
  /**
   * Validation pattern for each input.
   * e.g: /[0-9]{1}/ for digits only or /[0-9a-zA-Z]{1}/ for alphanumeric
   */
  validationPattern?: RegExp;
} & PartialInputProps;

function OTPInputComponent(props: Props) {
  const {
    // Set the default size to 6 characters
    size = 6,
    // Default validation is digits
    validationPattern = /[0-9]{1}/,
    value,
    onChange,
    ...restProps
  } = props;
  const arr = new Array(size).fill("-");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const elem = e.target;
    const val = e.target.value;
    // check if the value is valid
    if (!validationPattern.test(val) && val !== "") return;

    // change the value of the upper state using onChange
    const valueArr = value.split("");
    valueArr[index] = val;
    const newVal = valueArr.join("").slice(0, 6);
    onChange(newVal);

    // focus the next element if there's a value
    if (val) {
      const next = elem.nextElementSibling as HTMLInputElement | null;
      next?.focus();
    }
  };
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const current = e.currentTarget;
    if (e.key === "ArrowLeft" || e.key === "Backspace") {
      const prev = current.previousElementSibling as HTMLInputElement | null;
      prev?.focus();
      prev?.setSelectionRange(0, 1);
      return;
    }

    if (e.key === "ArrowRight") {
      const prev = current.nextSibling as HTMLInputElement | null;
      prev?.focus();
      prev?.setSelectionRange(0, 1);
    }
  };
  return (
    <Flex gap={2} w="60%">
      {/* Map through the array and render input components */}
      {arr.map((_, index) => {
        return (
          <Input
            key={String(index + 1)}
            {...restProps}
            textAlign="center"
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern={validationPattern.source}
            maxLength={6}
            value={value.at(index) ?? ""}
            onChange={(e) => handleInputChange(e, index)}
            onKeyUp={handleKeyUp}
          />
        );
      })}
    </Flex>
  );
}

export default OTPInputComponent;
