import { useEffect, useMemo, useRef, useState } from "react";
import { BufferGeometry, DoubleSide, Group, Mesh } from "three";
import { GroupProps, MeshProps, useFrame } from "@react-three/fiber";

export interface Model3DProps extends Omit<GroupProps, "scale"> {
  scale?: number;
  geometry: BufferGeometry;
  meshProps: MeshProps;
  onLoaded: (boundingRadius: number, mesh: Mesh, group: Group) => any;
}

function Model3D({
  scale = 1,
  geometry,
  meshProps,
  onLoaded,
  ...otherProps
}: Model3DProps) {
  const mesh = useRef<Mesh>(null);
  const group = useRef<Group>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (geometry) {
      setLoading(true);
    }
  }, [geometry]);

  const updateModelGeometry = useMemo(
    () => () => {
      if (!loading || !geometry || !mesh.current || !group.current) {
        return;
      }
      geometry.computeBoundingBox();
      geometry.computeBoundingSphere();
      geometry.computeVertexNormals();
      geometry.scale(scale, scale, scale);

      onLoaded(geometry.boundingSphere.radius, mesh.current, group.current);

      setLoading(false);
    },
    [loading, geometry, onLoaded, scale],
  );

  useFrame(() => {
    updateModelGeometry();
  });

  return (
    <group ref={group} {...otherProps}>
      <mesh ref={mesh} castShadow {...meshProps} geometry={geometry}>
        <meshStandardMaterial
          color="grey"
          side={DoubleSide}
          transparent={true}
          opacity={0.8}
        />
      </mesh>
    </group>
  );
}

export default Model3D;
