import { Flex, Input, useColorModeValue } from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { useState } from "react";
import { ExcelData } from "../api/useFetchExcelData";
import SystemVariableSelector from "./SystemVariableSelector";

function TableInputCellSystemVariable({
  column,
  table,
  getValue,
  row,
}: CellContext<ExcelData, string>) {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const textColor = useColorModeValue("gray.600", "white");
  const setInputValue = async (val: string) => {
    table.options.meta?.updateData(row.index, column.id, val, setValue);
  };
  return (
    <Flex gap="2">
      <Input
        color={textColor}
        value={value}
        fontSize="sm"
        disabled
        _disabled={{ fontcolor: "black" }}
      />
      <SystemVariableSelector
        setValue={setInputValue}
        id={row.original.id}
        icon={getValue() ? "edit" : "add"}
      />
    </Flex>
  );
}

export default TableInputCellSystemVariable;
