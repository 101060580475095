import { Box, useColorModeValue } from "@chakra-ui/react";
import ModelViewer from "./3DScene/ModelViewer";
import useCadFile from "views/admin/calculations/api/useGetCadFile";
import { useQuery } from "@tanstack/react-query";
import { usePartingPlanesMatch } from "contexts/ModelHooks";

import useFetchPartitionPlane from "../../views/admin/calculations/api/fetchPartitionPlane";
import { useEffect } from "react";

interface CadFileViewerType {
  calculation: CalculationType;
}

export default function CadFileViewer(props: CadFileViewerType) {
  const { cad_file, id, status_name, plane } = props.calculation;
  const { data: cadFile } = useCadFile(cad_file);
  const partingPlanesMatch = usePartingPlanesMatch();
  const { data: planeWithCores, refetch: refetchPlaneFieldValues } = useQuery({
    queryKey: ["partitionPlane", plane.id],
    queryFn: () => useFetchPartitionPlane(plane.id),
  });

  useEffect(() => {
    refetchPlaneFieldValues();
  }, [partingPlanesMatch, plane]);

  const editPlane = status_name === "draft";

  return (
    <Box w={{ sm: "full", md: "100%" }} p={5}>
      {cadFile && (
        <ModelViewer
          stlFileUrl={cadFile}
          editPlane={editPlane}
          calculationId={id}
          planeId={plane.id}
          planeCalculationId={plane.calculation}
          planeWithCores={planeWithCores}
        />
      )}
    </Box>
  );
}
