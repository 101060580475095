import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

export interface PredictionResultsMlParams {
  id: number;
  name: string;
  recommended_placeholder: string;
  display_name: string;
  parameter_type: number;
  num_range_min: number;
  num_range_max: number;
  unit: string;
  description: string;
  created_timestamp: string;
  updated_timestamp: string;
  current_language: string;
}

const fetchPredictionResultsMlParams = async (clientId?: number) => {
  const response = await axios.get<PredictionResultsMlParams[]>(
    API_URLS.fetchPredictionResultsMlParams(clientId),
  );
  const uniqueParameters = response.data.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
  );
  return uniqueParameters;
};

export default fetchPredictionResultsMlParams;
