import { Button, Flex, Text } from "@chakra-ui/react";
import Card from "components/atoms/Card";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

export interface LocationState {
  text: string;
  textId: string;
  button: string;
  buttonId: string;
  path: string;
}

function NotFoundView() {
  const location = useLocation();
  const history = useHistory();
  const { text, textId, button, buttonId, path } =
    location.state as LocationState;

  return (
    <Card mt="5rem" fontSize={["sm", "lg", "xl"]}>
      <Flex justifyContent="center" display="grid">
        <Text fontWeight="bold">
          <FormattedMessage id={textId} defaultMessage={text} />
        </Text>
        <Button
          variant="no-hover"
          bg="brand.400"
          alignSelf="flex-end"
          mt="24px"
          onClick={() => history.push(path)}
          _hover={{ bg: "blue.600" }}
          justifyContent="center"
        >
          <Text fontSize="sm" color="#fff" fontWeight="bold">
            <FormattedMessage id={buttonId} defaultMessage={button} />
          </Text>
        </Button>
      </Flex>
    </Card>
  );
}

export default NotFoundView;
