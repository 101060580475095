import "react-app-polyfill/stable";
import "./assets/css/App.css";
import { createRoot } from "react-dom/client";
import App from "App";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import theme from "./theme/theme";
import store from "./store/configStore";
// import axiosInterceptor from "services/axios.service";
import { LanguageProvider } from "contexts/LanguageProvider";

// axiosInterceptor();
const domNode = document.getElementById("root");
const root = createRoot(domNode);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={store}>
      <Toaster />
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <LanguageProvider>
            <App />
          </LanguageProvider>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </QueryClientProvider>,
);
