import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

export interface CalculatorOverwriteInput {
  calculationId: string;
  id: number;
  data: any;
}
const putCalculatorOverwrite = async ({
  id,
  data,
}: CalculatorOverwriteInput) => {
  const response = await axios.put(API_URLS.putCalculatorOverwrite(id), data);
  return response.data;
};

const useCalculatorOverwriteMutation = () => {
  const { mutateAsync, isLoading, isError, isSuccess, error, data } =
    useMutation(putCalculatorOverwrite);
  return { mutateAsync, isLoading, isError, isSuccess, error, data };
};

export default useCalculatorOverwriteMutation;
