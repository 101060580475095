import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import { useFormContext, FieldError } from "react-hook-form";

interface IInputField extends InputProps {
  name: string;
  label?: string;
  containerStyles?: BoxProps;
  validationSchema?: any;
}

function InputField({
  name,
  label,
  containerStyles,
  validationSchema,
  ...rest
}: IInputField) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const required = validationSchema?.required;

  return (
    <Box {...containerStyles}>
      <FormControl
        isInvalid={!!errors[name]}
        isRequired={required}
      >
        {label && (
          <FormLabel fontWeight="bold" htmlFor={name}>
            {label}
          </FormLabel>
        )}
        <Input
          borderColor={errors[name] ? "red.500" : "secondaryGray.100"}
          focusBorderColor={errors[name] ? "red.500" : "brand.400"}
          id={name}
          {...register(name, validationSchema)}
          {...rest}
        />
        {errors[name] && (
          <FormErrorMessage color="red.500">
            {(errors[name] as FieldError)?.message || "Invalid input"}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
}

export default InputField;
