import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IClient } from "models/client";
import API_URLS from "utils/apiVersionConfig";

interface FetchClientsResult {
  clients?: IClient[];
  currentClient?: IClient;
  isLoading: boolean;
}

const fetchClients = async () => {
  const response = await axios.get<IClient[]>(API_URLS.getProductionSites());
  return response.data;
};

const fetchCurrentClient = async (clientId: number) => {
  const response = await axios.get<IClient>(
    API_URLS.fetchCurrentClient(clientId),
  );
  return response.data;
};
const useFetchClients = (): FetchClientsResult => {
  const clientsQuery = useQuery(["clients"], fetchClients);
  const isLoading = clientsQuery.isLoading;

  return {
    clients: clientsQuery.data,
    isLoading,
  };
};

const useFetchCurrentClient = (clientId?: number): FetchClientsResult => {
  const currentClientQuery = useQuery(["currentClient", clientId], () =>
    fetchCurrentClient(clientId),
  );
  const isLoading = currentClientQuery.isLoading;

  return {
    currentClient: currentClientQuery.data,
    isLoading,
  };
};

export { useFetchClients as default, useFetchCurrentClient };
