import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const fetchCalculationById = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(API_URLS.getCalculation(id));
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

export default fetchCalculationById;
