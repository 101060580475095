import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import MoveCamera from "./camera/MoveCamera";
import SelectAxis from "./plane/SelectAxis";
import MovePlane from "./plane/MovePlane";
import { PlaneWithCoresType } from "views/admin/calculations/api/fetchPartitionPlane";
import { Box3 } from "three";
import AxesToggle from "./axes/AxesToggle";

interface PlaneControlsProps {
  showAxes: boolean;
  setShowAxes: (arg0: boolean) => void;
  setCameraPosition: (arg0: CameraPositionType) => void;
  handleOrientationChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  resetPosition: boolean;
  editPlane: boolean;
  calculationId: number;
  planeId: number;
  selectedOrientation: string;
  planeOffset: number;
  setPlaneOffset: (arg0: number) => void;
  planeWithCores: PlaneWithCoresType;
  planeCalculationId: number;
  bbox: Box3;
}

function PlaneControls({
  showAxes,
  setShowAxes,
  setCameraPosition,
  handleOrientationChange,
  editPlane,
  calculationId,
  planeId,
  planeCalculationId,
  planeOffset,
  selectedOrientation,
  setPlaneOffset,
  planeWithCores,
  resetPosition,
  bbox,
}: PlaneControlsProps) {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <AxesToggle showAxes={showAxes} setShowAxes={setShowAxes} />
        {editPlane && (
          <>
            <SelectAxis
              handleOrientationChange={handleOrientationChange}
              selectedOrientation={selectedOrientation}
            />
            <MovePlane
              resetPosition={resetPosition}
              calculationId={calculationId}
              planeOffset={planeOffset}
              selectedOrientation={selectedOrientation}
              setPlaneOffset={setPlaneOffset}
              planeWithCores={planeWithCores}
              planeCalculationId={planeCalculationId}
              planeId={planeId}
              bbox={bbox}
            />
          </>
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <MoveCamera setCameraPosition={setCameraPosition} />
      </Box>
    </>
  );
}

export default PlaneControls;
