import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useIntl, FormattedMessage } from "react-intl";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { IExcelFile } from "types/calculationTypes";
import { Roles } from "views/auth/signIn/signInSlice";
import useGetExcelBaseline from "../calculationDetails/api/useGetExcelBaseline";
import fetchCalculatorOverwrite from "./api/fetchCalculatorOverwrite";
import fetchExcelFiles from "./api/fetchExcelFiles";
import fetchPredictionResults from "./api/fetchPredictionResults";
import fetchPredictionResultsMlParams, {
  PredictionResultsMlParams,
} from "./api/fetchPredictionResultsMlParams";
import useFetchProductionLines from "./api/fetchProductionLines";
import useCalculatorOverwriteMutation from "./api/putCalculatorOverwrite";
import useCreateExcelFile from "./api/useCreateExcelFiles";
import BaselineMissingMessage from "./components/BaselineMissingMessage";
import ResultItem from "./components/ResultItem";
import ResultLoader from "./components/ResultLoader";
import processExcelFiles from "./utils/processExcelFiles";

type ICalculationResultsParams = {
  calculationId: string;
};

function CalculationResultsView() {
  const User = useAppSelector((state) => state.auth.User);
  const queryClient = useQueryClient();
  const intl = useIntl();
  const currentLocale = intl.locale;
  const { calculationId } = useParams<ICalculationResultsParams>();
  const history = useHistory();
  const { data: productionLines } = useFetchProductionLines();
  const {
    data: predictionMLParameters,
    refetch: refetchPredictionMLParameters,
  } = useQuery<PredictionResultsMlParams[]>(
    ["predictionresultmlparameters"],
    () => fetchPredictionResultsMlParams(),
  );
  const { data: excelBaseLines } = useGetExcelBaseline();
  const { mutateAsync } = useCalculatorOverwriteMutation();

  useEffect(() => {
    refetchPredictionMLParameters();
  }, [currentLocale]);

  const { data: defaultPredictionResults, isError: predictionResultsError } =
    useQuery({
      queryKey: ["predictionResults", calculationId],
      queryFn: () => fetchPredictionResults(calculationId),
      refetchInterval: 3000,
      initialData: [],
    });

  useEffect(() => {
    if (predictionResultsError) {
      history.push("/user/404", {
        text: "Results not Found",
        textId: "user.results.resultsNotFound",
        button: "Return to dashboard",
        buttonId: "user.dashboard.redirect",
        path: "/user/dashboard",
      });
    }
  }, [predictionResultsError]);

  const { data: calculationresults } = useQuery({
    queryKey: ["calculationOverrides", calculationId],
    queryFn: () => fetchCalculatorOverwrite(calculationId),
    initialData: [],
    cacheTime: 0,
    refetchInterval: 3000,
  });

  const { data: excelFiles } = useQuery<IExcelFile[] | undefined>({
    queryKey: ["excelFiles", calculationId],
    queryFn: () => fetchExcelFiles(calculationId as string),
    refetchInterval: 3000,
    initialData: [],
    cacheTime: 0,
    onError: (error: any) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      if (data.length > 0) {
        queryClient.invalidateQueries({ queryKey: ["calculations"] });
        setBaselineError(false);
      }
    },
  });

  // function to update excel values
  const { mutateAsync: createExcelFile } = useCreateExcelFile();

  const mergedExcelFiles = useMemo(
    () =>
      processExcelFiles(
        excelFiles,
        productionLines,
        calculationresults,
        defaultPredictionResults,
        predictionMLParameters,
      ),
    [
      excelFiles,
      productionLines,
      calculationresults,
      defaultPredictionResults,
      predictionMLParameters,
    ],
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([
        "calculationOverrides",
        "productionLines",
        // "excelFiles",
      ]);
    };
  }, [queryClient, calculationresults, calculationId]); //  createExcelFile]);

  const handleDownload = (url: string) => {
    window.open(url, "_blank");
  };

  const [excelFilesCreated, setExcelFilesCreated] = useState<boolean>(false);
  const [baselineError, setBaselineError] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (excelFiles?.length === 0 && excelFilesCreated) {
        toast.error(
          "Your results are ready but default baseline is not set. Please upload and set the missing baseline.",
        );
        setBaselineError(true);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [excelFiles, excelFilesCreated]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} minH="100vh">
      {/* todo: fix this error handling by getting a flag from the middleware >> (finished calculating and no baseline available for the selected production line) */}
      {excelBaseLines && excelBaseLines.length < 1 ? (
        <BaselineMissingMessage />
      ) : mergedExcelFiles?.length > 0 ? (
        <Accordion allowToggle defaultIndex={[0]}>
          {mergedExcelFiles?.map((file) => (
            <AccordionItem mb="4" key={file.id}>
              <h2>
                <AccordionButton
                  borderColor="transparent"
                  bg="blue.500"
                  color="white"
                  _hover={{ bg: "blue.600" }}
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {file.production_line_name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <TableContainer w="full" display="flex" flexDir="column">
                  <Button
                    my={4}
                    placeSelf="end"
                    _hover={{
                      bg: "green",
                      cursor: "pointer",
                    }}
                    colorScheme="green"
                    onClick={() => handleDownload(file.excel_file)}
                  >
                    <FormattedMessage
                      id="calculation.detail.download"
                      defaultMessage="Download"
                    />
                  </Button>
                  <Table variant="striped" size="sm">
                    <Thead fontWeight="bold">
                      <Tr>
                        <Th>
                          <FormattedMessage
                            id="calculation.detail.parameter"
                            defaultMessage="Parameter"
                          />
                        </Th>
                        <Th>
                          <FormattedMessage
                            id="calculation.detail.value"
                            defaultMessage="Value"
                          />
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {file.production_line_results?.map((result: any) => (
                        <ResultItem
                          key={result.id}
                          mutate={mutateAsync}
                          result={result}
                          calculationId={calculationId}
                          isEditable={User.role !== Roles.SalesPerson}
                          userId={User.user_id}
                          verboseName={User.verbose_name}
                          createExcelFile={() => createExcelFile(calculationId)}
                        />
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <ResultLoader />
      )}
    </Box>
  );
}

export default CalculationResultsView;
