import { Box, Text } from "@chakra-ui/react";

function BaselineMissingMessage() {
  return (
    <Box
      w="full"
      display="flex"
      gap="10"
      fontSize="20"
      flexWrap="wrap"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={[10, 20, 40]}
    >
      <Text>
        Your results are ready but default baseline is not set.
        <br /> Please upload and configure the missing baseline.
      </Text>
    </Box>
  );
}

export default BaselineMissingMessage;
