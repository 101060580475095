import { useEffect, useMemo } from "react";
import { useThree } from "@react-three/fiber";
import { Box3, Vector3 } from "three";
import { useCameraInitialPosition } from "contexts/ModelHooks";

interface CameraProps {
  cameraPosition: CameraPositionType;
  bbox: Box3;
}

function clamp(min: number, value: number, max: number): number {
  return Math.max(Math.min(value, max), min);
}

const EPS = 0.01;

function polarToCartesian(polar: CameraPositionType): [number, number, number] {
  const latitude = clamp(-Math.PI / 2 + EPS, polar.latitude, Math.PI / 2 - EPS);
  const longitude = clamp(-Math.PI + EPS, polar.longitude, Math.PI - EPS);
  return [
    polar.distance * Math.cos(latitude) * Math.sin(longitude),
    -polar.distance * Math.cos(longitude) * Math.cos(latitude),
    polar.distance * Math.sin(latitude),
  ];
}

function Camera({ cameraPosition, bbox }: CameraProps) {
  const { camera } = useThree();
  const cameraInitialPosition = useCameraInitialPosition();

  useEffect(() => {
    camera.up.applyAxisAngle(new Vector3(1, 0, 0), Math.PI / 2);
  }, [camera]);

  const { min, max } = bbox;
  const center = min.clone().add(max).multiplyScalar(0.5);
  camera.lookAt(center);

  useEffect(() => {
    const coords = polarToCartesian(cameraPosition || cameraInitialPosition);

    camera.position.set(
      coords[0] + center.x,
      coords[1] + center.y,
      coords[2] + center.z,
    );
    camera.lookAt(center);
  }, [cameraPosition]);

  return <perspectiveCamera near={1} far={1000} />;
}

export default Camera;
