import { Icon } from "@chakra-ui/react";
import { MdEngineering } from "react-icons/md";
import LoginView from "views/auth/signIn/LoginView";
import { FaFileExcel, FaHandshake } from "react-icons/fa";
import ExcelBaselineView from "views/admin/excelBaseline/ExcelBaselineView";
import ConfigExcelView from "views/admin/excelProcessing/ConfigExcelView";
import PartnameView from "views/admin/partnames/PartnamesView";
import CustomerView from "views/admin/customers/CustomerView";

const adminRoutes = [
  {
    name: "Part Names",
    layout: "/admin",
    path: "/partnames",
    icon: (
      <Icon as={MdEngineering} width="20px" height="20px" color="inherit" />
    ),
    component: PartnameView,
    secondary: true,
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "/customers",
    icon: <Icon as={FaHandshake} width="20px" height="20px" color="inherit" />,
    component: CustomerView,
    secondary: true,
  },
  {
    name: "Excel Baseline",
    layout: "/admin",
    path: "/excel-baseline",
    icon: <Icon as={FaFileExcel} width="20px" height="20px" color="inherit" />,
    component: ExcelBaselineView,
    secondary: true,
  },
  {
    name: "Config Excel Baseline",
    layout: "/admin",
    path: "/config-excel-baseline/:excelId",
    icon: <Icon as={FaFileExcel} width="20px" height="20px" color="inherit" />,
    component: ConfigExcelView,
    hideInSidebar: true,
  },
  {
    name: "Sign Out",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={FaHandshake} width="20px" height="20px" color="inherit" />,
    component: LoginView,
    hideInSidebar: true,
  },
];

export default adminRoutes;
