import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const deleteCalculation = async (calulationId: number) => {
  const response = await axios.delete(API_URLS.deleteCalculation(calulationId));
  return response.data;
};

const useDeleteCalculation = (calulationId: number) => {
  const mutation = useMutation(() => deleteCalculation(calulationId));
  return mutation;
};

export default useDeleteCalculation;
