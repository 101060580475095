import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { IExcelBaseline } from "../BaselineSchema";
import API_URLS from "utils/apiVersionConfig";

interface PostExcelData extends IExcelBaseline {
  client: number;
}

const postExcelBaseline = async (data: PostExcelData) => {
  const response = await axios.post(API_URLS.getExcelBaseline(), data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const useMutateExcelBaseline = (userClient: number) => {
  const history = useHistory();
  const { mutate, isLoading: isSubmitting } = useMutation(postExcelBaseline, {
    onSuccess: (data) => {
      const excelId = data.id;
      history.push(`/user/config-excel-baseline/${excelId}`);
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.data.client[0]) {
        toast.error("You already have baseline for the client");
      }
    },
  });

  const onSubmit = (data: IExcelBaseline) => {
    mutate({
      ...data,
      file: data.file[0] as unknown as FileList,
      client: userClient,
    });
  };
  return { onSubmit, isSubmitting };
};

export default useMutateExcelBaseline;
