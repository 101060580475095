/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Casculate - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Casculate (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { useContext, useState } from "react";
// Chakra imports
import jwt_decode from "jwt-decode";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { userSchema, AuthLogin } from "utils/yupSchemas";
import { toast } from "react-hot-toast";
import axiosInterceptor, { loginInstance } from "services/axios.service";
import { ILoginTokenInfo, setUserAuth, UserAuth } from "./signInSlice";
import OTPValidate from "./components/OTPValidate";
import { useAppDispatch } from "store/hooks";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

const initialLoginValues: AuthLogin = {
  username: "",
  password: "",
};

function LoginView() {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<AuthLogin>({
    mode: "onChange",
    defaultValues: initialLoginValues,
    resolver: yupResolver(userSchema),
  });
  const textColor = useColorModeValue("gray.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [userObject, setUserObject] = useState<UserAuth>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleClick = () => setShow(!show);
  const intl = useIntl();
  const currentLocale = intl.locale;
  const singInUser: SubmitHandler<AuthLogin> = async (data) => {
    try {
      const response = await loginInstance.post("/auth-token/", data);
      const tokenInfo = jwt_decode<ILoginTokenInfo>(response.data.access);
      //to do: add check for if user has 2fa enabled or disabled
      const userData: UserAuth = {
        client_group: tokenInfo.client_group,
        user_id: tokenInfo.user_id,
        accessToken: response.data.access,
        refreshToken: response.data.refresh,
        role: Number(tokenInfo.role),
        role_name: tokenInfo.role_name,
        username: data.username,
        expiresIn: tokenInfo.exp,
        tokenInit: tokenInfo.iat,
        client: tokenInfo.client,
        otp_enabled: tokenInfo.otp_enabled,
      };
      setUserObject(userData);

      if (userData.otp_enabled) {
        setShowOtp(true);
      } else {
        dispatch(setUserAuth(userData));
        axiosInterceptor(currentLocale);
        history.replace("/user/dashboard");
      }
    } catch (error) {
      toast.error(
        intl.formatMessage({
          id: "global.toasts.errorLogin",
          defaultMessage: "Wrong Credentials!",
        }),
      );
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration}>
      {!showOtp ? (
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mb={{ base: "20px", md: "auto" }}
          my="auto"
        >
          <Heading color={textColor} fontSize="36px" mb="10px">
            <FormattedMessage id="login.signIn" defaultMessage={"Sign In"} />
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            <FormattedMessage
              id="login.signInDescription"
              defaultMessage={"Enter your username and password to sign in!"}
            />
          </Text>
          <form onSubmit={handleSubmit(singInUser)}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                <FormattedMessage
                  id="login.username"
                  defaultMessage={"Username"}
                />
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="username"
                fontWeight="500"
                {...register("username")}
                size="lg"
              />
              <Text color="red" my="2">
                {errors?.username?.message}
              </Text>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                <FormattedMessage
                  id="login.password"
                  defaultMessage={"Password"}
                />
                <Text color={brandStars}>*</Text>
              </FormLabel>

              <InputGroup size="md">
                <Input
                  isRequired
                  fontSize="sm"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  {...register("password")}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <Text color="red" my="2">
                {errors?.password?.message}
              </Text>

              <Button
                fontSize="sm"
                variant="brand"
                _hover={{ bg: "blue.600" }}
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                isDisabled={!isValid}
              >
                <FormattedMessage
                  id="login.signInButton"
                  defaultMessage={"Sign In"}
                />
              </Button>
            </FormControl>
          </form>
        </Flex>
      ) : (
        <OTPValidate userObject={userObject} />
      )}
    </DefaultAuth>
  );
}

export default LoginView;
