import axios from "axios";
import { IInputParameters } from "types/calculationTypes";
import API_URLS from "utils/apiVersionConfig";

const fetchInputParameters = async (
  calculationId: number,
): Promise<IInputParameters[]> => {
  const response = await axios.get(
    API_URLS.fetchInputParameters(calculationId),
  );
  return response.data;
};

export default fetchInputParameters;
