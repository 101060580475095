import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import API_URLS from "utils/apiVersionConfig";

const changeInputParamMutation = async ({ paramID, data }: any) => {
  const response = await axios.put(API_URLS.changeInputParameter(paramID), data);
  return response.data;
};

const useChangeInputParamMutationMutation = (
  refetchInputParams: any,
  refetchMLParams: any,
) => {
  const mutation = useMutation(changeInputParamMutation, {
    onSuccess: () => {
      refetchInputParams();
      refetchMLParams();
    },
  });

  return mutation;
};

export default useChangeInputParamMutationMutation;
