import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const putSimpleFields = async (data: any) => {
  const response = await axios.put(API_URLS.putSimpleUserFields(data?.id), data);
  return response;
};

const usePutSimpleFields = () => {
  const mutation = useMutation(putSimpleFields);
  return mutation;
};
export default usePutSimpleFields;
