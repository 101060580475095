import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import API_URLS from "utils/apiVersionConfig";
import { Spinner, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";

const createCalculation = async (data: any) => {
  const response = await axios.post(API_URLS.postCalculation(), data);
  return response.data;
};

const usePostNewCalculation = (
  setCadFileError: (error: any) => void,
  setCreatingCalc: (arg0: boolean) => void,
) => {
  const history = useHistory();
  const intl = useIntl();
  const calcMutation = useMutation(createCalculation, {
    onMutate: () => {
      setCreatingCalc(true);
    },
    onSuccess: (data) => {
      setCreatingCalc(false);
      history.push("/user/newCalculation", {
        cadFile: data.data.cad_file,
        calculationId: data.data.id,
      });
    },
    onError: (error: any) => {
      setCreatingCalc(false);
      if (error.response.status === 400) {
        toast.dismiss();
        setCadFileError(error.response.data);
      }
    },
  });
  return calcMutation;
};
export default usePostNewCalculation;
