import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

export default function Footer() {
  let textColor = useColorModeValue("gray.400", "white");

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px={{ base: "30px", md: "0px" }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", lg: "0px" }}
      >
        &copy; {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          <FormattedMessage
            id="login.footer.allRightsReserved"
            defaultMessage={"All rights reserved."}
          />
        </Text>
      </Text>
    </Flex>
  );
}
