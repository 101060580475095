import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const fetchClientGroup = async () => {
  const response = await axios.get(API_URLS.fetchClientGroup());
  return response.data;
};

const useGetClientGroup = () => {
  const clientGroupQuery = useQuery(["clientgroups"], fetchClientGroup, {
    cacheTime: 0,
  });
  return clientGroupQuery;
};
export default useGetClientGroup;
