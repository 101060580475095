import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import { Select as ReactSelect } from "chakra-react-select";
import { Controller, FieldError, useFormContext } from "react-hook-form";

interface DropDownProps {
  name: string;
  label: string;
  containerStyles?: BoxProps;
  options?: any[];
  defaultValue?: {
    label: any;
    value: any;
    translationId: any;
  };
  validationSchema?: any;
}

function DropDown({
  name,
  label,
  containerStyles,
  options,
  defaultValue,
  validationSchema,
}: DropDownProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const mappedOptions = options?.map((option) => ({
    label: option.name,
    value: option.name,
    translationId: option.id,
  }));
  const required = validationSchema?.required;

  return (
    <Box {...containerStyles}>
      <FormControl
        isInvalid={!!errors[name]}
        isRequired={required}
        id={name}
      >
        <Text fontWeight="bold">
          {label}
          {required && (
            <Text as="span" color="red.500">
              {" "}*
            </Text>
          )}
        </Text>
        <Controller
          control={control}
          defaultValue={defaultValue?.value}
          name={name}
          rules={validationSchema}
          render={({ field }) => (
            <ReactSelect
              isSearchable
              size="md"
              placeholder="..."
              value={
                mappedOptions.find((option) => option.value === field.value) ||
                defaultValue
              }
              options={mappedOptions}
              onChange={(selectedOption) => {
                field.onChange(selectedOption?.value);
              }}
              colorScheme="brand.400"
            />
          )}
        />

        {errors[name] && (
          <FormErrorMessage color="red.500">
            {(errors[name] as FieldError)?.message || "Invalid input"}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
}

export default DropDown;
