import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "components/atoms/Card";
import { useHistory } from "react-router-dom";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import { useInfiniteQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import fetchCalculationsCAD from "../api/fetchMatchingCAD";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { TriangleDownIcon } from "@chakra-ui/icons";

interface FileExistsOverlayProps {
  createNewCalculation: () => void;
  cadFileNumber: string;
  creatingCalc: boolean;
}

function FileExistsOverlay(props: FileExistsOverlayProps) {
  const { createNewCalculation, cadFileNumber, creatingCalc } = props;
  const history = useHistory();
  const { data, isLoading, isError, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      refetchInterval: 5000,
      queryKey: ["calculations"],
      queryFn: ({ pageParam = 1 }) =>
        fetchCalculationsCAD(pageParam, cadFileNumber),
      getNextPageParam: (lastPage) => {
        return lastPage?.next?.split("p=")[1];
      },
    });

  /**
   * Retrieves the clean values for the firma from the calculation data.
   * @param {SimpleUserFieldValue[]} simpleUserFieldValues - The array of simple user field values.
   * @returns {string} - The clean value for the firma_clean (which is the customer name), or an empty string if not found.
   */
  const getCustomerName = (
    simpleUserFieldValues: SimpleUserFieldValue[],
  ): string => {
    if (!Array.isArray(simpleUserFieldValues)) {
      return "";
    }

    const firmaCleanValue = simpleUserFieldValues.find(
      (item) => item.name === "Firma_clean",
    );
    return firmaCleanValue
      ? String(firmaCleanValue.simple_user_field_value)
      : "";
  };

  const calculations = data?.pages.flatMap((page) => page.results);

  if (isLoading) {
    return (
      <Box display="flex" justifyItems="center" alignItems="center" h="100vh">
        <Spinner m="auto" color="brand.500" size="xl" />
      </Box>
    );
  }
  if (isError) {
    const intl = useIntl();
    return (
      <div>
        {toast.error(
          intl.formatMessage({
            id: "global.toasts.errorFetchingCalculations",
            defaultMessage: "Something went wrong while fetching calculations.",
          }),
        )}
      </div>
    );
  }

  return (
    <>
      <Text fontSize={{ sm: "xl", md: "2xl" }}>
        <FormattedMessage
          id="calculation.newCalcModal.desc"
          defaultMessage="Some calculations already exist against this file."
        />
      </Text>
      <Text fontSize={{ sm: "lg", md: "xl" }} mt={5}>
        <FormattedMessage
          id="calculation.newCalcModal.question"
          defaultMessage="Do you want to open it or create new one?"
        />
      </Text>
      <Box position="relative" mt={5}>
        <Box>
          <Card shadow="base" display="flex" flexDir="row">
            <TableContainer w="full">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>
                      <FormattedMessage
                        id="calculation.status"
                        defaultMessage="Status"
                      />
                    </Th>
                    <Th>
                      <FormattedMessage
                        id="calculation.data.customerName"
                        defaultMessage="Customer Name"
                      />
                    </Th>
                    <Th>
                      <FormattedMessage
                        id="user.dashboard.details"
                        defaultMessage="Details"
                      />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {calculations.map((calculation) => {
                    const status = calculation.status_name.toString();
                    return (
                      <Tr key={calculation.id}>
                        <Td>
                          {" "}
                          <Flex align="center">
                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={
                                calculation.status_name === "created"
                                  ? "green.500"
                                  : calculation.status_name === "preprocessing"
                                  ? "orange.400"
                                  : calculation.status_name === "draft"
                                  ? "grey.500"
                                  : calculation.status_name === "error"
                                  ? "red.500"
                                  : calculation.status_name === "aborted"
                                  ? "orange.600"
                                  : calculation.status_name === "finished"
                                  ? "green.500"
                                  : null
                              }
                              as={
                                calculation.status_name === "created"
                                  ? MdCheckCircle
                                  : calculation.status_name === "Finished"
                                  ? MdCancel
                                  : calculation.status_name === "Error"
                                  ? MdOutlineError
                                  : null
                              }
                            />
                            <Text fontSize="sm" fontWeight="700">
                              <FormattedMessage
                                id={`user.dashboard.status.${status}`}
                                defaultMessage={status}
                              />
                            </Text>
                          </Flex>
                        </Td>
                        <Td>
                          {getCustomerName(
                            calculation?.simple_user_field_values,
                          )}
                        </Td>

                        <Td>
                          <Button
                            variant="brand"
                            onClick={() => {
                              history.push({
                                pathname: `/user/details/${calculation.id}`,
                                state: { calculation },
                              });
                            }}
                          >
                            <FormattedMessage
                              id="calculation.newCalcModal.open"
                              defaultMessage="Open"
                            />
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Card>
        </Box>
        {hasNextPage && (
          <Button
            onClick={() => {
              fetchNextPage();
            }}
            mt="20px"
            mx="auto"
            display="block"
            colorScheme="brand"
          >
            <TriangleDownIcon color="white" />
          </Button>
        )}
      </Box>

      <Flex justifyContent="flex-end" mt={3}>
        <Button
          w="fit-content"
          bg="blue.500"
          fontWeight="500"
          color="white"
          _hover={{ bg: "blue.600" }}
          isDisabled={creatingCalc}
          onClick={() => createNewCalculation()}
          display="flex"
          alignItems="center"
          justifyContent={creatingCalc ? "space-between" : "center"}
        >
          {creatingCalc ? (
            <>
              <Spinner size="sm" color="white" mr={2} />
              <FormattedMessage
                id="user.excelBaseline.uploading"
                defaultMessage="Uploading..."
              />
            </>
          ) : (
            <FormattedMessage
              id="calculation.newCalcModal.create"
              defaultMessage="Create New"
            />
          )}
        </Button>
      </Flex>
    </>
  );
}

export default FileExistsOverlay;
