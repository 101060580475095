type ApiVersion = "v1" | "v2";

const API_VERSION: Record<ApiVersion, string> = {
  v1: "v1",
  v2: "v2",
};

const API_URLS = {
  getUser: (userId: number) => `${API_VERSION.v1}/users/${userId}`,

  getCalculations: (page: number) =>
    `${API_VERSION.v1}/calculations/?count=10&p=${page}`,
  getCalculation: (id: any) => `${API_VERSION.v1}/calculations/${id}`,
  postCalculation: () => `${API_VERSION.v1}/calculations/`,
  postCopyCalculation: (calculationId: number) =>
    `${API_VERSION.v2}/calculations/${calculationId}/copy/`,
  putCalculation: (calculationId: number) =>
    `${API_VERSION.v1}/calculations/${calculationId}/`,
  deleteCalculation: (calculationId: any) =>
    `${API_VERSION.v1}/calculations/${calculationId}/`,
  fetchCalculations: (pageParam: any, status: any) =>
    `${
      API_VERSION.v1
    }/calculations/?count=10&ordering=-created_timestamp&p=${pageParam}${
      status ? `&status=${status}` : ""
    }`,

  getCalculationPartingPlane: (planeId: number) =>
    `${API_VERSION.v1}/planes/${planeId}/`,
  putCalculationPartingPlane: (planeId: number) =>
    `${API_VERSION.v1}/planes/${planeId}/`,

  extractCadParameters: (calculationId: number) =>
    `${API_VERSION.v1}/calculations/${calculationId}/extract_cadfile_parameters/`,
  fetchCalculationsCAD: (pageParam: any, cadFileId: any) =>
    `${API_VERSION.v1}/calculations/?count=10&ordering=-created_timestamp&p=${pageParam}&cad_file=${cadFileId}`,

  runCalculationById: (calculationId: number) =>
    `${API_VERSION.v1}/calculations/${calculationId}/run/`,

  fetchMlParameters: () => `${API_VERSION.v1}/cadmlparameters/`,

  fetchPredictionResultsMlParams: (clientId?: number) =>
    `${API_VERSION.v1}/predictionresultmlparameters?${
      clientId ? `client=${clientId}` : ""
    }`,

  fetchUserBasedFieldsData: (formEndpoint: string) =>
    `${API_VERSION.v1}/${formEndpoint}/`,
  fetchUserBasedForm: () => `${API_VERSION.v1}/userbasedfields?form_type=0`,
  fetchUserBasedFields: () => `${API_VERSION.v1}/userbasedfields/`,
  fetchFormFields: () => `${API_VERSION.v1}/simpleuserfields/`,
  putSimpleUserFields: (id: any) =>
    `${API_VERSION.v1}/simpleuserfieldvalues/${id}/`,
  postSimpleUserFields: () => `${API_VERSION.v1}/simpleuserfieldvalues/`,
  getSimpleUserFieldValue: (calculationId: string) =>
    `${API_VERSION.v1}/simpleuserfieldvalues?calculation=${calculationId}`,

  changeInputParameter: (paramID: any) =>
    `${API_VERSION.v1}/inputparameters/${paramID}/`,
  fetchInputParameters: (calculationId: number) =>
    `${API_VERSION.v1}/inputparameters/?calculation=${calculationId}`,

  getCADFile: (cadFileId: number) => `${API_VERSION.v1}/cadfiles/${cadFileId}/`,
  postCADFile: () => `${API_VERSION.v1}/cadfiles/`,

  getExcelBaseline: () => `${API_VERSION.v1}/excelfiles/`,
  deleteExcelBaseline: (id: any) => `${API_VERSION.v1}/excelfiles/${id}/`,
  fetchExcelFile: (id: number) => `${API_VERSION.v1}/excelfiles?client=${id}`,

  createExcelFileAPI: () => `${API_VERSION.v1}/calculationoffersave/`,
  fetchExcelFilesCOF: (calculationId: string) =>
    `${API_VERSION.v1}/calculationofferfile/?calculation=${calculationId}`,

  fetchPredictionResults: (calculationId: string) =>
    `${API_VERSION.v1}/predictionresults/?calculation=${calculationId}`,

  fetchCalculatorOverwrite: (calculationId: string) =>
    `${API_VERSION.v1}/calculatoroverwrites/?calculation=${calculationId}`,

  putCalculatorOverwrite: (id: number) =>
    `${API_VERSION.v1}/calculatoroverwrites/${id}/`,

  getExcelFilePlaceholders: (fileId: number) =>
    `${API_VERSION.v1}/excelfileplaceholders?excel_file=${fileId}`,
  postExcelFilePlaceholders: () => `${API_VERSION.v1}/excelfileplaceholders/`,
  patchExcelFilePlaceholders: (id: number) =>
    `${API_VERSION.v1}/excelfileplaceholders/${id}/`,
  putExcelFilePlaceholders: (id: number) =>
    `${API_VERSION.v1}/excelfileplaceholders/${id}/`,

  fetchCustomers: () => `${API_VERSION.v1}/customers/`,
  addCustomer: () => `${API_VERSION.v1}/customers/`,
  deleteCustomer: (id: any) => `${API_VERSION.v1}/customers/${id}/`,
  editCustomer: (id: any) => `${API_VERSION.v1}/customers/${id}/`,

  fetchPartnames: () => `${API_VERSION.v1}/partnames/`,
  addPartname: () => `${API_VERSION.v1}/partnames/`,
  editPartname: (partnameId: any) =>
    `${API_VERSION.v1}/partnames/${partnameId}/`,
  deletePartname: (partnameId: number) => `/partnames/${partnameId}/`,

  fetchTestStandards: () => `${API_VERSION.v1}/teststandards/`,

  fetchMaterial: () => `${API_VERSION.v1}/materials/`,
  addMaterial: () => `${API_VERSION.v1}/materials/`,
  deleteMaterial: (id: any) => `${API_VERSION.v1}/materials/${id}/`,
  editMaterial: (id: any) => `${API_VERSION.v1}/materials/${id}/`,

  fetchClientGroup: () => `${API_VERSION.v1}/clientgroups/`,

  getProductionSites: () => `${API_VERSION.v1}/clients/`,

  fetchCurrentClient: (clientId: number) =>
    `${API_VERSION.v1}/clients/${clientId}`,

  fetchProductionLines: () => `${API_VERSION.v1}/productionlines/`,
  deleteProductionLine: (id: any) => `${API_VERSION.v1}/productionlines/${id}/`,
};

export default API_URLS;
