import { Box, Divider, Text } from "@chakra-ui/react";
import IconBar from "components/atoms/icons/IconBar";

type Props = {
  id: number;
  name: string;
  description: string;
  deletePartname?: (param: number) => void;
  editPartname?: (param: number) => void;
  viewPartname?: () => void;
};

function PartnameItem({
  id,
  name,
  description,
  deletePartname,
  editPartname,
  viewPartname,
}: Props) {
  return (
    <Box p={5} key={id}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Text>{name}</Text>
          <Text fontSize="xs" color="gray.500">
            {description}
          </Text>
        </Box>
        <IconBar
          onViewClick={viewPartname}
          onDeleteClick={() => deletePartname(id!)}
          onEditClick={() => editPartname(id!)}
        />
      </Box>
      <Divider mt={2} />
    </Box>
  );
}

export default PartnameItem;
