// create a hook to get the excel baseline with react query, the endpoint is excelfiles/

import { useQuery } from "@tanstack/react-query";

import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

export interface ExcelBaseline {
  id: number;
  file: string;
  excel_file: string;
  file_name: string;
  type: string;
  created_by_user: number;
  excel_parameter_pipeline: number;
  client: number | string;
  left_marker: string;
  right_marker: string;
  excel_baseline_sheet_names: string;
  comments: string;
  created_timestamp: string;
  deleted_timestamp: string | null;
}

const fetchExcelBaseline = async () => {
  const response = await axios.get<ExcelBaseline[]>(API_URLS.getExcelBaseline());
  return response.data;
};

const useGetExcelBaseline = () => {
  return useQuery(["excelBaseline"], () => fetchExcelBaseline());
};

export default useGetExcelBaseline;
