import {
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
  isError: boolean;
  mergedParameters: any;
}

function InputParametersDetails({ isError, mergedParameters }: Props) {
  const intl = useIntl();
  if (isError) {
    return (
      <Box>
        {toast.error(
          intl.formatMessage({
            id: "global.toasts.errorFetchingInputP",
            defaultMessage:
              "Something went wrong while fetching input parameters.",
          }),
        )}
      </Box>
    );
  }
  return (
    <Accordion allowToggle defaultChecked={false}>
      <AccordionItem>
        <h2>
          <AccordionButton
            borderColor="transparent"
            bg="blue.500"
            color="white"
            _hover={{ bg: "blue.600" }}
          >
            <Box flex="1" textAlign="left" fontSize="3xl" fontWeight="bold">
              <FormattedMessage
                id="calculation.detail.cadParameters"
                defaultMessage="Cad Parameters"
              />
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <TableContainer w="full">
            <Table variant="striped" size="lg">
              <Thead fontWeight="bold">
                <Tr>
                  <Th>
                    <FormattedMessage
                      id="calculation.detail.parameterName"
                      defaultMessage="Parameter Name"
                    />
                  </Th>
                  <Th>
                    <FormattedMessage
                      id="calculation.detail.parameterValue"
                      defaultMessage="Parameter Value"
                    />
                  </Th>
                  <Th>
                    <FormattedMessage
                      id="calculation.detail.unit"
                      defaultMessage="Unit"
                    />
                  </Th>
                  <Th>
                    <FormattedMessage
                      id="calculation.detail.extractedP"
                      defaultMessage="Extracted Parameter"
                    />
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mergedParameters()?.map((item: any, index: any) => {
                  return (
                    <Tr key={index}>
                      <Td fontWeight="bold">{item.display_name}</Td>
                      <Td fontWeight="bold">{item.ml_parameter_value}</Td>
                      <Td fontWeight="bold">{item.unit}</Td>
                      <Td fontWeight="bold">{item.extracted_parameter}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default InputParametersDetails;
