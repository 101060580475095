// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  Icon,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { MdDelete, MdUpload } from "react-icons/md";
import Dropzone from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormattedMessage } from "react-intl";
// Assets
import Card from "components/atoms/Card";
// Custom components
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CadFileError } from "types/calculationTypes";
import { toast } from "react-hot-toast";
import { FileInput, fileSchema } from "../CalculationSchema";
import FileExistsOverlay from "./components/FileExistsOverlay";
import Background from "../../../../assets/img/background.jpg";
import usePostCadFileMutation from "./api/usePostCadFileMutation";
import usePostNewCalculation from "./api/usePostNewCalculation";

function CadFileUploadView() {
  const bgNextButton = useColorModeValue("brand.400", "orange.300");
  const dropZone = useColorModeValue("gray.100", "gray.600");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const dispatch = useAppDispatch();
  const brandColor = useColorModeValue("brand.400", "brand.300");
  const [cadFileError, setCadFileError] = useState<CadFileError>();
  const userId = useAppSelector((state) => state.auth.User.user_id);
  const username = useAppSelector((state) => state.auth.User.username);
  const client = useAppSelector((state) => state.auth.User.client);
  const [creatingCalc, setCreatingCalc] = useState(false);

  useDisclosure();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<FileInput>({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: { cad_file: [] as unknown as FileList },
    resolver: yupResolver(fileSchema),
  });
  const { mutation, onSubmit } = usePostCadFileMutation(
    userId,
    client,
    setCadFileError,
    setCreatingCalc,
  );
  const finalRef = useRef(null);

  const onError = (error: any) => {
    toast.error(error.cad_file.message);
  };

  useEffect(() => {
    watch("cad_file");
  }, [dispatch, username, watch]);

  const calcMutation = usePostNewCalculation(setCadFileError, setCreatingCalc);

  const createNewCalculation = () => {
    calcMutation.mutate({
      created_by_user: Number(userId),
      ml_parameter_pipeline: 1,
      cad_file: cadFileError?.cad_file[0],
    });
  };

  return (
    <Box ref={finalRef}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Box
          bg={`url(${Background})`}
          bgSize="cover"
          h={{ sm: "45vh", md: "35vh" }}
          w="100%"
        />
        <Flex
          direction="column"
          minH="100vh"
          mt={{ sm: "-20vh", md: "-17vh", lg: "-10vh" }}
          align="center"
          w={{ sm: "full", md: "full", lg: "850px" }}
          mx="auto"
        >
          <FormControl>
            <Card>
              <Box>
                <Controller
                  control={control}
                  name="cad_file"
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, onBlur }, fieldState }) => (
                    <Dropzone
                      noClick
                      onDrop={(acceptedFiles) => {
                        setValue(
                          "cad_file",
                          acceptedFiles as unknown as FileList,
                          {
                            shouldValidate: true,
                          },
                        );
                      }}
                    >
                      {({ getRootProps, getInputProps, open }) => (
                        <Box>
                          <Flex
                            align="center"
                            justify="center"
                            bg={dropZone}
                            border="1px dashed"
                            borderColor={borderColor}
                            borderRadius="16px"
                            h="max-content"
                            cursor="pointer"
                            w={{ base: "100%", "2xl": "100%" }}
                            me="36px"
                            maxH={{ base: "100%", "2xl": "100%" }}
                            p="20"
                            minH={{
                              base: "15vh",
                              lg: "15vh",
                              "2xl": "15vh",
                            }}
                            {...getRootProps({ className: "dropzone" })}
                          >
                            <input
                              {...getInputProps({
                                id: "spreadsheet",
                                onChange,
                                onBlur,
                              })}
                            />

                            <Button variant="no-effects" onClick={open}>
                              <Box>
                                <Icon
                                  as={MdUpload}
                                  w="80px"
                                  h="80px"
                                  color={brandColor}
                                />
                                <Flex justify="center" mx="auto" mb="12px">
                                  <Text
                                    fontSize="xl"
                                    fontWeight="700"
                                    color={brandColor}
                                  >
                                    <FormattedMessage
                                      id="calculation.dropzone.title"
                                      defaultMessage="Upload Files"
                                    />
                                  </Text>
                                </Flex>
                                <Text
                                  fontSize="sm"
                                  fontWeight="500"
                                  pb="10px"
                                  color="secondaryGray.500"
                                >
                                  <FormattedMessage
                                    id="calculation.dropzone.desc"
                                    defaultMessage="STEP, and STL files are allowed."
                                  />
                                </Text>
                              </Box>
                            </Button>
                          </Flex>

                          <FormHelperText color="red">
                            {fieldState?.error?.message}
                          </FormHelperText>
                        </Box>
                      )}
                    </Dropzone>
                  )}
                />
                {getValues("cad_file")?.length > 0 && (
                  <Box cursor="pointer" display="flex" flexDir="row" mt="3">
                    <Text>{getValues("cad_file")[0]?.name}</Text>
                    <Icon
                      as={MdDelete}
                      h="24px"
                      w="24px"
                      ml={2}
                      onClick={() => {
                        setValue("cad_file", [] as unknown as FileList);
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Flex justifyContent="flex-end" mt={3}>
                <Button
                  w="fit-content"
                  bg={bgNextButton}
                  fontWeight="500"
                  color="white"
                  _hover={{ bg: "blue.600" }}
                  isDisabled={
                    getValues("cad_file").length === 0 ||
                    !isValid ||
                    mutation.isLoading ||
                    creatingCalc
                  }
                  display="flex"
                  alignItems="center"
                  justifyContent={creatingCalc ? "space-between" : "center"}
                  type="submit"
                >
                  {creatingCalc ? (
                    <>
                      <Spinner size="sm" color="white" mr={2} />
                      <FormattedMessage
                        id="user.excelBaseline.uploading"
                        defaultMessage="Uploading..."
                      />
                    </>
                  ) : (
                    <FormattedMessage
                      id="calculation.dropzone.button"
                      defaultMessage="Next"
                    />
                  )}
                </Button>
              </Flex>
            </Card>
          </FormControl>
        </Flex>
        <Modal
          finalFocusRef={finalRef}
          isOpen={cadFileError?.calculation?.length >= 1}
          onClose={() => {
            setCadFileError(undefined);
          }}
          size="6xl"
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage
                id="calculation.newCalcModal.title"
                defaultMessage="Choose an option"
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FileExistsOverlay
                creatingCalc={creatingCalc}
                createNewCalculation={createNewCalculation}
                cadFileNumber={cadFileError?.cad_file.at(-1)}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </form>
    </Box>
  );
}

export default CadFileUploadView;
