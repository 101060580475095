import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useAppSelector } from "store/hooks";
import API_URLS from "utils/apiVersionConfig";

const getUser = async (userId: number) => {
  const response = await axios.get(API_URLS.getUser(userId));
  return response.data;
};

const useFetchUser = () => {
  const userId = useAppSelector((state) => state.auth.User.user_id);
  const { data } = useQuery(["user"], () => getUser(userId));

  return data;
};

export default useFetchUser;
