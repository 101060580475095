import {
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ExcelData } from "../api/useFetchExcelData";
import SheetMenuSelector from "./SheetMenuSelector";
import API_URLS from "utils/apiVersionConfig";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

interface FinalCostSectionProps {
  excelId: number;
  existingFinalCost?: ExcelData;
}

function FinalCostSection({
  excelId,
  existingFinalCost,
}: FinalCostSectionProps) {
  const queryClient = useQueryClient();
  const cellStyles = {
    borderColor: useColorModeValue("gray.200", "gray.500"),
    color: useColorModeValue("gray.700", "white"),
    fontSize: "sm",
  };

  const headerStyles = {
    borderColor: useColorModeValue("gray.200", "gray.500"),
    color: useColorModeValue("gray.500", "white"),
    fontSize: "md",
    fontWeight: "700",
  };

  const intl = useIntl();
  const [locationValue, setLocationValue] = useState<string>(
    existingFinalCost?.cell_position || "",
  );
  const [sheetName, setSheetName] = useState<string>(
    existingFinalCost?.sheet_name || "",
  );
  const onSave = async () => {
    if (locationValue.split(" ").length > 1) {
      toast.error(
        intl.formatMessage({
          id: "user.excelBaseline.cellPositionSpacesError",
          defaultMessage: "Cell position cannot contain spaces",
        }),
      );
      return;
    }
    if (existingFinalCost) {
      await axios
        .put(API_URLS.putExcelFilePlaceholders(existingFinalCost.id), {
          cell_position: locationValue.trim().toUpperCase(),
          sheet_name: sheetName,
          type_of_data: intl.formatMessage({
            id: "user.excelBaseline.decimal",
            defaultMessage: "Decimal",
          }),
          excel_file: excelId,
          name: intl.formatMessage({
            id: "user.excelBaseline.finalCost",
            defaultMessage: "Final Cost",
          }),
        })
        .then(() => {
          queryClient.invalidateQueries(["ExcelData"]);
          toast.success(
            intl.formatMessage({
              id: "user.excelBaseline.finalCostSaved",
              defaultMessage: "Final Cost saved",
            }),
          );
        });
      return;
    }

    await axios
      .post(API_URLS.postExcelFilePlaceholders(), {
        cell_position: locationValue.trim().toUpperCase(),
        sheet_name: sheetName,
        type_of_data: intl.formatMessage({
          id: "user.excelBaseline.decimal",
          defaultMessage: "Decimal",
        }),
        excel_file: excelId,
        name: intl.formatMessage({
          id: "user.excelBaseline.finalCost",
          defaultMessage: "Final Cost",
        }),
      })
      .then(() => {
        toast.success(
          intl.formatMessage({
            id: "user.excelBaseline.finalCostSaved",
            defaultMessage: "Final Cost saved",
          }),
        );
        queryClient.invalidateQueries(["ExcelData"]);
      });
  };
  useEffect(() => {
    if (existingFinalCost) {
      setLocationValue(existingFinalCost.cell_position);
      setSheetName(existingFinalCost.sheet_name);
    }
  }, [existingFinalCost]);

  return (
    <TableContainer>
      <Text fontSize="xl" fontWeight="bold" pl="20px" mt="4">
        <FormattedMessage
          id="user.excelBaseline.finalCost"
          defaultMessage="Final Cost"
        />
      </Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th {...headerStyles}>
              <FormattedMessage
                id="user.excelBaseline.dataType"
                defaultMessage="Type of data"
              />
            </Th>
            <Th {...headerStyles}>
              <FormattedMessage
                id="user.excelBaseline.sheetName"
                defaultMessage="Sheet name"
              />
            </Th>
            <Th {...headerStyles}>
              <FormattedMessage
                id="user.excelBaseline.cellPosition"
                defaultMessage="Cell position"
              />
            </Th>
            <Th {...cellStyles} />
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td {...cellStyles}>
              <FormattedMessage
                id="user.excelBaseline.decimal"
                defaultMessage="Decimal"
              />
            </Td>
            <Td {...cellStyles}>
              <Input
                {...cellStyles}
                w="fit-content"
                value={sheetName}
                onChange={(e) => setSheetName(e.target.value)}
              />
              <SheetMenuSelector excelId={excelId} setValue={setSheetName} />
            </Td>
            <Td {...cellStyles}>
              <Input
                {...cellStyles}
                minW="20"
                maxW="fit-content"
                value={locationValue}
                onChange={(e) => setLocationValue(e.target.value)}
              />
            </Td>
            <Td {...cellStyles}>
              <Button colorScheme="blue" onClick={onSave}>
                <FormattedMessage
                  id="user.excelBaseline.set"
                  defaultMessage="Set"
                />
              </Button>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default FinalCostSection;
