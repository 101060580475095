import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ICustomer } from "models/customer";
import API_URLS from "utils/apiVersionConfig";

const fetchCustomers = async () => {
  const response = await axios.get(API_URLS.fetchCustomers());
  return response.data;
};

const useFetchCustomers = () => {
  const customerQuery = useQuery<ICustomer[], Error>(
    ["customers"],
    fetchCustomers,
  );
  return customerQuery;
};

export default useFetchCustomers;
