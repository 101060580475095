import { IMaterialType, ProductionLine } from "types/calculationTypes";
import { useEffect, useState } from "react";
import useProductionSites from "./useGetProductionSites";
import useFetchProductionLines from "../../calculationResults/api/fetchProductionLines";

const useGetFilteredProductionLines = (
  calculationMaterialId: number,
  setProduction: React.Dispatch<React.SetStateAction<any[]>>,
) => {
  const { data: productionSites } = useProductionSites();
  const { data: productionLines } = useFetchProductionLines();

  // State variable to hold the filtered lines
  const [filteredLines, setFilteredLines] = useState<ProductionLine[] | null>(
    null,
  );

  useEffect(() => {
    // Filter the productionLines when either calculationMaterialId or productionLines change
    if (productionLines) {
      const filtered = productionLines.map((line) => {
        const isSupported = line.available_materials.some(
          (material: IMaterialType) => material.id === calculationMaterialId,
        );
        return { ...line, is_supported: isSupported };
      });
      setFilteredLines(filtered);
    }
  }, [calculationMaterialId, productionLines]);

  useEffect(() => {
    // Update the supported productions when filteredLines or setProduction change
    if (filteredLines) {
      const supportedProductions = filteredLines
        .filter((line) => line.is_supported)
        .map((line) => line.id);
      setProduction(supportedProductions);
    }
  }, [filteredLines, setProduction]);

  const clientFactoriesWithProductions = productionSites?.map((site) => {
    return {
      ...site,
      productionLines: filteredLines?.filter((line) => line.client === site.id),
    };
  });

  return {
    clientFactoriesWithProductions,
  };
};

export default useGetFilteredProductionLines;
