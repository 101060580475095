import {
  Button,
  Flex,
  HStack,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { MdCheckCircle, MdErrorOutline, MdWarning } from "react-icons/md";
import { DeleteIcon } from "@chakra-ui/icons";
// Custom components
import Card from "components/atoms/Card";
import { useHistory } from "react-router-dom";
import { useRef, useState } from "react";
import { CalculationList } from "models/calculation";
import { FaPlusCircle } from "react-icons/fa";
import CustomDeleteModal from "components/molecule/modal/CustomDeleteModal";
import { useAppSelector } from "store/hooks";
import useCalculationDeleteHideMutation from "views/admin/calculations/calculationView/api/deleteCalculation";
import { Roles } from "views/auth/signIn/signInSlice";
import { useIntl, FormattedMessage } from "react-intl";
import { IClient } from "models/client";
// Assets

interface ComplexTableProps {
  currentClient: IClient;
  tableData: CalculationList;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

type StatusNumber = number;

// StatusNumber is the number corresponding to the status of the calculation
const getStatusColor = (value: StatusNumber) => {
  switch (value) {
    case 0: // created
      return "blue.500";
    case 1: // preprocessing
      return "orange.400";
    case 2: // draft
      return "grey.500";
    case 4: // Error
      return "red.500";
    case 5: // aborted
      return "orange.600";
    case 6: // finished
      return "green.500";
    default:
      return null;
  }
};

const getStatusIcon = (value: StatusNumber): React.ElementType | null => {
  switch (value) {
    case 0: // created
      return FaPlusCircle;
    case 6: // finished
      return MdCheckCircle;
    case 4: // Error
      return MdErrorOutline;
    default:
      return null;
  }
};

const disableDetailButton = (value: StatusNumber): boolean => {
  if (value === 1 || value === 3 || value === 0) {
    return true;
  }

  return false;
};

/**
 * Disable delete button based on user role and calculation ownership.
 * @param {string} userId - The ID of the user performing the action.
 * @param {string} userRole - The role of the user (sales, calc, control).
 * @param {number} userClientSite - The client site ID of the user.
 * @param {Calculation} calculation - The calculation object to check ownership.
 * @returns {boolean} - Returns true to disable the delete button, false otherwise.
 */
const disableDeleteButton = (
  userId: number,
  userRole: Roles,
  userClientSite: string,
  calculation: CalculationType,
): boolean => {
  if (
    userRole === Roles.SalesPerson &&
    userId.toString() === calculation.created_by_user.toString()
  ) {
    // sales users are only allowed to delete their own calculations
    return false;
  } else if (
    userRole === Roles.CalculatingUser ||
    userRole === Roles.ControllingUser
  ) {
    // calculation and control users are allowed to delete all calculations from their own client site
    if (userClientSite.toString() === calculation.client_site_id.toString()) {
      return false;
    }
  }

  // otherwise, not allowed to delete -> disable "delete" button
  return true;
};

// const columns = columnsDataCheck;
export default function ComplexTable({
  currentClient,
  tableData = [],
  sorting,
  setSorting,
}: ComplexTableProps) {
  const tableRef = useRef<HTMLTableElement>(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const history = useHistory();
  const intl = useIntl();
  const userRole = useAppSelector((state) => state.auth.User.role);
  const userID = useAppSelector((state) => state.auth.User.user_id);
  const userClientSite = useAppSelector(
    (state) => state.auth.User.client,
  ).toString();
  const calculationDeleteHideMutation = useCalculationDeleteHideMutation();

  const convertToDate = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [calculationData, setCalculationData] = useState(null);

  const handleCalculationDelete = () => {
    if (calculationData) {
      calculationDeleteHideMutation.mutate({
        calculationID: parseInt(calculationData?.id),
        userID,
      });
    }
  };

  const columnHelper = createColumnHelper<CalculationType>();
  const columns = [
    columnHelper.accessor("built_calculation_id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          <FormattedMessage
            id="user.dashboard.calculationId"
            defaultMessage="Calculation Id"
          />
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor(
      (row: any) => {
        const firmaCleanValue = row.simple_user_field_values.find(
          (item: any) => item.name === "crm_number",
        );
        return firmaCleanValue ? firmaCleanValue.simple_user_field_value : "";
      },
      {
        id: "crm_number",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            <FormattedMessage
              id="user.dashboard.CRMNumber"
              defaultMessage="CRM Number"
            />
          </Text>
        ),
        cell: (info: any) => (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()}
            </Text>
          </Flex>
        ),
      },
    ),
    columnHelper.accessor("status_name", {
      id: "status_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          <FormattedMessage
            id="user.dashboard.status"
            defaultMessage="Status"
          />
        </Text>
      ),
      cell: (info) => {
        const statusKey = info.getValue().toString().replace(/\s+/g, "_");
        return (
          <Tooltip
            hasArrow
            placement="bottom-start"
            label={
              info.getValue() === "error"
                ? info.row.original.errors
                : info.row.original.comments
            }
          >
            <Flex>
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={getStatusColor(info.row.original.status)}
                as={getStatusIcon(info.row.original.status)}
              />
              <Text color={textColor} fontSize="sm" fontWeight="700">
                <FormattedMessage
                  id={`user.dashboard.status.${statusKey}`}
                  defaultMessage={statusKey}
                />
              </Text>
            </Flex>
          </Tooltip>
        );
      },
    }),
    // Add accessor later
    columnHelper.accessor("created_by_user_name", {
      id: "created_by_user_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          <FormattedMessage
            id="user.dashboard.createdBy"
            defaultMessage="Created By"
          />
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor(
      (row: any) => {
        const firmaCleanValue = row.simple_user_field_values.find(
          (item: any) => item.name === "Firma_clean",
        );
        return firmaCleanValue ? firmaCleanValue.simple_user_field_value : "";
      },
      {
        id: "customer_name",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            <FormattedMessage
              id="user.dashboard.customer"
              defaultMessage="Customer"
            />
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        ),
      },
    ),
    columnHelper.accessor("created_timestamp", {
      id: "created_timestamp",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          <FormattedMessage
            id="user.dashboard.creationDate"
            defaultMessage="Creation Date"
          />
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {convertToDate(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("id", {
      id: "action",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          <FormattedMessage
            id="user.dashboard.actions"
            defaultMessage="Actions"
          />
        </Text>
      ),
      cell: (info) => {
        const calculation = info.row.original;
        // for calculations that are already finished, show a warning if the model version of that calculation is not the current one
        const modelWarning =
          calculation.status === 6 &&
          calculation.model_version !== currentClient?.model_version;

        return (
          <HStack>
            <Button
              bg="brand.400"
              color="white"
              _hover={{ bg: "blue.600" }}
              isDisabled={disableDetailButton(calculation.status)}
              onClick={() => {
                history.push(`/user/details/${info.getValue()}`, {
                  modelWarning,
                  calculation,
                });
              }}
            >
              <FormattedMessage
                id="user.dashboard.details"
                defaultMessage="Details"
              />
            </Button>
            {calculation.status === 6 && (
              <Button
                bg="brand.400"
                color="white"
                _hover={{ bg: "blue.600" }}
                onClick={() => {
                  history.push(`/user/result/${info.getValue()}`, {
                    calculationId: info.getValue(),
                    calculationStatus: info.row.getValue("status_name"),
                  });
                }}
              >
                <FormattedMessage
                  id="user.dashboard.results"
                  defaultMessage="Results"
                />
              </Button>
            )}
            <Button
              colorScheme="brand"
              color="white"
              _hover={{ bg: "brand.600" }}
              isDisabled={disableDeleteButton(
                userID,
                userRole,
                userClientSite,
                calculation,
              )}
              onClick={() => {
                setCalculationData(calculation);
                setIsModelOpen(true);
              }}
            >
              <DeleteIcon color="white" />
            </Button>
          </HStack>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <CustomDeleteModal
        isOpen={isModelOpen}
        onClose={() => {
          setIsModelOpen(false);
        }}
        onDelete={handleCalculationDelete}
        calculationData={calculationData}
      />
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Table ref={tableRef} variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table?.getRowModel().rows.length > 0 ? (
              <>
                {table.getRowModel().rows.map((row, index) => {
                  const calculationModelVersion = row.original?.model_version;
                  const clientModelVersion = currentClient?.model_version;
                  // for calculations that are already finished, show a warning if the model version of that calculation is not the current one
                  const shouldDisplayWarning =
                    row.original?.status == 6 &&
                    calculationModelVersion !== clientModelVersion;

                  return (
                    <Tr
                      key={index}
                      _hover={{
                        cursor: "pointer",
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                            {cell.column.id === "id" &&
                              shouldDisplayWarning && (
                                <Tooltip
                                  label={intl.formatMessage({
                                    id: "user.dashboard.modelWarning",
                                    defaultMessage:
                                      "This calculation was run with an outdated AI model. You can rerun this calculation with the latest models.",
                                  })}
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    mr="4px"
                                  >
                                    <Icon
                                      as={MdWarning}
                                      color="yellow.300"
                                      boxSize="1.5em"
                                    />
                                  </Flex>
                                </Tooltip>
                              )}
                          </Flex>
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={7}>
                  <Text align="center" color="gray.500">
                    <FormattedMessage
                      id="user.dashboard.noCalculationsFound"
                      defaultMessage="No calculations found."
                    />
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Card>
    </>
  );
}
