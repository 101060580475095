import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { FormattedMessage } from "react-intl";


interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: (calculation: CalculationType) => void;
  calculationData: CalculationType;
}

const CustomDeleteModal: React.FC<CustomModalProps> = ({ isOpen, onClose, onDelete, calculationData }) => {
  const handleDelete = () => {
    onDelete(calculationData); // Call the delete function passed as prop
    onClose(); // Close the modal after deletion
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="dashboard.deleteModal.title"
            defaultMessage={"Delete Calculation"}
          />
          {` ${calculationData?.built_calculation_id}`}
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            id="dashboard.deleteModal.text"
            defaultMessage={"Are you sure you want to delete this calculation?"}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            <FormattedMessage
              id="dashboard.deleteModal.cancel"
              defaultMessage={"Cancel"}
            />
          </Button>
          <Button colorScheme="brand" onClick={handleDelete}>
            <FormattedMessage
              id="dashboard.deleteModal.delete"
              defaultMessage={"Delete"}
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomDeleteModal;
