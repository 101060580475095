import { Input, InputGroup, InputLeftElement, useColorModeValue } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useIntl } from 'react-intl';

interface SearchbarProps {
  onChange?: (params: string) => void;
}
function SearchBar({ onChange }: SearchbarProps) {
  const intl = useIntl();
  const brandColor = useColorModeValue("gray.700", "white");

  return (
    <InputGroup mb="10">
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        color={brandColor}
        onChange={(e) => onChange(e.target.value)}
        type="text"
        placeholder={intl.formatMessage({
          id: 'calculation.data.search',
          defaultMessage: 'Search',
        })}
      />
    </InputGroup>
  );
}

export default SearchBar;
