import { OptionType } from "types/calculationTypes";

export const materialsConstant = [
  { id: 1, name: "EN-GJL-300" },
  { id: 2, name: "EN_GJS_600_6" },
  { id: 3, name: "EN_GJS_400_18" },
  { id: 4, name: "EN_GJS_500_10" },
  { id: 5, name: "EN_GJS_600" },
  { id: 6, name: "EN_GJS_700" },
  { id: 7, name: "EN_GJS_400_15" },
  { id: 8, name: "EN_GJS_600_10" },
  { id: 9, name: "EN_GJL_250" },
  { id: 10, name: "EN_GJS_500_7" },
  { id: 11, name: "EN_GJS_550_6" },
  { id: 12, name: "EN_GJS_700_2" },
  { id: 13, name: "EN_GJS_500" },
  { id: 14, name: "EN_GJS_600_3" },
  { id: 15, name: "EN_GJS_450_10" },
  { id: 16, name: "EN_GJV_300" },
];

export const customersConstant = [
  {
    id: 1,
    name: "DAILMER",
  },
  {
    id: 2,
    name: "BMW",
  },
  {
    id: 3,
    name: "SCHÄFLLER",
  },
  {
    id: 4,
    name: "JOHN_DEER",
  },
];

export const statusFilterOptions: OptionType[] = [
  { value: null, label: "All", translationId: "user.dashboard.statusAll" },
  {
    value: "created",
    label: "Created",
    translationId: "user.dashboard.statusCreated",
  },
  {
    value: "preprocessing",
    label: "Preprocessing",
    translationId: "user.dashboard.statusPreprocessing",
  },
  {
    value: "draft",
    label: "Draft",
    translationId: "user.dashboard.statusDraft",
  },
  {
    value: "error",
    label: "Error",
    translationId: "user.dashboard.statusError",
  },
  {
    value: "aborted",
    label: "Aborted",
    translationId: "user.dashboard.statusAborted",
  },
  {
    value: "finished",
    label: "Finished",
    translationId: "user.dashboard.statusFinished",
  },
];

export const dateFilterOptions: OptionType[] = [
  {
    value: false,
    label: "Ascending",
    translationId: "user.dashboard.ascending",
  },
  {
    value: true,
    label: "Descending",
    translationId: "user.dashboard.descending",
  },
];

export const calculationDetailLabels: OptionType[] = [
  {
    value: "createdBy",
    label: "Created By",
    translationId: "calculation.createdBy",
  },
  {
    value: "process",
    label: "Process",
    translationId: "calculation.process",
  },
  {
    value: "weight",
    label: "Weight",
    translationId: "calculation.weight",
  },
  {
    value: "module",
    label: "Module",
    translationId: "calculation.module",
  },
  {
    value: "calculation",
    label: "Calculation",
    translationId: "calculation.calculation",
  },
  {
    value: "status",
    label: "Status",
    translationId: "calculation.status",
  },
];
