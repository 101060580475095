import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type } from "os";
import { setCookie, removeCookie } from "utils/CookieFunctions";


export enum Roles {
  SalesPerson,
  ControllingUser,
  CalculatingUser,
  Admin,
}
export interface UserAuth {
  user_id: number;
  username: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
  tokenInit: string;
  role: Roles | number;
  role_name?: string
  client_group: number;
  client: number;
  verbose_name?: string;
  otp_enabled: boolean;
}
export interface ILoginTokenInfo {
  client_group: number;
  tokenType: string;
  exp: string;
  iat: string;
  user_id: number;
  role: number;
  role_name?: string
  client: number;
  otp_enabled: boolean;
}
type InitialState = {
  isLoggedIn: Boolean;
  User: UserAuth;
};

const initialState: InitialState = {
  isLoggedIn: false,
  User: {
    user_id: null,
    username: "",
    expiresIn: "",
    accessToken: "",
    role: null,
    role_name: "",
    refreshToken: "",
    tokenInit: "",
    client_group: null,
    client: null,
    otp_enabled: false,
  },
};

const authSlice = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    setUserAuth(state: InitialState, action: PayloadAction<UserAuth>) {
      state.User.username = action.payload.username;
      state.User.expiresIn = action.payload.expiresIn;
      state.User.accessToken = action.payload.accessToken;
      state.User.refreshToken = action.payload.refreshToken;
      state.User.tokenInit = action.payload.tokenInit;
      state.User.role = action.payload.role;
      state.User.role_name = action.payload.role_name;
      state.User.user_id = action.payload.user_id;
      state.User.client_group = action.payload.client_group;
      state.User.client = action.payload.client;
      state.User.otp_enabled = action.payload.otp_enabled;
      state.isLoggedIn = true;
      setCookie("User", JSON.stringify(state.User));
    },
    logout(state: InitialState) {
      removeCookie("User");
      state.User.username = "";
      state.User.expiresIn = "";
      state.User.accessToken = "";
      state.User.refreshToken = "";
      state.isLoggedIn = false;
      state.User.role = null;
      state.User.role_name = "";
      state.User.tokenInit = "";
      state.User.client_group = null;
      state.User.client = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
export const { setUserAuth, logout } = authSlice.actions;
