import React, { HTMLProps, useEffect, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Box3, PCFSoftShadowMap } from "three";
import { Box, useColorModeValue } from "@chakra-ui/react";
import SceneSetup, { SceneSetupProps } from "./SceneSetup";
import "./styles/Canvas.css";
import PlaneControls from "../../molecule/3DControls/Controls";
import { PlaneWithCoresType } from "views/admin/calculations/api/fetchPartitionPlane";
import { useSetPartingPlanesMatch } from "contexts/ModelHooks";
import CenteredSpinnerRound from "../../atoms/CenteredSpinnerRound";
import Card from "components/atoms/Card";

type DivProps = Omit<HTMLProps<HTMLDivElement>, "color" | "onError">;

export interface StlViewerProps extends DivProps, SceneSetupProps {
  onError?: (err: Error) => void;
  canvasId?: string;
}

interface ModelViewerProps {
  stlFileUrl: string;
  editPlane: boolean;
  calculationId: number;
  planeId: number;
  planeCalculationId: number;
  planeWithCores: PlaneWithCoresType;
}

export default function ModelViewer({
  stlFileUrl,
  editPlane,
  calculationId,
  planeId,
  planeCalculationId,
  planeWithCores,
}: ModelViewerProps) {
  const sceneRef = useRef<any>();
  const [cameraPosition, setCameraPosition] = useState<CameraPositionType>();
  const setPartingPlanesMatch = useSetPartingPlanesMatch();
  const [localPlaneData, setLocalPlaneData] = useState(null);
  const [planeOffset, setPlaneOffset] = useState<number>(0);
  const [selectedOrientation, setSelectedOrientation] = useState<string>("YZ");
  const [resetPosition, setResetPosition] = useState<boolean>(false);
  const [onBboxChange, setOnBoxChange] = useState<Box3>();
  const [showAxes, setShowAxes] = useState(true);

  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none",
  );

  useEffect(() => {
    const savedPlaneDataString = localStorage.getItem("planeData");
    const savedDataArray = JSON.parse(savedPlaneDataString);

    const correctPlane =
      savedDataArray &&
      savedDataArray.find(
        (calculation: any) => calculation.calculationId === planeCalculationId,
      );

    if (correctPlane && planeWithCores) {
      setLocalPlaneData(correctPlane);

      setPartingPlanesMatch(
        correctPlane.axis === planeWithCores.axis &&
          correctPlane.position === planeWithCores.position &&
          planeWithCores.number_of_cavities !== null,
      );
    } else {
      setLocalPlaneData(planeWithCores);
    }
  }, [planeWithCores]);

  useEffect(() => {
    if (localPlaneData) {
      setPlaneOffset(localPlaneData.position);
      setSelectedOrientation(localPlaneData.axis);
    }
  }, [localPlaneData]);

  const handleOrientationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setResetPosition(true);
    setSelectedOrientation(event.target.value);
  };

  return (
    <Card boxShadow={shadow}>
      {localPlaneData ? (
        <>
          <Box className="canvas">
            <Canvas
              shadows
              gl={{
                shadowMapType: PCFSoftShadowMap,
                antialias: true,
              }}
            >
              <SceneSetup
                showAxes={showAxes}
                planeWithCores={planeWithCores}
                url={stlFileUrl}
                sceneRef={sceneRef}
                cameraPosition={cameraPosition}
                planeOffset={planeOffset}
                selectedOrientation={selectedOrientation}
                setOnBoxChange={setOnBoxChange}
              />
            </Canvas>
          </Box>
          <PlaneControls
            showAxes={showAxes}
            setShowAxes={setShowAxes}
            resetPosition={resetPosition}
            setCameraPosition={setCameraPosition}
            handleOrientationChange={handleOrientationChange}
            editPlane={editPlane}
            calculationId={calculationId}
            planeId={planeId}
            planeOffset={planeOffset}
            setPlaneOffset={setPlaneOffset}
            selectedOrientation={selectedOrientation}
            planeWithCores={planeWithCores}
            planeCalculationId={planeCalculationId}
            bbox={onBboxChange}
          />
        </>
      ) : (
        <Box>
          <CenteredSpinnerRound />
        </Box>
      )}
    </Card>
  );
}
