import { RefObject, useEffect, useState } from "react";
import { useLoader } from "@react-three/fiber";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { Box3, Color, Mesh } from "three";
import {
  useCameraInitialPosition,
  useSetCameraInitialPosition,
} from "contexts/ModelHooks";
import Model3D from "../../atoms/3DElements/Model3D";
import Floor from "../../atoms/3DElements/Floor";
import Lights from "../../atoms/3DElements/Lights";
import Camera from "../../atoms/3DElements/Camera";
import ModelOrbitControls from "../../atoms/3DElements/ModelOrbitControls";
import Plane from "../../atoms/3DElements/Plane";
import Axes from "../../atoms/3DElements/Axes";
import Cores from "../../atoms/3DElements/Cores";

const INITIAL_LATITUDE = Math.PI / 8;
const INITIAL_LONGITUDE = -Math.PI / 8;
const CAMERA_POSITION_DISTANCE_FACTOR = 2.5;
const LIGHT_DISTANCE = 350;
const BACKGROUND = new Color("white");

export interface ModelRef {
  model: Mesh;
}

export interface SceneSetupProps {
  showAxes: boolean;
  url: string;
  extraHeaders?: Record<string, string>;
  orbitControls?: boolean;
  sceneRef: RefObject<any>;
  cameraPosition: CameraPositionType;
  planeOffset: number;
  selectedOrientation: string;
  planeWithCores: any;
  setOnBoxChange: any;
}

function SceneSetup({
  url,
  extraHeaders,
  orbitControls = true,
  sceneRef,
  cameraPosition,
  planeOffset,
  selectedOrientation,
  planeWithCores,
  setOnBoxChange,
  showAxes,
}: SceneSetupProps) {
  const [mesh, setMesh] = useState<Mesh>();
  const setCameraInitialPosition = useSetCameraInitialPosition();
  const cameraInitialPosition = useCameraInitialPosition();
  const [sceneReady, setSceneReady] = useState(false);
  const [bbox, setBbox] = useState<Box3>();

  useEffect(() => {
    setSceneReady(false);
  }, [url]);

  const geometry = useLoader(STLLoader, url, (loader) =>
    loader.setRequestHeader(extraHeaders ?? {}),
  );

  function onLoaded(boundingRadius: number, mesh: Mesh): void {
    setMesh(mesh);
    const distance = boundingRadius * CAMERA_POSITION_DISTANCE_FACTOR;
    setCameraInitialPosition({
      latitude: INITIAL_LATITUDE,
      longitude: INITIAL_LONGITUDE,
      distance,
    });

    setTimeout(() => {
      setSceneReady(true);
      setOnBoxChange(mesh.geometry.boundingBox);
      setBbox(mesh.geometry.boundingBox);
    }, 100);
  }

  return (
    <>
      <scene background={BACKGROUND} ref={sceneRef} />
      {cameraInitialPosition != null && bbox && (
        <Camera cameraPosition={cameraPosition} bbox={bbox} />
      )}
      <Model3D
        name="group"
        meshProps={{ name: "mesh" }}
        geometry={geometry}
        visible={sceneReady}
        onLoaded={onLoaded}
      />

      {sceneReady && bbox && (
        <>
          <Floor bbox={bbox} />
          <Lights distance={LIGHT_DISTANCE} bbox={bbox} />
          {showAxes && <Axes />}
          {orbitControls && <ModelOrbitControls bbox={bbox} />}
          {planeWithCores.cavity_geometries && (
            <Cores cavities={planeWithCores.cavity_geometries} />
          )}
          <Plane
            planeOffset={planeOffset}
            selectedOrientation={selectedOrientation}
            bbox={bbox}
          />
        </>
      )}
    </>
  );
}

export default SceneSetup;
