import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import API_URLS from "utils/apiVersionConfig";
import { useIntl } from 'react-intl';

const deleteCustomer = async ({ id }: any) => {
  const response = await axios.delete(API_URLS.deleteCustomer(id));
  return response.data;
};

const useDeleteMutation = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const deleteCustomerMutation = useMutation(deleteCustomer, {
    onSuccess: () => {
      toast.success(        
        intl.formatMessage({
        id: 'global.toasts.customerEdited',
        defaultMessage: 'Customer edited successfully',
      }))
      queryClient.invalidateQueries(["customers"]);
    },
    onError() {
      toast.error(        
        intl.formatMessage({
        id: 'global.toasts.errorAddingCustomer',
        defaultMessage: 'Something went wrong while adding customer.',
      }))
    },
  });

  return deleteCustomerMutation;
};

export default useDeleteMutation;
