import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IMaterialType } from "types/calculationTypes";
import API_URLS from "utils/apiVersionConfig";

interface MaterialState {
  materials: IMaterialType[] | null;
  loading: boolean;
  errors: any;
}
const initialState: MaterialState = {
  materials: [],
  loading: false,
  errors: null,
};

// actions are processes that get data from backend
export const getAndProcessMaterial = createAsyncThunk<IMaterialType[]>(
  "materials/getAndProcessMaterial",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(API_URLS.fetchMaterial());
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    setMaterials: (state, action: PayloadAction<IMaterialType[]>) => {
      state.materials = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAndProcessMaterial.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAndProcessMaterial.fulfilled, (state, action) => {
      state.materials = action.payload;
      state.loading = false;
    });
    builder.addCase(getAndProcessMaterial.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    });
  },
});

export default materialsSlice.reducer;
export const { setMaterials } = materialsSlice.actions;
