import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

export interface PointsOfCoreType {
  x: number;
  y: number;
  z: number;
  [Symbol.iterator](): Iterator<number>;
}

export interface AllCoresType {
  volume: number;
  points: PointsOfCoreType[];
}

export interface PlaneWithCoresType {
  planeId: number;
  calculationId: number;
  axis: string;
  position: number;
  number_of_cavities: number | null;
  total_volume_of_cavities: number | null;
  cavity_geometries: AllCoresType[] | [];
}

const fetchPartitionPlane = async (
  planeId: number,
): Promise<PlaneWithCoresType> => {
  try {
    const response = await axios.get(
      API_URLS.getCalculationPartingPlane(planeId),
    );

    return response.data;
  } catch (error: any) {
    const errorMessage =
      (error.response?.data && error.response.data.message) || "Unknown error";
    throw new Error(`Failed to fetch partition plane: ${errorMessage}`);
  }
};

export default fetchPartitionPlane;
