import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICustomer } from "models/customer";
import { useForm } from "react-hook-form";
import { customerSchema } from "utils/yupSchemas";
import useAddCustomer from "../api/useAddCustomer";
import { FormattedMessage } from "react-intl";

type AddCustomerProps = {
  onClose: () => void;
};

function AddCustomer({ onClose }: AddCustomerProps) {
  const textColor = useColorModeValue("gray.700", "white");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICustomer>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(customerSchema),
  });
  const submitHandler = useAddCustomer({ onClose });
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Flex flexDir="column" gap={5} mb={5}>
        <FormControl>
          <FormLabel
            color={textColor}
            fontWeight="bold"
            fontSize="xs"
            htmlFor="name"
          >
            <FormattedMessage
              id="calculation.data.customerName"
              defaultMessage="Customer Name"
            />
          </FormLabel>
          <Input
            color={textColor}
            borderRadius="15px"
            placeholder="..."
            fontSize="xs"
            id="is_active"
            type="text"
            {...register("name")}
          />
          {errors && errors.name && (
            <FormHelperText color="red">
              {errors.name.message && errors.name.message}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          type="submit"
          colorScheme="brand"
          alignSelf="end"
          isDisabled={!isValid}
        >
          <FormattedMessage id="calculation.data.save" defaultMessage="Save" />
        </Button>
      </Flex>
    </form>
  );
}
export default AddCustomer;
