import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px={{ base: "30px", md: "50px" }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        &copy; {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          <FormattedMessage
            id="global.footer.allRightsReserved"
            defaultMessage={"Casculate. All Rights Reserved."}
          />
        </Text>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            href="mailto:support.calculationapp@casculate.com"
            fontWeight="500"
            color={textColor}
            _hover={{
              color: "blue.500",
            }}
          >
            <FormattedMessage
              id="global.footer.support"
              defaultMessage={"Support"}
            />
          </Link>
        </ListItem>

        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            href="https://casculategmbh.odoo.com/agb"
            target={"_blank"}
            fontWeight="500"
            color={textColor}
            _hover={{
              color: "blue.500",
            }}
          >
            <FormattedMessage
              id="global.footer.privacyPolicy"
              defaultMessage={"Privacy Policy"}
            />
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
