// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

// Custom components
import { CasculateLogo } from "components/atoms/icons/Icons";
import { HSeparator } from "components/atoms/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  const logoColor = useColorModeValue("gray.700", "white");

  return (
    <Flex alignItems="center" flexDirection="column">
      <Box
        bg="white"
        p="1"
        borderRadius="10"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt="8"
        mb="8"
      >
        <CasculateLogo h="26px" w="175px" color={logoColor} />
      </Box>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
