import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import store from "store/configStore";
import { getCookie } from "utils/CookieFunctions";
import {
  ILoginTokenInfo,
  logout,
  setUserAuth,
  UserAuth,
} from "views/auth/signIn/signInSlice";
import jwt_decode from "jwt-decode";
// for dev local machine outsie of docker

const axiosHeaders = {
  "Content-Type": "multipart/form-data",
};

export const loginInstance = axios.create({
  baseURL: process.env.REACT_APP_TOKEN_URL,
  headers: axiosHeaders,
});
export const resetPasswordInstance = axios.create({
  baseURL: `${process.env.REACT_APP_TOKEN_URL}/api`,
  headers: axiosHeaders,
});
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

// export const loginInstance = axios.create({
//   baseURL: "https://middleware.dev.casculate.com/",
// });
// axios.defaults.baseURL = "https://middleware.dev.casculate.com/api/v1";

const refreshTokenRotation = async (
  res: AxiosResponse,
  originalRequest: any,
) => {
  const tokenInfo = jwt_decode<ILoginTokenInfo>(res.data.access);
  const userObject: UserAuth = {
    client_group: tokenInfo.client_group,
    user_id: tokenInfo.user_id,
    accessToken: res.data.access,
    refreshToken: res.data.refresh,
    role: Number(tokenInfo.role),
    role_name: tokenInfo.role_name,
    username: store.getState().auth.User.username,
    expiresIn: tokenInfo.exp,
    tokenInit: tokenInfo.iat,
    client: tokenInfo.client,
    otp_enabled: tokenInfo.otp_enabled,
  };
  store.dispatch(setUserAuth(userObject));
  axios.defaults.headers.common.Authorization = `Bearer ${userObject.accessToken}`;
  originalRequest.headers.Authorization = `Bearer ${userObject.accessToken}`;
};

const axiosInterceptor = (language: string) => {
  axios.defaults.headers.common["Accept-Language"] = language;
  axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      if (!config.headers.Authorization) {
        const { accessToken } = getCookie<UserAuth>("User");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      // Do something before request is sent

      return config;
    },
    (error) => {
      // Do something with request error
      console.log(error);
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // window.location.href = "/auth/sign-in";

      if (
        error.response.status === 401 &&
        //originalRequest.url === `${process.env.REACT_APP_TOKEN_URL}/auth/token`
        originalRequest.url === `${process.env.REACT_APP_TOKEN_URL}/auth/token`
      ) {
        store.dispatch(logout());
        window.location.href = "/auth/sign-in";
        return Promise.reject(error);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 403 ||
        error.response.status === 401 &&
        !originalRequest.retry) {
        originalRequest.retry = true;
        const { refreshToken } = getCookie<UserAuth>("User");

        return loginInstance
          .post("/auth-token/refresh/", {
            refresh: refreshToken,
          })
          .then((res) => {
            refreshTokenRotation(res, originalRequest);
            return axios(originalRequest);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              store.dispatch(logout());
              window.location.href = "/auth/sign-in";
            }
            return Promise.reject(err);
          });
      }
      return Promise.reject(error);
    },
  );

  resetPasswordInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      if (!config.headers.Authorization) {
        const { accessToken } = getCookie<UserAuth>("User");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      // Do something before request is sent

      return config;
    },
    (error) => {
      // Do something with request error
      console.log(error);
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  resetPasswordInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      // window.location.href = "/auth/sign-in";

      if (
        error.response.status === 401 &&
        originalRequest.url === `${process.env.REACT_APP_TOKEN_URL}/auth/token`
      ) {
        store.dispatch(logout());

        return Promise.reject(error);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        const { refreshToken } = getCookie<UserAuth>("User");

        return loginInstance
          .post("/auth-token/refresh/", {
            refresh: refreshToken,
          })
          .then((res) => {
            refreshTokenRotation(res, originalRequest);

            return axios(originalRequest);
          })
          .catch((err) => {
            store.dispatch(logout());
            return Promise.reject(err);
          });
      }
      return Promise.reject(error);
    },
  );
};
export default axiosInterceptor;
