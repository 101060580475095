import React from "react";
import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  Text,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useFormContext, FieldError, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

interface IRadioButtonField extends BoxProps {
  name: string;
  label: string;
  options?: string[];
  defaultValue?: string;
  validationSchema?: any;
}

function RadioButtonField({
  name,
  label,
  options,
  defaultValue,
  validationSchema,
  ...containerStyles
}: IRadioButtonField) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box {...containerStyles}>
      <FormControl
        isInvalid={!!errors[name]}
        isRequired={validationSchema?.required}
      >
        <Text fontWeight="bold">{label}</Text>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          {...validationSchema}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              color="brand.400"
              onChange={onChange}
              defaultChecked
              defaultValue={defaultValue}
            >
              <Box>
                {options?.map((option, index) => (
                  <Radio key={index} value={option}>
                    {option === "raw" ? (
                      <FormattedMessage id="calculation.processstateRaw" />
                    ) : (
                      <FormattedMessage id="calculation.processstateFinished" />
                    )}
                  </Radio>
                ))}
              </Box>
            </RadioGroup>
          )}
        />
        {errors[name] && (
          <FormErrorMessage color="red.500">
            {(errors[name] as FieldError)?.message || "Invalid input"}
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
}

export default RadioButtonField;
