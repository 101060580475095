import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

const runCalculationById = async (calculationId: number, production_lines: number[]) => {
  const response = await axios({
    url: API_URLS.runCalculationById(calculationId),
    method: "POST", 
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      production_lines: production_lines
    }
  });
  return response.data;
};
export default runCalculationById;
