import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import API_URLS from "utils/apiVersionConfig";

export interface ExcelData {
  id: number;
  name: string;
  excel_file: number;
  placeholder_name: string;
  sheet_name: string;
  system_variable: string;
  cell_position: string;
  comments: string;
  created_timestamp: string;
  updated_timestamp: string;
  [key: string]: any; // Add this index signature
}

const fetchExcelData = async (fileId: number) => {
  try {
    const response = await axios.get(API_URLS.getExcelFilePlaceholders(fileId));
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      throw new Error(error.message);
    } else {
      throw new Error(error.message);
    }
  }
};

const useFetchExcelData = (
  fileId: number,
  setData: React.Dispatch<React.SetStateAction<ExcelData[]>>,
  setExistingFinalCost: React.Dispatch<React.SetStateAction<ExcelData>>,
) => {
  const excelData = useQuery<ExcelData[], Error>(
    ["ExcelData", fileId],
    () => fetchExcelData(fileId),
    {
      onSuccess: (data) => {
        const dataWithoutFinalCost = data
          .filter((item) => item.name !== "Final Cost")
          .sort((a, b) => a.id - b.id);
        const existingFinalCost = data.find(
          (item) => item.name === "Final Cost",
        );
        setExistingFinalCost(existingFinalCost);
        setData(dataWithoutFinalCost);
      },
      onError: (error: any) => {
        throw new Error(error.message);
      },
    },
  );

  return excelData;
};
export default useFetchExcelData;
