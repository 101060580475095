import { Button } from "@chakra-ui/react";
import { useCameraInitialPosition } from "contexts/ModelHooks";
import {
  FrontCube,
  IsoCube,
  RightCube,
  TopCube,
} from "../../../atoms/icons/Icons";

interface MoveCameraProps {
  setCameraPosition: (arg0: CameraPositionType) => void;
}

function MoveCamera({ setCameraPosition }: MoveCameraProps) {
  const cameraInitialPosition = useCameraInitialPosition();

  const handleResetCamera = (position: string) => {
    const cameraPositions: Record<string, CameraPositionType> = {
      front: {
        latitude: Math.PI / 4,
        longitude: Math.PI,
        distance: cameraInitialPosition.distance,
      },
      top: {
        latitude: Math.PI / 2,
        longitude: 0,
        distance: cameraInitialPosition.distance,
      },
      right: {
        latitude: Math.PI / 8,
        longitude: Math.PI / 2,
        distance: cameraInitialPosition.distance,
      },
      iso: {
        latitude: Math.PI / 8,
        longitude: -Math.PI / 8,
        distance: cameraInitialPosition.distance,
      },
    };

    setCameraPosition(cameraPositions[position] || cameraInitialPosition);
  };

  return (
    <>
      {["iso", "front", "top", "right"].map((position) => (
        <Button
          key={position}
          mr={position !== "right" ? 2 : 0}
          w="25%"
          alignSelf="center"
          bg="blue.500"
          color="white"
          fontWeight="500"
          _hover={{ bg: "blue.600" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => handleResetCamera(position)}
        >
          {position === "iso" ? (
            <IsoCube />
          ) : position === "front" ? (
            <FrontCube />
          ) : position === "top" ? (
            <TopCube />
          ) : (
            <RightCube />
          )}
        </Button>
      ))}
    </>
  );
}

export default MoveCamera;
