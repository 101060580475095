import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import axios from "axios";
import { MdDelete, MdEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import API_URLS from "utils/apiVersionConfig";
import { ExcelBaseline } from "views/admin/calculations/calculationDetails/api/useGetExcelBaseline";

type BaselineListProps = {
  baseline: ExcelBaseline;
  refetchExcelBaselines: () => void;
  currentClientName: string;
};

function BaselineList({
  baseline,
  refetchExcelBaselines,
  currentClientName,
}: BaselineListProps) {
  const history = useHistory();
  const excelId = baseline?.id;
  return (
    <Box>
      <Flex
        mt="4"
        p="2"
        justifyContent="space-between"
        shadow="sm"
        borderRadius={10}
      >
        <Text fontSize="xl" fontWeight="bold">
          {baseline?.file_name}{" "}
          <Text fontSize="md" fontWeight="normal" as="span">
            ({baseline?.client})
          </Text>
        </Text>
        {currentClientName === baseline?.client && (
          <Flex gap="2">
            <Icon
              as={MdEdit}
              w={8}
              h={8}
              _hover={{ cursor: "pointer", color: "blue.500" }}
              onClick={() => {
                history.push(`/user/config-excel-baseline/${excelId}`);
              }}
            />
            <Icon
              as={MdDelete}
              w={8}
              h={8}
              _hover={{ cursor: "pointer", color: "red.500" }}
              onClick={async () => {
                await axios
                  .delete(API_URLS.deleteExcelBaseline(baseline?.id))
                  .then(() => {
                    refetchExcelBaselines();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default BaselineList;
