import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import useGetExcelBaseline from "views/admin/calculations/calculationDetails/api/useGetExcelBaseline";

interface SheetMenuSelectorProps {
  setValue: (value: string) => void;
  icon?: string;
  excelId?: number;
}
const hoverStyles = { bg: "blue.400", color: "white" };

function parseSheetNames(sheetNamesString: string) {
  const formattedSheetNamesString = sheetNamesString?.replace(/'/g, '"');
  const sheetNamesArray: string[] = JSON?.parse(formattedSheetNamesString);
  return sheetNamesArray || [];
}

function SheetMenuSelector({
  setValue,
  icon,
  excelId,
}: SheetMenuSelectorProps) {
  const { data: baselineExcel } = useGetExcelBaseline();
  const currentBaseline = baselineExcel?.find(
    (item: any) => item.id === excelId,
  );
  const sheetNamesString = currentBaseline?.excel_baseline_sheet_names;
  const sheetNamesArray = sheetNamesString?.includes("[")
    ? parseSheetNames(sheetNamesString)
    : [sheetNamesString];

  return (
    <Menu>
      <MenuButton
        fontSize="xs"
        fontWeight="normal"
        as={Button}
        ml="2"
        variant="outline"
      >
        {icon === "edit" ? <EditIcon /> : <ChevronDownIcon />}
      </MenuButton>

      <MenuList>
        {sheetNamesArray?.map((item: any) => (
          <MenuItem
            key={item}
            _hover={hoverStyles}
            onClick={(e) => {
              setValue(e.currentTarget.textContent);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default SheetMenuSelector;
