import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { useAppSelector } from "store/hooks";
import { ChangePasswordMutationArgs } from "types/settingsTypes";
import useChangePasswordMutation from "../api/changePasswordMutation";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

function ResetPassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const signedUser = useAppSelector((state) => state.auth.User);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { mutate } = useChangePasswordMutation();
  const intl = useIntl();

  const onSubmit = async (data: any) => {
    const mutationData: ChangePasswordMutationArgs = {
      userId: signedUser.user_id,
      data,
    };
    mutate(mutationData);
  };
  const passwordsDontMatch = watch("password") !== watch("password2");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDir="column" gap={4}>
        <Text fontWeight="bold">
          <FormattedMessage
            id="global.settings.oldPassword"
            defaultMessage="Old Password:"
          />
        </Text>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={showOldPassword ? "text" : "password"}
            placeholder={intl.formatMessage({
              id: "global.settings.oldPassword",
              defaultMessage: "Old password",
            })}
            {...register("old_password", { required: true })}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={() => setShowOldPassword(!showOldPassword)}
            >
              {showOldPassword ? (
                <RiEyeLine width={24} height={24} />
              ) : (
                <RiEyeOffLine width={24} height={24} />
              )}{" "}
            </Button>
          </InputRightElement>
        </InputGroup>
        <Text fontWeight="bold">
          <FormattedMessage
            id="global.settings.newPassword"
            defaultMessage="New Password:"
          />
        </Text>

        <Text display="inline" fontSize="12px" color="gray.400">
          <FormattedMessage
            id="global.settings.passwordRequirements"
            defaultMessage="Your password should be more than 8 letters containing at least one digit and one alphabet."
          />
        </Text>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={showNewPassword ? "text" : "password"}
            borderColor={passwordsDontMatch ? "red.500" : "gray.300"}
            focusBorderColor={passwordsDontMatch ? "red.500" : "brand.400"}
            placeholder={intl.formatMessage({
              id: "global.settings.newPassword",
              defaultMessage: "New password",
            })}
            {...register("password", {
              required: true,
              minLength: {
                value: 8,
                message: intl.formatMessage({
                  id: "global.settings.passwordLength",
                  defaultMessage: "Password must be at least 8 characters long",
                }),
              },
            })}
          />

          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? (
                <RiEyeLine width={24} height={24} />
              ) : (
                <RiEyeOffLine width={24} height={24} />
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
        {errors.password && (
          <Text color="red.400" fontSize="sm">
            {errors.password.message.toString()}
          </Text>
        )}
        <Text fontWeight="bold">
          <FormattedMessage
            id="global.settings.confirmPassword"
            defaultMessage="Confirm Password:"
          />
        </Text>

        <InputGroup size="md">
          <Input
            pr="4.5rem"
            variant="outline"
            borderColor={passwordsDontMatch ? "red.500" : "gray.300"}
            focusBorderColor={passwordsDontMatch ? "red.500" : "brand.400"}
            type={showConfirmPassword ? "text" : "password"}
            placeholder={intl.formatMessage({
              id: "global.settings.confirmPassword",
              defaultMessage: "Confirm password",
            })}
            {...register("password2", {
              required: true,
              validate: (value: any) =>
                value === watch("password2") || "Passwords do not match",
            })}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <RiEyeLine width={24} height={24} />
              ) : (
                <RiEyeOffLine width={24} height={24} />
              )}{" "}
            </Button>
          </InputRightElement>
        </InputGroup>
        {passwordsDontMatch && (
          <Text color="red.400" fontSize="sm">
            <FormattedMessage
              id="global.settings.passwordMismatch"
              defaultMessage="Your passwords do not match."
            />
          </Text>
        )}
        <Button
          isDisabled={passwordsDontMatch}
          type="submit"
          _hover={{ bg: "blue.600" }}
          color="#fff"
          bg="brand.400"
          alignSelf="end"
          mt="10"
        >
          <FormattedMessage
            id="global.settings.change"
            defaultMessage="Change"
          />
        </Button>
      </Box>
    </form>
  );
}

export default ResetPassword;
