import {
  Checkbox,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ProductionLine } from "types/calculationTypes";

type ProductionLineProps = {
  productionLines: ProductionLine[];
  production: any[];
  setProduction: React.Dispatch<React.SetStateAction<any[]>>;
};

function ProductionLines({
  productionLines = [],
  production,
  setProduction,
}: ProductionLineProps) {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex ml="10" w="full" flexDir="column">
      <Stack spacing={[1, 5, 7]} mb={[1, 5, 7]}>
        {productionLines?.map((productionLine: ProductionLine) => (
          <Flex
            key={(productionLine.id + Math.random()).toString()}
            id={(productionLine.id + Math.random()).toString()}
            alignItems="center"
            gap={2}
            cursor={productionLine.is_supported ? "pointer" : "not-allowed"}
          >
            <Checkbox
              id={(productionLine.id + Math.random()).toString()}
              disabled={!productionLine.is_supported}
              color="blue.500"
              size="lg"
              isChecked={production.includes(productionLine.id)}
              _disabled={{
                color: "secondaryGray.600",
                textDecoration: "line-through",
                textDecorationColor: "black",
                border: "1px solid",
                opacity: 0.3,
              }}
              css={`
                > span:first-of-type {
                  box-shadow: unset;
                }
              `}
              onChange={(e) => {
                if (e.target.checked) {
                  setProduction([...production, productionLine.id]);
                } else {
                  setProduction(
                    production?.filter((id) => id !== productionLine.id),
                  );
                }
              }}
            />
            <Text
              color={
                productionLine.is_supported ? textColor : "secondaryGray.600"
              }
              textDecoration={
                productionLine.is_supported ? "none" : "line-through"
              }
              textDecorationColor="black"
              fontSize={{ sm: "md", md: "lg" }}
              fontWeight="bold"
            >
              {productionLine.name}
            </Text>
          </Flex>
        ))}
      </Stack>
    </Flex>
  );
}
export default ProductionLines;
