import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-hot-toast";
import API_URLS from "utils/apiVersionConfig";

const getCadFile = async (cadFileId: number) => {
  const response = await axios.get(API_URLS.getCADFile(cadFileId));
  return response.data.stl_file;
};

const useCadFile = (cadFileId: number) => {
  return useQuery(["cadFile", cadFileId], () => getCadFile(cadFileId), {
    enabled: !!cadFileId,
    onError: () => {
      toast.error("Error while fetching CAD file.");
    },
  });
};

export default useCadFile;
