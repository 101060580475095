import { LineBasicMaterial, BufferGeometry, Vector3, Line, Euler } from "three";
import { Text } from "@react-three/drei";

const ThickerAxesHelper = () => {
  const createLine = (
    start: Vector3,
    end: Vector3,
    color: number,
    lineWidth: number,
  ): Line => {
    const material = new LineBasicMaterial({ color, linewidth: lineWidth });
    const geometry = new BufferGeometry().setFromPoints([start, end]);
    return new Line(geometry, material);
  };

  const createLabel = (
    text: string,
    position: Vector3,
    color: string,
  ): JSX.Element => {
    return (
      <Text
        key={text}
        position={position.add(new Vector3(0, 0, 2))}
        fontSize={5}
        color={color}
        anchorX="center"
        anchorY="middle"
        rotation={new Euler(Math.PI / 2, 0, 0)}
      >
        {text}
      </Text>
    );
  };

  const xAxis = createLine(
    new Vector3(0, 0, 0),
    new Vector3(1000, 0, 0),
    0xff0000,
    100,
  );
  const yAxis = createLine(
    new Vector3(0, 0, 0),
    new Vector3(0, 1000, 0),
    0x00ff00,
    100,
  );
  const zAxis = createLine(
    new Vector3(0, 0, 0),
    new Vector3(0, 0, 1000),
    0x0000ff,
    100,
  );

  const xAxisLabel = createLabel("X", new Vector3(25, 0, 0), "red");
  const yAxisLabel = createLabel("Y", new Vector3(0, 25, 0), "green");
  const zAxisLabel = createLabel("Z", new Vector3(0, 0, 25), "blue");

  return (
    <>
      <primitive object={xAxis} />
      <primitive object={yAxis} />
      <primitive object={zAxis} />

      {xAxisLabel}
      {yAxisLabel}
      {zAxisLabel}
    </>
  );
};

export default ThickerAxesHelper;
