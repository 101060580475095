// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Stack,
  Text,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { Key, useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
// Assets
import Card from "components/atoms/Card";
// Custom components
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { CalculationInputs, UserBasedField } from "types/calculationTypes";
import { FormattedMessage, useIntl } from "react-intl";
import Background from "../../../../assets/img/background.jpg";
import useExtractCadParametersMutation from "./api/extractCadParametersMutation";
import FormItem from "./components/FormItem";
import useDeleteCalculation from "./api/useDeleteCalculation";
import usePostSimpleFields from "./api/usePostSimpleFields";
import fetchFormFields from "./api/useFetchFormFields";
import { useQuery } from "@tanstack/react-query";

interface INewCalculationDetail {
  cadFile: number;
  calculationId: number;
}

function NewCalculation() {
  const { data: formFields, refetch: refetchFormFields } = useQuery<
    UserBasedField[]
  >({
    queryKey: ["formFields"],
    queryFn: () => fetchFormFields(),
  });
  const finalRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { calculationId } = (location.state || {}) as INewCalculationDetail;

  useEffect(() => {
    if (!calculationId) {
      history.push("/user/404", {
        text: "There was an error creating the calculation, please start again",
        textId: "user.newCalc.error",
        button: "Return to upload file",
        buttonId: "user.cadFile.redirect",
        path: "/user/cadFile",
      });
    }
  }, [location.state]);

  const formMethods = useForm<CalculationInputs>();
  const extractCadParam = useExtractCadParametersMutation();
  const deleteMutation = useDeleteCalculation(calculationId);
  const [isCreated] = useState(false);
  const { mutateAsync } = usePostSimpleFields();
  const intl = useIntl();
  const currentLocale = intl.locale;
  const brandColor = useColorModeValue("gray.700", "white");
  const onSubmit: SubmitHandler<CalculationInputs> = async (values) => {
    // rewrite above code wiht object.enteries
    const apiRequestPromises = Object.entries(values).map(
      async ([key, value]) => {
        const itemId = formFields?.find(
          (item: { name: string }) => item.name === key,
        )?.id;
        const postData: any = {
          name: key,
          calculation: calculationId,
          simple_user_field: itemId,
          simple_user_field_value: value,
        };
        return mutateAsync(postData);
      },
    );
    try {
      await Promise.all(apiRequestPromises);
      extractCadParam.mutateAsync(calculationId).then(() => {
        history.push("/user/dashboard");
      });
    } catch (error) {
      // Handle errors that may occur during the API requests
      console.error(error);
    }
  };

  useEffect(() => {
    refetchFormFields();
  }, [currentLocale]);

  useEffect(() => {
    // Listen for changes in the route history
    const unlisten = history.listen((location, action) => {
      if (action === "POP") {
        deleteMutation.mutate();
      }
      if (action === "PUSH") {
        if (!formMethods.formState.isValid) {
          deleteMutation.mutate();
        }
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [deleteMutation, history]);

  return (
    <Box ref={finalRef}>
      {calculationId && (
        <Prompt
          when={!formMethods.formState.isValid}
          message={intl.formatMessage({
            id: "global.warning.uncompleted",
            defaultMessage:
              "You have uncompleted fields and your process will be lost. Are you sure you want to leave?",
          })}
        />
      )}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Box
            bg={`url(${Background})`}
            bgSize="cover"
            borderRadius="30px"
            h={{ sm: "45vh", md: "35vh" }}
            w="100%"
          />
          <Flex
            direction="column"
            minH="100vh"
            mt={{ sm: "-25vh", md: "-25vh", lg: "-20vh" }}
            align="center"
            w={{ sm: "full", md: "full", lg: "850px" }}
            mx="auto"
          >
            <FormControl>
              <Card>
                <Text
                  color={brandColor}
                  fontSize={{ sm: "xl", md: "2xl" }}
                  mt="4px"
                  fontWeight="bold"
                  textAlign="center"
                  mb="4"
                >
                  <FormattedMessage
                    id="calculation.heading"
                    defaultMessage="Set Calculation Parameters"
                  />
                </Text>
                <Flex direction="column" w="100%">
                  <Stack direction="column">
                    {formFields?.map((field: UserBasedField, index: Key) => (
                      <FormItem key={index} formItem={field} />
                    ))}
                  </Stack>

                  <Button
                    type="submit"
                    variant="no-hover"
                    bg="brand.400"
                    alignSelf="flex-end"
                    mt="24px"
                    _hover={{ bg: "blue.600" }}
                    isDisabled={!formMethods.formState.isValid || isCreated}
                    w={{ sm: "75px", lg: "100px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                    h="35px"
                  >
                    {isCreated && <Spinner color="white" h="14px" w="14px" />}
                    <Text fontSize="sm" color="#fff" fontWeight="bold">
                      <FormattedMessage
                        id="calculation.data.save"
                        defaultMessage="Save"
                      />
                    </Text>
                  </Button>
                </Flex>
              </Card>
            </FormControl>
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
}

export default NewCalculation;
